import React, { useState } from "react";
import Sidebar from "../../directives/sidebar";
import Header from "../../directives/header";
import Footer from "../../directives/footer";
import toast, { Toaster } from "react-hot-toast";
import { baseurl } from "../../config/config";
import { insertPromoCodeAction } from "../../Action/user.action";
import Togglesidebar from "../../directives/togglesidebar";

const AddPromocode = () => {
  const [formData, setFormData] = useState({
    code: "",
    percent: "",
    validFrom: "",
    validTo: "",
  });

  const [validationErrors, setValidationErrors] = useState({
    code: "",
    percent: "",
    validFrom: "",
    validTo: "",
  });

  const givenDateString = "9999-12-31";
  const maxDate = new Date(givenDateString).toISOString().split("T")[0];
  let currDate = new Date().toISOString().split("T")[0];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    const res = await insertPromoCodeAction(formData);
    if (res.success) {
      toast.success(res.msg);
      setTimeout(() => {
        window.location.href = `${baseurl}promocode`;
      }, 1200);
    } else {
      toast.error(res.msg);
    }
  };

  const validateForm = () => {
    let isValid = true;
    const errors = {
      code: "",
      percent: "",
      validFrom: "",
      validTo: "",
    };

    if (formData.code.trim() === "") {
      errors.code = "Promo code is required";
      isValid = false;
    }

    if (formData.percent.trim() === "") {
      errors.percent = "Discount is required";
      isValid = false;
    }

    if (formData.validFrom.trim() === "") {
      errors.validFrom = "Please select offer start date";
      isValid = false;
    }

    if (formData.validTo.trim() === "") {
      errors.validTo = "Please select offer end date";
      isValid = false;
    }

    setValidationErrors(errors);
    return isValid;
  };

  return (
    <>
      <Toaster />
      <div className="content">
        <div className="main-content">
          <div className="block justify-between page-header md:flex"></div>
          <div className="grid grid-cols-12 gap-6">
            <div className="col-span-12">
              <div className="box-header">
                <h5 className="box-title">Add Promo Code</h5>
              </div>
              <div className="box-body">
                <form onSubmit={handleSubmit}>
                  <div className="mb-3">
                    <label
                      htmlFor="code"
                      className="block text-sm font-medium text-gray-600 mb-2"
                    >
                      Promo Code
                    </label>
                    <input
                      type="text"
                      id="code"
                      name="code"
                      placeholder="Promo Code"
                      value={formData.code}
                      onChange={handleChange}
                      className="mt-1 p-2 border rounded-md w-full"
                    />
                    <span className="text-red-500 text-sm">
                      {validationErrors.code}
                    </span>
                  </div>
                  <div className="mb-3">
                    <label
                      htmlFor="percent"
                      className="block text-sm font-medium text-gray-600 mb-2"
                    >
                      Discount (%)
                    </label>
                    <input
                      type="number"
                      id="percent"
                      name="percent"
                      placeholder="Discount (%)"
                      value={formData.percent}
                      onChange={handleChange}
                      className="mt-1 p-2 border rounded-md w-full"
                    />
                    <span className="text-red-500 text-sm">
                      {validationErrors.percent}
                    </span>
                  </div>
                  <div className="mb-3">
                    <label
                      htmlFor="validFrom"
                      className="block text-sm font-medium text-gray-600 mb-2"
                    >
                      Valid From
                    </label>
                    <input
                      type="date"
                      id="validFrom"
                      name="validFrom"
                      value={formData.validFrom}
                      onChange={handleChange}
                      className="mt-1 p-2 border rounded-md w-full"
                      min={currDate}
                      max={maxDate}
                    />
                    <span className="text-red-500 text-sm">
                      {validationErrors.validFrom}
                    </span>
                  </div>
                  <div className="mb-3">
                    <label
                      htmlFor="validTo"
                      className="block text-sm font-medium text-gray-600 mb-2"
                    >
                      Valid To
                    </label>
                    <input
                      type="date"
                      id="validTo"
                      name="validTo"
                      value={formData.validTo}
                      onChange={handleChange}
                      min={formData.validFrom}
                      max={maxDate}
                      className="mt-1 p-2 border rounded-md w-full"
                    />
                    <span className="text-red-500 text-sm">
                      {validationErrors.validTo}
                    </span>
                  </div>
                  <div className="mt-3">
                    <button type="submit" className="ti-btn ti-btn-primary">
                      Add Promo Code
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddPromocode;
