import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import { useQuery } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import {
  getCategoriesByDepartment,
  getCategoryBySubDepartment,
  getSubDepartmentsByDepartment,
} from '../../Action/user.action';
import { LoaderIcon } from 'react-hot-toast';

export const DepartmentSubCard = ({ banner, refetchBanners, departments, setBannerData }) => {
  const [file, setFile] = useState(null);
  const [selectedDep, setSelectedDep] = useState(null);
  const [selectedSubDep, setSelectedSubDep] = useState(null);
  const [selectedCat, setSelectedCat] = useState(null);
  const [loading, setLoader] = useState(false);

  const { data: subDepartments } = useQuery({
    queryKey: [`subDepartments-${banner.id}`, selectedDep],
    queryFn: () =>
      getSubDepartmentsByDepartment({ department_id: selectedDep })
        .then((res) => res.data)
        .catch((err) => console.log(err)),
    enabled: !!selectedDep,
  });

  const { watch, register, reset } = useForm({
    title: '',
    title_ru: '',
    opacity: '',
  });

  const title = watch('title');
  const title_ru = watch('title_ru');
  const opacity = watch('opacity');

  useEffect(() => {
    reset(banner);
  }, [banner]);

  const { data: categories } = useQuery({
    queryKey: [`categories-${banner.id}`, selectedSubDep],
    queryFn: () =>
      getCategoryBySubDepartment({ sub_department_id: selectedSubDep })
        .then((res) => res.data)
        .catch((err) => console.log(err)),
    enabled: !!selectedSubDep,
  });

  const loginData = !Cookies.get('loginSuccessFarfetchAdmin')
    ? []
    : JSON.parse(Cookies.get('loginSuccessFarfetchAdmin'));
  const addBanner = async (id) => {
    setLoader(true);
    const formData = new FormData();
    formData.append('category_id', banner.is_active ? banner.category_id : selectedCat);
    formData.append('department_id', banner.is_active ? banner.department_id : selectedDep);
    formData.append('sub_department_id', banner.is_active ? banner.subdepartment_id : selectedDep);
    formData.append('title', title);
    formData.append('title_ru', title_ru);
    formData.append('opacity', opacity);
    formData.append('id', banner.id);
    if (file) {
      formData.append('banner', file);
    }

    await axios
      .patch(`${process.env.REACT_APP_API_URL}/updateBanner`, formData, {
        headers: {
          Authorization: loginData?.authToken,
        },
      })
      .then((res) => {
        refetchBanners();
        setLoader(false);
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
      });
    setLoader(false);
  };

  const deleteBanner = async (id) => {
    await axios
      .delete(`${process.env.REACT_APP_API_URL}/deleteBanner`, {
        data: { id },
        headers: {
          Authorization: loginData?.authToken,
        },
      })
      .then((res) => {
        refetchBanners();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  if (!banner) {
    return null;
  }

  return (
    <div
      className="border p-5 dark:border-white/10"
      style={{ width: 'calc(25% - 40px)', minWidth: '313px', display: 'flex', flexDirection: 'column' }}
    >
      {banner.department_id === 1 && <h3>For Men</h3>}
      {banner.department_id === 2 && <h3>For Woman</h3>}
      {banner.department_id === 3 && <h3>For Kids</h3>}
      <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
        <label>Selected Banner for Department</label>
        {
          <div
            style={{
              width: 'auto',
              height: '300px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: '20px',
            }}
          >
            <img
              style={{ objectFit: 'contain', height: '100%' }}
              src={`${process.env.REACT_APP_MEDIA_URL}/banners/${banner?.url}`}
            />
          </div>
        }
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', marginTop: '20px' }}>
        <label>Add New Banner Image</label>
        <input onChange={(e) => setFile(e.target.files[0])} type="file" accept=".webp" />

        {!banner.is_active && (
          <>
            {departments?.length > 0 && (
              <select onChange={(e) => setSelectedDep(e.target.value)}>
                <option>Select department</option>
                {departments?.map((dep) => (
                  <option value={dep.id}>{dep.name}</option>
                ))}
              </select>
            )}

            <select onChange={(e) => setSelectedSubDep(e.target.value)}>
              <option>Select SubDepartment</option>
              {subDepartments?.map((dep) => (
                <option value={dep.id}>{dep.name}</option>
              ))}
            </select>

            <select onChange={(e) => setSelectedCat(e.target.value)}>
              <option>Select category</option>
              {categories?.map((cat) => (
                <option value={cat.id}>{cat.name}</option>
              ))}
            </select>

            {/* <select onChange={(e) => setSelectedCat(e.target.value)}>
              <option>Select Brand</option>
              {brands?.map((brand) => (
                <option value={brand.id}>{brand.name}</option>
              ))}
            </select> */}
          </>
        )}

        <label>Title</label>
        <input className="border p-2" {...register('title')} />

        <label>Title_ru</label>
        <input className="border p-2" {...register('title_ru')} />

        <label>Opacity</label>
        <input className="border p-2" {...register('opacity')} />

        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '20px',
            marginTop: '10px',
          }}
        >
          <button
            style={{ padding: '10px', border: '1px solid #ccc', width: '100%' }}
            onClick={() => addBanner(banner.department_id)}
            className="special_special_button align-center center flex items-center justify-center"
            type="submit"
          >
            {loading ? <LoaderIcon /> : 'Submit'}
          </button>
          {!banner.is_active && (
            <button
              style={{ padding: '10px', border: '1px solid #ccc', width: '100%' }}
              onClick={() => deleteBanner(banner.id)}
              className="special_delete_button align-center center flex items-center justify-center"
              type="submit"
            >
              Delete
            </button>
          )}
        </div>
      </div>
    </div>
  );
};
