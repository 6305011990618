import React, { useEffect, useState } from "react";
import Sidebar from "../../directives/sidebar";
import Header from "../../directives/header";
import Footer from "../../directives/footer";
import DataTable from "react-data-table-component";
import Swal from "sweetalert2";
import {
  getCMSPagesAction,
  insertCMSPagesAction,
  updateCMSPagesAction,
  updatePagesStatusAction,
  deleteCmsPageAction,
} from "../../Action/user.action";
import "react-responsive-modal/styles.css";
import "react-toastify/dist/ReactToastify.css";
import { Modal } from "react-responsive-modal";
import { ToastContainer, toast } from "react-toastify";
import config from "../../config/config";
import { fetchAndSetTranslations } from "../../i18n";
import { useTranslation } from "react-i18next";
import { tableStyles } from "../../config/tableStyles";
import { Loader } from "../UI/Loader";

const CMS = () => {
  const [loader, setLoader] = useState(true);
  const { t, i18n } = useTranslation();
  const [listing, setListing] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [open, setOpen] = useState(false);
  const [modalSecond, setModalSecond] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState({
    name: "",
    nameRu: "",
  });
  const [formData, setFormData] = useState({
    name: "",
    nameRu: "",
  });
  const [validationErrors, setValidationErrors] = useState({});

  useEffect(() => {
    fetchPagesList();
    fetchTranslations();
  }, []);

  const fetchTranslations = async () => {
    try {
      const translations = await fetchAndSetTranslations("ru");
    } catch (error) {
      console.error("An error occurred while fetching translations:", error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSelectedCategory((old) => {
      return { ...old, [name]: value };
    });
  };

  const clearValidationError = (fieldName) => {
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      [fieldName]: undefined,
    }));
  };

  // Add category ----------------------------------------
  const addCategory = async (e) => {
    e.preventDefault();
    const errors = {};

    if (formData.name === "") {
      errors.name = "Page Name is required";
    }

    if (formData.nameRu === "") {
      errors.nameRu = "Page Name is required";
    }

    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
    } else {
      setValidationErrors({});
      try {
        const response = await insertCMSPagesAction(formData);
        if (response.success) {
          toast.success("Success", {
            position: toast.POSITION.TOP_CENTER,
          });
          onCloseModal();
          setTimeout(() => {
            fetchPagesList();
            fetchTranslations();
          }, 1000);
        } else {
          console.error("Failed to add category");
        }
      } catch (error) {
        console.error("An error occurred while adding the category:", error);
      }
    }
  };

  // Update category  ---------------------------------
  const updateCategory = async (e) => {
    e.preventDefault();
    const errors = {};
    if (selectedCategory.name === "") {
      errors.name = "name is required";
    }
    if (selectedCategory.heading === "") {
      errors.heading = "Heading is required";
    }
    if (selectedCategory.nameRu === "") {
      errors.nameRu = "Page Name is required";
    }
    if (selectedCategory.heading_ru === "") {
      errors.heading_ru = "Heading is required";
    }

    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
    } else {
      // Clear validation errors
      setValidationErrors({});
      try {
        const response = await updateCMSPagesAction(
          selectedCategory,
          formData.id,
        );
        if (response.success) {
          toast.success("Success", {
            position: toast.POSITION.TOP_CENTER,
          });
          onCloseModalSecond();
          setTimeout(() => {
            fetchPagesList();
            fetchTranslations();
          }, 0);
        } else {
          console.error("Failed to update Region");
        }
      } catch (error) {
        console.error("An error occurred while updating the Region:", error);
      }
    }
  };

  const StatusUpdate = async (data) => {
    let res = await updatePagesStatusAction({
      id: data.id,
    });
    if (res.success) {
      fetchPagesList();
    } else {
      Swal.fire("Failed!", res.msg, "error");
    }
  };

  const updateDeleteStatus = async (data) => {
    Swal.fire({
      title: "Are you sure?",
      text: `You want to Delete it!`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: `Yes, Delete it!`,
    }).then(async (result) => {
      if (result.isConfirmed) {
        let res = await deleteCmsPageAction({
          id: data.id,
        });
        if (res.success) {
          fetchPagesList();
        } else {
          Swal.fire("Failed!", res.msg, "error");
        }
      }
    });
  };

  // Get listing -------------------------------
  const fetchPagesList = async () => {
    try {
      setLoader(true);
      const res = await getCMSPagesAction();
      if (res.success) {
        setLoader(false);
        setListing(res.data);
      }
      setLoader(false);
    } catch (error) {
      console.error("An error occurred while getting the listing:", error);
    }
  };

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  const filteredData = listing.filter((item, index) => {
    item.index = index;
    item = Object.values(item).some((value) =>
      String(value).toLowerCase().includes(searchText.toLowerCase()),
    );
    return item;
  });

  const columns = [
    {
      name: "№",
      selector: (row) => `${row.index + 1}`,
      sortable: true,
    },
    {
      name: "Page Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Action",
      cell: (row) => {
        return (
          <div className="button_wrp">
            <button
              onClick={() => onOpenModalSecond(row)}
              className="btn btn-outline-primary"
            >
              <i class="fa fa-pencil-square-o fa-xs" aria-hidden="true"></i>
            </button>
            <a
              href={
                `${process.env.REACT_APP_BASE_URL}cmscontentlist/` + row?.id
              }
            >
              <button className="btn btn-outline-dark">
                <i className="fa fa-eye fa-xs" />
              </button>
            </a>
            {row.isActive ? (
              <button
                onClick={() => StatusUpdate(row)}
                className="btn btn-success"
              >
                <i class="fa fa-check fa-xs" aria-hidden="true" />
              </button>
            ) : (
              <button
                onClick={() => StatusUpdate(row)}
                className="btn btn-outline-danger"
              >
                <i class="fa fa-times fa-s" aria-hidden="true" />
              </button>
            )}
            <button
              onClick={() => updateDeleteStatus(row)}
              className="btn btn-outline-danger"
            >
              <i class="fa fa-trash fa-xs" aria-hidden="true" />
            </button>
          </div>
        );
      },
    },
  ];

  // Modal code  ---------------------------------------
  const onOpenModalSecond = (category) => {
    category.nameRu = t(category.name);
    setSelectedCategory(category);
    setModalSecond(true);
  };

  const onCloseModalSecond = () => setModalSecond(false);

  const onOpenModal = () => setOpen(true);

  const onCloseModal = () => setOpen(false);

  return (
    <>
      <div className="content">
        <div className="main-content">
          <div className="block justify-between page-header md:flex">
            <div></div>
          </div>

          <div className="grid grid-cols-12 gap-6">
            <div className="col-span-12">
              <div className="box">
                <div className="box-header">
                  <h5 className="box-title  font-normal">Pages List</h5>
                  <button
                    type="button"
                    onClick={onOpenModal}
                    className="ti-btn ti-btn-primary"
                    style={{ float: "right", marginTop: "-35px" }}
                  >
                    Add
                  </button>
                </div>
                <div className="box-body">
                  {/* Search input */}
                  <input
                    type="text"
                    value={searchText}
                    onChange={handleSearch}
                    placeholder="Search..."
                    className="px-2 py-1 border rounded mb-4 searchtype"
                  />
                  {!loader ? (
                    <div className="overflow-hidden table-bordered">
                      <DataTable
                        columns={columns}
                        data={filteredData}
                        pagination
                        paginationTotalRows={filteredData.length}
                        customStyles={tableStyles}
                      />
                    </div>
                  ) : (
                    <Loader />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Start::main-content */}
      </div>
      {/* First modal: Add category -------------- */}
      <Modal open={open} onClose={onCloseModal} center>
        <div className="form-group">
          <label className="mb-2" htmlFor="categoryId">
            <h4>Add New Pages</h4>
          </label>
          <hr />
          <label className="" htmlFor="name">
            Page Name
          </label>
          <br />
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={(e) =>
              setFormData(
                { ...formData, name: e.target.value },
                clearValidationError("name"),
              )
            }
            className="form-control"
            id="name"
            style={{ marginTop: "10px", marginBottom: "10px" }}
            placeholder="Page Name (EN)"
          />
          {validationErrors.name && (
            <span className="text-danger">{validationErrors.name}</span>
          )}
        </div>

        <div className="form-group">
          <input
            type="text"
            name="nameRu"
            value={formData.nameRu}
            onChange={(e) =>
              setFormData(
                { ...formData, nameRu: e.target.value },
                clearValidationError("nameRu"),
              )
            }
            className="form-control"
            id="nameRu"
            style={{ marginTop: "10px", marginBottom: "10px" }}
            placeholder="Page Name (RU)"
          />
          {validationErrors.nameRu && (
            <span className="text-danger">{validationErrors.nameRu}</span>
          )}
        </div>

        <button
          type="submit"
          onClick={addCategory}
          className="btn btn-primary modal-footer"
        >
          Add
        </button>
      </Modal>
      {/* Edit modal code --------- */}
      <Modal open={modalSecond} onClose={onCloseModalSecond} center>
        <label className="mb-2" htmlFor="regionName">
          <h4>Edit Page</h4>
        </label>
        <hr />
        <div className="form-group mb-3">
          <label className="mb-2" htmlFor="name">
            Page Name
          </label>
          <br />
          <input
            type="text"
            name="name"
            value={selectedCategory.name}
            onChange={(e) => {
              handleChange(e);
              clearValidationError("name");
            }}
            className="form-control"
            id="name"
            placeholder="Page Name (EN)"
          />
          {validationErrors.name && (
            <span className="text-danger">{validationErrors.name}</span>
          )}
        </div>

        <div className="form-group mb-3">
          <input
            type="text"
            name="nameRu"
            value={selectedCategory.nameRu}
            onChange={(e) => {
              handleChange(e);
              clearValidationError("nameRu");
            }}
            className="form-control"
            id="nameRu"
            placeholder="Page Name (RU)"
          />
          {validationErrors.nameRu && (
            <span className="text-danger">{validationErrors.nameRu}</span>
          )}
        </div>

        <button
          type="submit"
          onClick={updateCategory}
          className="btn btn-primary modal-footer"
        >
          Update
        </button>
      </Modal>
      <ToastContainer />
    </>
  );
};

export default CMS;
