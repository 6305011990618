import React, { useState, useEffect } from "react";
import "react-responsive-modal/styles.css";
import "react-toastify/dist/ReactToastify.css";
import Sidebar from "../../directives/sidebar";
import Header from "../../directives/header";
import Footer from "../../directives/footer";
import toast, { Toaster } from "react-hot-toast";
import config from "../../config/config";
import { baseurl } from "../../config/config";
import { useParams } from "react-router-dom";
import { fetchAndSetTranslations } from "../../i18n";
import { useTranslation } from "react-i18next";
import {
  getCategoryAction,
  getProductInfoByIdAction,
  getSubCategoryAction,
  getInnerCategoryAction,
  getProductTypeListAction,
  getBrandListAction,
  updateProductAction,
  getProductSizeListAction,
  getProductByIdAndColorProductIdAction,
  getColorListForProduct,
} from "../../Action/user.action";

const EditProduct = () => {
  let params = useParams();
  const { t, i18n } = useTranslation();
  const [color, setColor] = useState([]);
  const [form, setForm] = useState({
    productName: "",
    productName_ru: "",
    description: "",
    description_ru: "",
    colorId: "",
    colorProductId: "",
    categoryId: "",
    subCategoryId: "",
    innerCategoryId: "",
    productTypeId: "",
    brandId: "",
    productId: "",
  });
  const [translation, setTranslation] = useState();
  const [category, setCategory] = useState([]);
  const [subCategory, setSubCategory] = useState([]);
  const [innerCategory, setInnerCategory] = useState([]);
  const [productType, setProductType] = useState([]);
  const [product, setProduct] = useState([]);
  const [brand, setBrand] = useState([]);
  const [sizes, setSize] = useState([]);
  let [categorySizes, setcategorySizes] = useState([]);
  const [oldImages, setoldImages] = useState([]);
  const [defaultQty, setdefaultQty] = useState(false);
  const [qtyBasedOnSize, setqtyBasedOnSize] = useState(false);
  const [isSizeAvailable, setIsSizeAvailable] = useState("");
  const [isSubmitting, setisSubmitting] = useState(false);
  const [validationErrors, setValidationErrors] = useState({
    name: "",
    nameRu: "",
    price: "",
    image1: "",
    departmentId: "",
    categoryId: "",
    subcategoryId: "",
    productTypeId: "",
    brandId: "",
  });
  const [imagePreviews, setImagePreviews] = useState({
    image1: null,
    image2: null,
    image3: null,
    image4: null,
    image5: null,
  });

  const handleQuantityType = (type) => {
    if (type === "0") {
      setIsSizeAvailable("0");
      setdefaultQty(true);
      setqtyBasedOnSize(false);
    } else if (type === "1") {
      setIsSizeAvailable("1");
      setdefaultQty(false);
      setqtyBasedOnSize(true);
    }
  };

  const inputHandler = async (e) => {
    const { name, value } = e.target;
    setForm((old) => {
      return { ...old, [name]: value };
    });
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      [name]: value.trim() === "" ? `${name} is required` : "",
    }));
  };

  const handleChange = async (e) => {
    const { name, value } = e.target;
    setForm((old) => {
      return { ...old, [name]: value };
    });
  };

  useEffect(() => {
    fetchCategoryList();
    fetchSubCategoryList();
    fetchInnerCategoryList();
    fetchProductTypeList();
    fetchBrandsList();
    fetchProductSizeList();
    fetchProductInfoById();
    fetchColorList();
  }, []);

  const fetchTranslations = async () => {
    try {
      const translations = await fetchAndSetTranslations("ru");
      setTranslation(translations);
    } catch (error) {
      console.error("An error occurred while fetching translations:", error);
    }
  };

  const fetchCategoryList = async () => {
    try {
      const res = await getCategoryAction();
      if (res.success) {
        setCategory(res.data.filter((item) => item.isActive == 1));
      }
    } catch (error) {
      console.error("An error occurred while getting Category:", error);
    }
  };

  const fetchSubCategoryList = async () => {
    try {
      const res = await getSubCategoryAction();
      if (res.success) {
        setSubCategory(res.data.filter((item) => item.isActive == 1));
      }
    } catch (error) {
      console.error("An error occurred while getting Sub Category:", error);
    }
  };

  const fetchInnerCategoryList = async () => {
    try {
      const res = await getInnerCategoryAction();
      if (res.success) {
        setInnerCategory(res.data.filter((item) => item.isActive == 1));
      }
    } catch (error) {
      console.error("An error occurred while getting Inner Category:", error);
    }
  };

  const fetchProductTypeList = async () => {
    try {
      const res = await getProductTypeListAction();
      console.log(res, "RES");
      if (res.success) {
        setProductType(res.data.productTypes);
      }
    } catch (error) {
      console.error("An error occurred while getting Product Type:", error);
    }
  };

  const fetchBrandsList = async () => {
    try {
      const res = await getBrandListAction();
      if (res.success) {
        setBrand(res.data.filter((item) => item.isActive == 1));
      }
    } catch (error) {
      console.error("An error occurred while getting Brand:", error);
    }
  };

  const fetchProductSizeList = async () => {
    try {
      const res = await getProductSizeListAction();
      if (res.success) {
        setSize(res.data);
      }
    } catch (error) {
      console.error("An error occurred while getting Sizes:", error);
    }
  };

  const fetchColorList = async () => {
    try {
      const res = await getColorListForProduct();
      if (res.success) {
        setColor(res.data);
      }
    } catch (error) {
      console.error("An error occurred while getting Category:", error);
    }
  };

  const fetchProductByIdAndColorProductIdAction = async () => {
    try {
      const res = await getProductByIdAndColorProductIdAction();
      if (res.success) {
        let data = res.data;
        setProduct(data);
      }
    } catch (error) {
      console.error("An error occurred while getting Category:", error);
    }
  };

  useEffect(() => {
    setcategorySizes(sizes.filter((o) => o.categoryId == form.categoryId));
    if (form.subCategoryId != "") {
      setcategorySizes(
        sizes.filter((o) => o.subCategoryId == form.subCategoryId),
      );
    }
  }, [form.categoryId, form.subCategoryId]);

  const fetchProductInfoById = async () => {
    try {
      const products = await getProductInfoByIdAction({ id: params.id });
      let matchProduct = products.data;
      if (matchProduct) {
        setForm({
          ...matchProduct,
          productName_ru: t(matchProduct.productName),
          description_ru: t(matchProduct.description),
          composition_ru: t(matchProduct.composition),
          highlights_ru: t(matchProduct.highlights),
          wearing_ru: t(matchProduct.wearing),
          washingInstruction_ru: t(matchProduct.washingInstruction),
        });
        setoldImages({
          image1: matchProduct.images[0],
          image2: matchProduct.images[1],
          image3: matchProduct.images[2],
          image4: matchProduct.images[3],
          image5: matchProduct.images[4],
        });
        setIsSizeAvailable(matchProduct.isSizeAvailable);
        if (matchProduct.isSizeAvailable == 0) {
          setIsSizeAvailable("0");
          setdefaultQty(true);
          setqtyBasedOnSize(false);
        } else if (matchProduct.isSizeAvailable == 1) {
          setIsSizeAvailable("1");
          setdefaultQty(false);
          setqtyBasedOnSize(true);
        }
        setcategorySizes(matchProduct.sizes || []);
      }
    } catch (error) {
      console.error("Error fetching Product detail:", error);
    }
  };

  const [image1, setImage1] = useState([]);
  const [image2, setImage2] = useState([]);
  const [image3, setImage3] = useState([]);
  const [image4, setImage4] = useState([]);
  const [image5, setImage5] = useState([]);

  const [imgDimentionErr, setimgDimentionErr] = useState("");
  const handleImageChange = (e, i) => {
    const selectedImages = e.target.files;
    if (selectedImages.length > 0) {
      const image = selectedImages[0];
      validateImageDimensions(image, selectedImages, i);
    }
  };

  const validateImageDimensions = (image, selectedImages, i) => {
    const img = new Image();
    img.src = URL.createObjectURL(image);

    img.onload = () => {
      if (img.width !== 480 || img.height !== 640) {
        setValidationErrors((prevErrors) => ({
          ...prevErrors,
          [`image${i}`]: "Height should be 640 and width should be 480 pixels.",
        }));
        setimgDimentionErr(
          "Height should be 640 and width should be 480 pixels.",
        );
        return;
      } else {
        setValidationErrors((prevErrors) => ({
          ...prevErrors,
          [`image${i}`]: "", // Clear dimension error if dimensions are correct
        }));
        setimgDimentionErr("");
      }

      // If all checks pass, set the image state
      switch (i) {
        case 1:
          setImage1(selectedImages);
          previewImage(selectedImages[0], "image1");
          break;
        case 2:
          setImage2(selectedImages);
          previewImage(selectedImages[0], "image2");
          break;
        case 3:
          setImage3(selectedImages);
          previewImage(selectedImages[0], "image3");
          break;
        case 4:
          setImage4(selectedImages);
          previewImage(selectedImages[0], "image4");
          break;
        case 5:
          setImage5(selectedImages);
          previewImage(selectedImages[0], "image5");
          break;
        default:
          break;
      }
    };
  };

  const previewImage = (file, imageKey) => {
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreviews((prevPreviews) => ({
          ...prevPreviews,
          [imageKey]: reader.result,
        }));
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setisSubmitting(true);
    if (!validateForm()) {
      setisSubmitting(false);
      return;
    }
    let qtySizeObj = [];
    if (isSizeAvailable == 1) {
      const categorySizesId = categorySizes.map(({ id }) => id);
      let totalQty = 0;
      categorySizesId.map((id) => {
        let obj = {
          sizeId: id,
          quantity: document.getElementById("qtyInput_" + id).value,
          onSizePrice: document.getElementById("amtInput_" + id).value,
        };
        obj.onSizePrice = Math.abs(form.price - obj.onSizePrice);

        qtySizeObj.push(obj);
        if (obj.quantity !== "") {
          totalQty += parseInt(obj.quantity);
        }
      });
      form.quantity = totalQty;
    }

    let data = {
      ...form,
      // productId: params.id,
      // image1: image1[0],
      // image2: image2[0] ? image2[0] : [],
      // image3: image3[0] ? image3[0] : [],
      // image4: image4[0] ? image4[0] : [],
      // image5: image5[0] ? image5[0] : [],
      // isSizeAvailable: isSizeAvailable,
      // qtySizeObj: JSON.stringify(qtySizeObj),
    };

    console.log(data);
    const res = await updateProductAction(data);
    if (res.success) {
      toast.success(res.msg);
      setTimeout(() => {
        window.location.href = `${baseurl}productList`;
      }, 1200);
      fetchTranslations();
    } else {
      toast.error(res.msg);
    }
  };

  const validateForm = () => {
    let isValid = true;
    const errors = {
      name: "",
      nameRu: "",
      price: "",
      image1: "",
      departmentId: "",
      categoryId: "",
      subcategoryId: "",
      productTypeId: "",
    };

    if (form.name === "") {
      errors.name = "Product name is required";
      isValid = false;
    }

    if (form.nameRu === "" || !form.nameRu) {
      errors.nameRu = "Product name is required";
      isValid = false;
    }

    // if (form.price === "") {
    //   errors.price = "Price is required";
    //   isValid = false;
    // }

    if (form.image1 === "" && !imgDimentionErr) {
      if (imgDimentionErr != "") {
        errors.image1 = imgDimentionErr;
      } else {
        errors.image1 = "Image is required";
      }
      isValid = false;
    }

    if (form.categoryId === "") {
      errors.categoryId = "Category is required";
      isValid = false;
    }

    if (form.subcategoryId === "") {
      errors.subcategoryId = "Sub category is required";
      isValid = false;
    }

    if (form.departmentId === "") {
      errors.departmentId = "Inner category is required";
      isValid = false;
    }

    if (form.productTypeId === "") {
      errors.productTypeId = "Product type is required";
      isValid = false;
    }

    // if (form.brandId === "" || form.brandId == 0) {
    //   errors.brandId = "Brand name is required";
    //   isValid = false;
    // }

    setValidationErrors(errors);
    if (!isValid) {
      window.scrollTo(0, 0);
    }
    return isValid;
  };

  return (
    <>
      <div className="page">
        <Sidebar />
        <Header />
        <Toaster />
        <div className="content">
          <div className="grid grid-cols-12 gap-6 p-4">
            <div className="col-span-12">
              <div className="box fields">
                <div className="box-header">
                  <h5 className="box-title">Edit Product</h5>
                </div>
                <div className="box-body">
                  <form onSubmit={handleSubmit}>
                    <div className="space-y-4">
                      <div className="space-y-2 mb-3">
                        <label className="ti-form-label mb-2">
                          Product Name
                        </label>
                        <input
                          type="text"
                          className="my-auto ti-form-input"
                          name="name"
                          placeholder="Product Name (EN)"
                          value={form.name}
                          onChange={handleChange}
                        />
                        <span className="text-red-500 text-sm">
                          {validationErrors.name}
                        </span>
                      </div>

                      <div className="space-y-2 mb-3">
                        {console.log("nameRu", form.nameRu)}
                        <input
                          type="text"
                          className="my-auto ti-form-input"
                          name="nameRu"
                          placeholder="Product Name (RU)"
                          value={form.nameRu}
                          onChange={handleChange}
                        />
                        <span className="text-red-500 text-sm">
                          {validationErrors.nameRu}
                        </span>
                      </div>

                      <div className="space-y-2 mb-3">
                        <label className="ti-form-label mb-2">
                          Description
                        </label>
                        <textarea
                          className="my-auto ti-form-input"
                          placeholder="Description (EN)"
                          name="description"
                          value={form.description}
                          onChange={handleChange}
                        ></textarea>
                      </div>

                      <div className="space-y-2 mb-3">
                        <textarea
                          className="my-auto ti-form-input"
                          placeholder="Description (RU)"
                          name="description_ru"
                          value={form.description_ru}
                          onChange={handleChange}
                        ></textarea>
                      </div>

                      {/* <div className="space-y-2 mb-3">
                        <label className="ti-form-label mb-2">
                          Price (USD)
                        </label>
                        <input
                          type="number"
                          className="my-auto ti-form-input"
                          placeholder="Price"
                          name="price"
                          value={form.price}
                          onChange={handleChange}
                        />
                        <span className="text-red-500 text-sm">
                          {validationErrors.price}
                        </span>
                      </div>

                      <div className="space-y-2 mb-3">
                        <label className="ti-form-label mb-2">
                          Discount (%)
                        </label>
                        <input
                          type="number"
                          className="my-auto ti-form-input"
                          placeholder="Discount"
                          name="discount"
                          value={form.discount}
                          onChange={handleChange}
                        />
                      </div> */}
                      <div className="space-y-2 mb-3">
                        <label className="ti-form-label mb-2">
                          Department Name
                        </label>
                        <select
                          className="my-auto ti-form-select"
                          name="departmentId"
                          value={form.departmentId}
                          onChange={handleChange}
                        >
                          <option value="">Select Category</option>
                          {category.map((item) => (
                            <option key={item.id} value={item.id}>
                              {item.name}
                            </option>
                          ))}
                        </select>
                        <span className="text-red-500 text-sm">
                          {validationErrors.departmentId}
                        </span>
                      </div>

                      <div className="space-y-2 mb-3">
                        <label className="ti-form-label mb-2">
                          Category Name
                        </label>
                        <select
                          className="my-auto ti-form-select"
                          name="categoryId"
                          value={form.categoryId}
                          onChange={handleChange}
                        >
                          <option value="">Select Category</option>
                          {subCategory
                            .filter(
                              (item) => item.departmentId == form.departmentId,
                            )
                            .map((item) => (
                              <option key={item.id} value={item.id}>
                                {item.name}
                              </option>
                            ))}
                        </select>
                        <span className="text-red-500 text-sm">
                          {validationErrors.categoryId}
                        </span>
                      </div>

                      <div className="space-y-2 mb-3">
                        <label className="ti-form-label mb-2">
                          Sub Category Name
                        </label>
                        <select
                          className="my-auto ti-form-select"
                          name="subcategoryId"
                          value={form.subcategoryId}
                          onChange={handleChange}
                        >
                          <option value="">Select Sub Category</option>
                          {innerCategory
                            .filter(
                              (item) => item.categoryId == form.categoryId,
                            )
                            .map((item) => (
                              <option key={item.id} value={item.id}>
                                {item.name}
                              </option>
                            ))}
                        </select>
                        <span className="text-red-500 text-sm">
                          {validationErrors.subcategoryId}
                        </span>
                      </div>

                      <div className="space-y-2 mb-3">
                        <label className="ti-form-label mb-2">
                          Product Type Name
                        </label>
                        <select
                          className="my-auto ti-form-select"
                          name="productTypeId"
                          value={form.productTypeId}
                          onChange={handleChange}
                        >
                          <option value="">Select Product Type Name</option>

                          {productType
                            ?.filter(
                              (item) =>
                                item.subcategoryId == form.subcategoryId,
                            )
                            .map((item) => (
                              <option key={item.id} value={item.id}>
                                {item.name}
                              </option>
                            ))}
                        </select>
                        <span className="text-red-500 text-sm">
                          {validationErrors.productTypeId}
                        </span>
                      </div>

                      {/* <div className="space-y-2 mb-3">
                        <label className="ti-form-label mb-2">Brand Name</label>
                        <select
                          className="my-auto ti-form-select"
                          name="brandId"
                          value={form.brandId}
                          onChange={handleChange}
                        >
                          <option value="">Select Brand Name</option>
                          {brand
                            .filter(
                              (item) => item.categoryId == form.categoryId,
                            )
                            .map((item) => (
                              <option key={item.id} value={item.id}>
                                {item.name}
                              </option>
                            ))}
                        </select>
                        <span className="text-red-500 text-sm">
                          {validationErrors.brandId}
                        </span>
                      </div> */}

                      <div className="space-y-2 mb-3">
                        <label className="ti-form-label mb-2">Color Name</label>
                        <select
                          className="my-auto ti-form-select"
                          name="colorId"
                          value={form.colorId}
                          onChange={handleChange}
                        >
                          <option value="">Select Color Name</option>
                          {color.map((item) => (
                            <option key={item.id} value={item.id}>
                              {item.name}
                            </option>
                          ))}
                        </select>
                        <span className="text-red-500 text-sm">
                          {validationErrors.colorId}
                        </span>
                      </div>

                      {/* <div className="space-y-2">
                        <label className="ti-form-label mb-0">
                          Color of Product
                        </label>
                        <select
                          className="my-auto ti-form-select"
                          name="colorProductId"
                          value={form.colorProductId}
                          onChange={inputHandler}
                        >
                          <option value="">Select color of product</option>
                          {product
                            .filter(
                              (item) =>
                                item.categoryId == form.categoryId,
                            )
                            .map((item) => (
                              <option key={item.id} value={item.id}>
                                {item.name}
                              </option>
                            ))}
                        </select>
                      </div> */}

                      <div className="space-y-2 mb-3">
                        <label className="ti-form-label mb-2">
                          Image1* (640x480 pixels)
                        </label>
                        <input
                          accept="image/x-png,image/gif,image/jpeg,image/webp"
                          type="file"
                          name="image1"
                          className="my-auto ti-form-input"
                          onChange={(e) => handleImageChange(e, 1)}
                        />
                        {imagePreviews.image1 && (
                          <img
                            src={imagePreviews.image1}
                            alt="Image Preview"
                            style={{ maxWidth: "200px" }}
                          />
                        )}
                        {!imagePreviews.image1 && oldImages.image1 && (
                          <img
                            src={`${process.env.REACT_APP_MEDIA_URL + oldImages.image1.url}`}
                            alt="image"
                            style={{ maxWidth: "200px" }}
                          />
                        )}
                        <span className="text-red-500 text-sm">
                          {validationErrors.image1}
                        </span>
                      </div>

                      <div className="space-y-2 mb-3">
                        <label className="ti-form-label mb-2">
                          Image 2 (640x480 pixels)
                        </label>
                        <input
                          accept="image/x-png,image/gif,image/jpeg,image/webp"
                          type="file"
                          name="image2"
                          className="my-auto ti-form-input"
                          onChange={(e) => handleImageChange(e, 2)}
                        />
                        {imagePreviews.image2 && (
                          <img
                            src={imagePreviews.image2}
                            alt="Image Preview"
                            style={{ maxWidth: "200px" }}
                          />
                        )}
                        {!imagePreviews.image2 && oldImages.image2 && (
                          <img
                            src={`${process.env.REACT_APP_MEDIA_URL + oldImages.image2.url}`}
                            alt="image"
                            style={{ maxWidth: "200px" }}
                          />
                        )}
                      </div>

                      <div className="space-y-2 mb-3">
                        <label className="ti-form-label mb-2">
                          Image 3 (640x480 pixels)
                        </label>
                        <input
                          accept="image/x-png,image/gif,image/jpeg,image/webp"
                          type="file"
                          name="image3"
                          className="my-auto ti-form-input"
                          onChange={(e) => handleImageChange(e, 3)}
                        />
                        {imagePreviews.image3 && (
                          <img
                            src={imagePreviews.image3}
                            alt="Image"
                            style={{ maxWidth: "200px" }}
                          />
                        )}
                        {!imagePreviews.image3 && oldImages.image3 && (
                          <img
                            src={`${process.env.REACT_APP_MEDIA_URL + oldImages.image3.url}`}
                            alt="image"
                            style={{ maxWidth: "200px" }}
                          />
                        )}
                      </div>

                      <div className="space-y-2 mb-3">
                        <label className="ti-form-label mb-2">
                          Image 4 (640x480 pixels)
                        </label>
                        <input
                          accept="image/x-png,image/gif,image/jpeg,image/webp"
                          type="file"
                          name="image4"
                          className="my-auto ti-form-input"
                          onChange={(e) => handleImageChange(e, 4)}
                        />
                        {imagePreviews.image4 && (
                          <img
                            src={imagePreviews.image4}
                            alt="Image Preview"
                            style={{ maxWidth: "200px" }}
                          />
                        )}
                        {!imagePreviews.image4 && oldImages.image4 && (
                          <img
                            src={`${process.env.REACT_APP_MEDIA_URL + oldImages.image4.url}`}
                            alt="image"
                            style={{ maxWidth: "200px" }}
                          />
                        )}
                      </div>

                      <div className="space-y-2 mb-3">
                        <label className="ti-form-label mb-2">
                          Image 5 (640x480 pixels)
                        </label>
                        <input
                          accept="image/x-png,image/gif,image/jpeg,image/webp"
                          type="file"
                          name="image5"
                          className="my-auto ti-form-input"
                          onChange={(e) => handleImageChange(e, 5)}
                        />
                        {imagePreviews.image5 && (
                          <img
                            src={imagePreviews.image5}
                            alt="Image Preview"
                            style={{ maxWidth: "200px" }}
                          />
                        )}
                        {!imagePreviews.image5 && oldImages.image5 && (
                          <img
                            src={`${process.env.REACT_APP_MEDIA_URL + oldImages.image5.url}`}
                            alt="image"
                            style={{ maxWidth: "200px" }}
                          />
                        )}
                      </div>

                      {/* Sizes section start */}
                      <div className="space-y-1 mb-3">
                        <label className="ti-form-label mb-2">
                          Quantity Type
                        </label>
                        <select
                          className="my-auto ti-form-select"
                          name="isSizeAvailable"
                          onChange={(e) => handleQuantityType(e.target.value)}
                          value={isSizeAvailable}
                        >
                          <option value="" disabled>
                            Select Quantity Type
                          </option>
                          <option
                            value="0"
                            selected={isSizeAvailable == 0 ? true : false}
                          >
                            Only available in single size
                          </option>
                          <option
                            value="1"
                            selected={isSizeAvailable == 1 ? true : false}
                          >
                            Quantity based on sizes
                          </option>
                        </select>
                      </div>
                      {defaultQty ? (
                        <div className="space-y-2 mb-3">
                          <label className="ti-form-label mb-2">Quantity</label>
                          <input
                            type="number"
                            className="my-auto ti-form-input"
                            name="productQuantity"
                            placeholder="Product Quantity"
                            value={form.productQuantity}
                            onChange={inputHandler}
                          />
                        </div>
                      ) : (
                        ""
                      )}

                      {qtyBasedOnSize ? (
                        <div className="space-y-2 mb-3">
                          <label className="ti-form-label mb-2">
                            Add quantity based on size
                          </label>
                          <div className="table-responsive">
                            <table className="table table-bordered">
                              <thead>
                                <tr>
                                  <th>Size Name</th>
                                  <th>Quantity</th>
                                  <th>On Size Price</th>
                                </tr>
                              </thead>
                              <tbody>
                                {form.categoryId != "" ? (
                                  categorySizes.map((item) => (
                                    <tr>
                                      <td>
                                        <input
                                          type="text"
                                          className="my-auto ti-form-input"
                                          placeholder="Size Name"
                                          readOnly
                                          value={item.name}
                                        />
                                      </td>

                                      <td>
                                        <input
                                          type="text"
                                          className="my-auto ti-form-input"
                                          placeholder="0"
                                          value={item.quantity}
                                          id={`qtyInput_${item.id}`}
                                        />
                                      </td>
                                      <td>
                                        <input
                                          type="number"
                                          className="my-auto ti-form-input"
                                          placeholder="0"
                                          value={
                                            item.quantity == 0
                                              ? 0
                                              : item.onSizePrice
                                          }
                                          id={`amtInput_${item.id}`}
                                        />
                                      </td>
                                    </tr>
                                  ))
                                ) : (
                                  <tr>
                                    <td colSpan={3}>
                                      <center>
                                        Please select <b>category</b> or{" "}
                                        <b>sub-category</b> first.
                                      </center>
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}

                      {/* Sizes section end */}

                      {/* <div className="space-y-2 mb-3">
                        <label className="ti-form-label mb-2">
                          Composition
                        </label>
                        <textarea
                          className="my-auto ti-form-input"
                          placeholder="composition (EN)"
                          name="composition"
                          value={form.composition}
                          onChange={handleChange}
                        ></textarea>
                      </div>

                      <div className="space-y-2 mb-3">
                        <textarea
                          className="my-auto ti-form-input"
                          placeholder="composition (RU)"
                          name="composition_ru"
                          value={form.composition_ru}
                          onChange={handleChange}
                        ></textarea>
                      </div>

                      <div className="space-y-2 mb-3">
                        <label className="ti-form-label mb-2">Highlights</label>
                        <textarea
                          className="my-auto ti-form-input"
                          placeholder="Highlights (EN)"
                          name="highlights"
                          value={form.highlights}
                          onChange={handleChange}
                        ></textarea>
                      </div>

                      <div className="space-y-2 mb-3">
                        <textarea
                          className="my-auto ti-form-input"
                          placeholder="Highlights (RU)"
                          name="highlights_ru"
                          value={form.highlights_ru}
                          onChange={handleChange}
                        ></textarea>
                      </div> */}

                      {/* <div className="space-y-2 mb-3">
                        <label className="ti-form-label mb-2">Wearing</label>
                        <textarea
                          className="my-auto ti-form-input"
                          placeholder="Wearing (EN)"
                          name="wearing"
                          value={form.wearing}
                          onChange={handleChange}
                        ></textarea>
                      </div>

                      <div className="space-y-2 mb-3">
                        <textarea
                          className="my-auto ti-form-input"
                          placeholder="Wearing (RU)"
                          name="wearing_ru"
                          value={form.wearing_ru}
                          onChange={handleChange}
                        ></textarea>
                      </div>

                      <div className="space-y-2 mb-3">
                        <label className="ti-form-label mb-2">
                          {" "}
                          Washing Instruction
                        </label>
                        <textarea
                          className="my-auto ti-form-input"
                          placeholder="Washing Instruction (EN)"
                          name="washingInstruction"
                          value={form.washingInstruction}
                          onChange={handleChange}
                        ></textarea>
                      </div>

                      <div className="space-y-2 mb-3">
                        <textarea
                          className="my-auto ti-form-input"
                          placeholder="Washing Instruction (RU)"
                          name="washingInstruction_ru"
                          value={form.washingInstruction_ru}
                          onChange={handleChange}
                        ></textarea>
                      </div> */}
                    </div>
                    <button type="submit" className="ti-btn ti-btn-primary">
                      Submit
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default EditProduct;
