import { DASHBOARD_CONFIG } from './const';

export const DashboardCards = (statistics) => {
  return DASHBOARD_CONFIG.map((i) => {
    return (
      <a href={`${process.env.REACT_APP_BASE_URL}${i.link ?? '#'}`} className="totalbox">
        <div className="box">
          <div className="box-body">
            <div className="total_inr">
              <div className="flex items-center justify-center ecommerce-icon px-0 me-lg-3 me-md-3">
                <span className="rounded-sm p-2 bg-primary/10 text-black">{i.icon}</span>
              </div>
              <div className="ms-3">
                <div className="mb-2 font-normal text-black">{i.title}</div>
                <div className="text-gray-500 dark:text-white/70 mb-1 text-xs">
                  <span className="text-gray-800 font-semibold text-xl leading-none align-bottom dark:text-white">
                    <h5 className="mb-0 font-normal dashboard_content">{statistics?.statistics[i.key]}</h5>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </a>
    );
  });
};
