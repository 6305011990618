import React, { useEffect, useState } from "react";
import { Modal } from "react-responsive-modal";
import { ToastContainer, toast } from "react-toastify";
import Sidebar from "../../directives/sidebar";
import Header from "../../directives/header";
import Footer from "../../directives/footer";
import DataTable from "react-data-table-component";
import { SketchPicker, BlockPicker } from "react-color";
import {
  updateColor,
  addColor,
  updateColorStatus,
  getColorList,
  deleteColor,
} from "../../Action/user.action";
import Swal from "sweetalert2";
import "react-responsive-modal/styles.css";
import "react-toastify/dist/ReactToastify.css";
import Togglesidebar from "../../directives/togglesidebar";
import { tableStyles } from "../../config/tableStyles";

const Color = () => {
  const [sketchPickerColor, setSketchPickerColor] = useState({});
  const [loader, setLoader] = useState(true);
  const [sublisting, setSubListing] = useState([]);
  const [open, setOpen] = useState(false);
  const [modalSecond, setModalSecond] = useState(false);
  const [formData, setFormData] = useState({ name: "" });
  const [validationErrors, setValidationErrors] = useState({});
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    fetchColorList();
  }, []);

  const clearValidationError = (fieldName) => {
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      [fieldName]: undefined,
    }));
  };

  const handleColorChange = (e) =>
    setFormData({ ...formData, name: e.target.value });

  const StatusUpdate = async (data) => {
    console.log(data);
    let res = await updateColorStatus({
      id: data.id,
      status: data.status === 0 ? "1" : "0",
    });
    if (res.success) {
      fetchColorList();
    } else {
      Swal.fire("Failed!", res.msg, "error");
    }
  };

  const updateDeleteStatus = async (data) => {
    Swal.fire({
      title: "Are you sure?",
      text: `You want to Delete it!`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: `Yes, Delete it!`,
    }).then(async (result) => {
      if (result.isConfirmed) {
        let res = await deleteColor({
          id: data.id,
        });
        if (res.success) {
          fetchColorList();
        } else {
          Swal.fire("Failed!", res.msg, "error");
        }
      }
    });
  };

  // Add Inner Category-------------
  const addColorHandler = async (e) => {
    e.preventDefault();
    const errors = {};

    if (formData.name === "") {
      errors.name = "Color name is required";
    }

    if (
      !sketchPickerColor ||
      sketchPickerColor.length == undefined ||
      sketchPickerColor == ""
    ) {
      errors.sketchPickerColor = "Color code is required";
    }

    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
    } else {
      // Clear validation errors
      setValidationErrors({});
      try {
        formData.code = sketchPickerColor;

        const response = await addColor(formData);
        if (response.success) {
          toast.success("Success", {
            position: toast.POSITION.TOP_CENTER,
          });
          onCloseModal();
          fetchColorList();
          setSketchPickerColor({});
        } else {
          toast.error(response.msg, {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      } catch (error) {
        console.error("An error occurred while adding the color:", error);
      }
    }
  };

  // Update InnerCategory -------------
  const updateColorHandler = async (e) => {
    e.preventDefault();
    try {
      const errors = {};
      const data = {
        id: formData.id,
        name: formData.name,
      };

      if (data.name === "") {
        errors.name = "Color name is required";
      }

      if (
        !sketchPickerColor ||
        sketchPickerColor.length == undefined ||
        sketchPickerColor == ""
      ) {
        errors.sketchPickerColor = "Color code is required";
      }

      if (Object.keys(errors).length > 0) {
        setValidationErrors(errors);
      } else {
        // Clear validation errors
        setValidationErrors({});

        data.code = sketchPickerColor;

        const response = await updateColor(data);
        if (response.success) {
          toast.success("Success", {
            position: toast.POSITION.TOP_CENTER,
          });
          onCloseModalSecond();
          fetchColorList();
          setSketchPickerColor({});
        } else {
          toast.error(response.msg, {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      }
    } catch (error) {
      console.error("An error occurred while updating the color:", error);
    }
  };

  // Fetch Color Size------------------
  const fetchColorList = async () => {
    try {
      setLoader(true);
      const res = await getColorList();
      if (res.success) {
        setLoader(false);
        setSubListing(res.data);
      }
      setLoader(false);
    } catch (error) {
      console.error(
        "An error occurred while getting inner subcategories:",
        error,
      );
    }
  };

  // Open modal for editing
  const onOpenModalSecond = (color) => {
    setFormData({
      name: color.name,
      id: color.id,
    });
    setSketchPickerColor(color.code);
    setModalSecond(true);
  };

  // Open modal for adding
  const onOpenModal = () => {
    setFormData({
      name: "",
    });
    setOpen(true);
  };

  // Close modal for adding
  const onCloseModal = () => {
    setOpen(false);
    setValidationErrors({});
    setSketchPickerColor({});
  };

  // Close modal for editing
  const onCloseModalSecond = () => {
    setModalSecond(false);
  };

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  const filteredData = sublisting.filter((item, index) => {
    item.index = index;
    return Object.values(item).some((value) =>
      String(value).toLowerCase().includes(searchText.toLowerCase()),
    );
  });

  const columns = [
    {
      name: "№",
      selector: (row) => `${row.index + 1}`,
      sortable: true,
    },
    {
      name: "Color Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Color Code",
      selector: (row) => row.code,
      sortable: true,
    },
    {
      name: "Action",
      cell: (row) => {
        return (
          <div className="button_wrp">
            <button
              onClick={() => onOpenModalSecond(row)}
              className="btn btn-outline-primary"
            >
              <i className="fa fa-pencil-square-o fa-xs" aria-hidden="true"></i>
            </button>
            {row.status === 0 ? (
              <button
                onClick={() => StatusUpdate(row)}
                className="btn btn-danger redclosebtn"
              >
                <i className="fa fa-times fa-s" aria-hidden="true"></i>
              </button>
            ) : row.status === 1 ? (
              <button
                onClick={() => StatusUpdate(row)}
                className="btn btn-outline-success"
              >
                <i className="fa fa-check  fa-xs" aria-hidden="true"></i>
              </button>
            ) : (
              ""
            )}
            <button
              onClick={() => updateDeleteStatus(row)}
              className="btn btn-outline-danger"
            >
              <i className="fa fa-trash fa-xs" aria-hidden="true"></i>
            </button>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <div className="page">
        <div className="content">
          <div className="main-content">
            <div className="block justify-between page-header md:flex"></div>
            <div className="grid grid-cols-12 gap-6">
              <div className="col-span-12">
                <div className="box">
                  <div className="box-header">
                    <h5 className="box-title font-normal">Color List</h5>
                    <button
                      type="button"
                      onClick={onOpenModal}
                      className="ti-btn ti-btn-outline-primary"
                      style={{ float: "right", marginTop: "-35px" }}
                    >
                      Add
                    </button>
                  </div>
                  <div className="box-body">
                    {/* Search input */}
                    <input
                      type="text"
                      value={searchText}
                      onChange={handleSearch}
                      placeholder="Search..."
                      className="px-2 py-1 border rounded mb-4"
                    />
                    {!loader ? (
                      <div className="overflow-hidden table-bordered">
                        <DataTable
                          columns={columns}
                          data={filteredData}
                          pagination
                          paginationTotalRows={filteredData.length}
                          customStyles={tableStyles}
                        />
                      </div>
                    ) : (
                      <>
                        <br />
                        <br />
                        <center>
                          <h4>
                            <i className="fa fa-spinner fa-spin"></i> &nbsp;
                            Please wait
                          </h4>
                        </center>
                        <br />
                        <br />
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Modal
          open={open}
          onClose={onCloseModal}
          center
          closeOnOverlayClick={false}
          closeOnEsc={false}
        >
          <label className="font-normal" htmlFor="categoryId">
            Add Color
          </label>
          <hr />
          <div className="form-group mb-3">
            <label className="mb-2 font-normal" htmlFor="name">
              Color Name
            </label>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={(e) => {
                handleColorChange(e);
                clearValidationError("name");
              }}
              className="form-control select"
              id="InnerSubcategoryName"
              placeholder="Color Name"
            />
            {validationErrors.name && (
              <span className="text-danger">{validationErrors.name}</span>
            )}
          </div>

          <div className="form-group mb-3">
            <label className="mb-2 font-normal" htmlFor="code">
              Color Code
            </label>
            <div className="space-y-2">
              <SketchPicker
                onChange={(color) => {
                  setSketchPickerColor(color.hex);
                }}
                color={sketchPickerColor}
              />
            </div>
            {validationErrors.sketchPickerColor && (
              <span className="text-danger">
                {validationErrors.sketchPickerColor}
              </span>
            )}
          </div>

          <button
            type="submit"
            onClick={addColorHandler}
            className="btn btn-primary modal-footer"
          >
            Add
          </button>
        </Modal>
        <Modal
          open={modalSecond}
          onClose={onCloseModalSecond}
          center
          closeOnOverlayClick={false}
          closeOnEsc={false}
        >
          <label className="mb-2 font-normal" htmlFor="categoryId">
            Edit Color
          </label>
          <hr />

          <div className="form-group mb-3">
            <label className="mb-2 font-normal" htmlFor="name">
              Color Name
            </label>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={(e) => {
                handleColorChange(e);
                clearValidationError("name");
              }}
              className="form-control select"
              id="name"
              placeholder="Color Name"
            />
            {validationErrors.name && (
              <span className="text-danger">{validationErrors.name}</span>
            )}
          </div>

          <div className="form-group mb-3">
            <label className="mb-2 font-normal" htmlFor="code">
              Color Code
            </label>
            <div className="space-y-2">
              <SketchPicker
                onChange={(color) => {
                  setSketchPickerColor(color.hex);
                }}
                color={sketchPickerColor}
              />
            </div>
            {validationErrors.sketchPickerColor && (
              <span className="text-danger">
                {validationErrors.sketchPickerColor}
              </span>
            )}
          </div>

          <br />
          <button
            type="submit"
            onClick={updateColorHandler}
            className="btn btn-primary modal-footer"
          >
            Update
          </button>
        </Modal>
        <ToastContainer />
      </div>
    </>
  );
};

export default Color;
