import React, { useEffect, useState } from "react";
import Sidebar from "../../directives/sidebar";
import Header from "../../directives/header";
import Footer from "../../directives/footer";
import DataTable from "react-data-table-component";
import Swal from "sweetalert2";
import {
  getBrandListAction,
  insertBrandNameAction,
  updateBrandNameAction,
  updateBrandStatusAction,
  deleteBrandAction,
} from "../../Action/user.action";
import "react-responsive-modal/styles.css";
import "react-toastify/dist/ReactToastify.css";
import { Modal } from "react-responsive-modal";
import { ToastContainer, toast } from "react-toastify";
import { Form } from "react-bootstrap";
import { tableStyles } from "../../config/tableStyles";
import { Loader } from "../UI/Loader";

const Brand = () => {
  const [loader, setLoader] = useState(true);
  const [listing, setListing] = useState([]);
  const [open, setOpen] = useState(false);
  const [modalSecond, setModalSecond] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState({
    name: "",
  });
  const [formData, setFormData] = useState({ name: "" });
  const [validationErrors, setValidationErrors] = useState({});
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    fetchBrandList();
  }, []);

  const handlebrandNameChange = (e) => {
    setSelectedCategory((prevCategory) => ({
      ...prevCategory,
      name: e.target.value,
    }));
  };

  const clearValidationError = (fieldName) => {
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      [fieldName]: undefined,
    }));
  };

  // Add category ----------------------------------------
  const addCategory = async (e) => {
    e.preventDefault();
    const errors = {};

    if (formData.name === "") {
      errors.name = "Brand name is required";
    }

    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
    } else {
      // Clear validation errors
      setValidationErrors({});
      try {
        const response = await insertBrandNameAction(formData);
        if (response.success) {
          toast.success("Success", {
            position: toast.POSITION.TOP_CENTER,
          });
          onCloseModal();
          fetchBrandList();
        } else {
          toast.error(response.msg, {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      } catch (error) {
        console.error("An error occurred while adding the category:", error);
      }
    }
  };

  const StatusUpdate = async (id) => {
    let res = await updateBrandStatusAction({
      id: id,
    });
    if (res.success) {
      fetchBrandList();
    } else {
      Swal.fire("Failed!", res.msg, "error");
    }
  };

  const updateDeleteStatus = async (data) => {
    Swal.fire({
      title: "Are you sure?",
      text: `You want to Delete it!`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: `Yes, Delete it!`,
    }).then(async (result) => {
      if (result.isConfirmed) {
        let res = await deleteBrandAction({
          id: data.id,
        });
        if (res.success) {
          fetchBrandList();
        } else {
          Swal.fire("Failed!", res.msg, "error");
        }
      }
    });
  };

  // Update category  ---------------------------------
  const updateCategory = async (e) => {
    e.preventDefault();
    const errors = {};
    if (selectedCategory.name === "") {
      errors.name = "Brand name is required";
    }

    console.log(formData);
    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
    } else {
      // Clear validation errors
      setValidationErrors({});
      try {
        const response = await updateBrandNameAction(selectedCategory);
        if (response.success) {
          toast.success("Success", {
            position: toast.POSITION.TOP_CENTER,
          });
          onCloseModalSecond();
          fetchBrandList();
        } else {
          console.error("Failed to update category");
        }
      } catch (error) {
        console.error("An error occurred while updating the category:", error);
      }
    }
  };

  // Get listing -------------------------------
  const fetchBrandList = async () => {
    try {
      setLoader(true);
      const res = await getBrandListAction();
      if (res.success) {
        setLoader(false);
        setListing(res.data);
      }
    } catch (error) {
      console.error("An error occurred while getting the listing:", error);
    }
  };

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  const filteredData = React.useMemo(() => {
    return listing.filter((item, index) => {
      item.index = index;
      item = Object.values(item).some((value) =>
        String(value).toLowerCase().includes(searchText.toLowerCase()),
      );
      return item;
    });
  }, [listing, searchText]);

  const columns = [
    {
      name: "№",
      selector: (row) => `${row.index + 1}`,
      sortable: true,
    },
    {
      name: "Brand Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Is Active",
      cell: (row) => {
        return (
          <Form>
            <Form.Check
              checked={row.isActive}
              onClick={() => StatusUpdate(row.id)}
            />
          </Form>
        );
      },
    },
    {
      name: "Action",
      cell: (row) => {
        return (
          <div className="button_wrp">
            <button
              onClick={() => onOpenModalSecond(row)}
              className="btn btn-outline-primary"
            >
              <i class="fa fa-pencil-square-o fa-xs" aria-hidden="true"></i>
            </button>
            {row.status == 0 ? (
              <button
                onClick={() => StatusUpdate(row)}
                className="btn btn-danger redclosebtn"
              >
                <i class="fa fa-times fa-s" aria-hidden="true"></i>
              </button>
            ) : row.status == 1 ? (
              <button
                onClick={() => StatusUpdate(row)}
                className="btn btn-success"
              >
                <i class="fa fa-check  fa-xs" aria-hidden="true"></i>
              </button>
            ) : (
              ""
            )}
            <button
              onClick={() => updateDeleteStatus(row)}
              className="btn btn-outline-danger"
            >
              <i class="fa fa-trash fa-xs" aria-hidden="true"></i>
            </button>
          </div>
        );
      },
    },
  ];

  // Modal code  ---------------------------------------
  const onOpenModalSecond = (category) => {
    setSelectedCategory(category);
    setModalSecond(true);
  };

  const onCloseModalSecond = () => setModalSecond(false);

  const onOpenModal = () => setOpen(true);

  const onCloseModal = () => {
    setFormData({ name: "" });
    setOpen(false);
  };
  return (
    <>
      <div className="content">
        {/* Start::main-content */}
        <div className="main-content">
          <div className="block justify-between page-header md:flex"></div>

          <div className="grid grid-cols-12 gap-6">
            <div className="col-span-12">
              <div className="box">
                <div className="box-header">
                  <h5 className="box-title font-normal">Brand List</h5>
                  <button
                    type="button"
                    onClick={onOpenModal}
                    className="ti-btn ti-btn-outline-primary"
                    style={{ float: "right", marginTop: "-35px" }}
                  >
                    Add
                  </button>
                </div>
                <div className="box-body">
                  <input
                    type="text"
                    value={searchText}
                    onChange={handleSearch}
                    placeholder="Search..."
                    className="px-2 py-1 border rounded mb-4"
                  />
                  {!loader ? (
                    <div className="overflow-hidden table-bordered">
                      <DataTable
                        columns={columns}
                        data={filteredData}
                        pagination
                        paginationPerPage={10}
                        paginationRowsPerPageOptions={[10]}
                        paginationTotalRows={filteredData.length}
                        customStyles={tableStyles}
                      />
                    </div>
                  ) : (
                    <Loader />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Start::main-content */}
      </div>
      {/* First modal: Add category -------------- */}
      <Modal
        open={open}
        onClose={onCloseModal}
        center
        closeOnOverlayClick={false}
        closeOnEsc={false}
      >
        <label className="mb-2 font-normal" htmlFor="categoryId">
          Add Brand Name
        </label>
        <hr />

        <div className="form-group">
          <label className="" htmlFor="name">
            Brand Name
          </label>
          <br />

          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={(e) =>
              setFormData(
                { ...formData, name: e.target.value },
                clearValidationError("name"),
              )
            }
            className="form-control select"
            id="name"
            style={{ marginTop: "10px", marginBottom: "10px" }}
            placeholder="Brand Name"
          />
          {validationErrors.name && (
            <span className="text-danger">{validationErrors.name}</span>
          )}
        </div>

        {/* <br/> */}
        <button
          type="submit"
          onClick={addCategory}
          className="btn btn-primary "
        >
          Add
        </button>
      </Modal>
      {/* Edit modal code --------- */}
      <Modal
        open={modalSecond}
        onClose={onCloseModalSecond}
        center
        closeOnOverlayClick={false}
        closeOnEsc={false}
      >
        <label className="mb-2 font-normal" htmlFor="categoryId">
          Edit Brand Name
        </label>
        <hr />

        <div className="form-group">
          <label className="font-normal" htmlFor="name">
            Brand Name
          </label>
          <br />
          <br />
          <input
            type="text"
            name="name"
            value={selectedCategory.name}
            onChange={(e) => {
              handlebrandNameChange(e);
              clearValidationError("name");
            }}
            className="form-control select"
            id="name"
            placeholder="Brand Name"
          />
          {validationErrors.name && (
            <span className="text-danger">{validationErrors.name}</span>
          )}
        </div>
        <br />
        <button
          type="submit"
          onClick={updateCategory}
          className="btn btn-primary "
        >
          Update
        </button>
      </Modal>
      <ToastContainer />
    </>
  );
};

export default Brand;
