import React, { useEffect, useState } from "react";
import { getDashboardStatisticsAction } from "../../Action/user.action";
import { DashboardCard, DashboardCards } from "./DashboardCards";
import { useGetDashboardQuery } from "../../store/api/dashboardApi";

const Dashboard = () => {
  const { data: statistics } = useGetDashboardQuery();

  return (
    <div className="content">
      <div className="main-content">
        <div className="block justify-between page-header md:flex">
          <div>
            <h3 className="text-gray-700 hover:text-gray-900 dark:text-white dark:hover:text-white text-2xl font-normal">
              {" "}
              Dashboard
            </h3>
          </div>
        </div>
        <div className=" gap-x-5 box_content">
          {statistics && <DashboardCards statistics={statistics.data} />}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
