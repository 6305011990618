import { PutRequest, deleteRequest, getRequest, postRequest, postRequestFormData, putRequestFormData } from '../helper';

export const LoginAction = (data) => {
  return postRequest('adminLogin', data).then((res) => {
    return res.data;
  });
};

export const getCurrenciesAction = (data) => {
  return getRequest('getCurrencies', data).then((res) => {
    return res.data;
  });
};

export const getDutiesAction = (data) => {
  return getRequest('getDuties', data).then((res) => {
    return res.data;
  });
};

export const createDuty = (data) => {
  return postRequest('createDuty', data).then((res) => {
    return res.data;
  });
};

export const updateDuty = (data) => {
  return PutRequest(`updateDuty`, data, 'PATCH').then((res) => {
    return res.data;
  });
};

export const deleteDuty = (data) => {
  return deleteRequest(`deleteDuty`, data).then((res) => {
    return res.data;
  });
};

//-------------------------------|| DASHBOARD ||---------------------------------

export const getDashboardStatisticsAction = (data) => {
  return getRequest('getDashboardStatistics', data).then((res) => {
    return res.data;
  });
};

// Department and Category Section-----------------------------------------------------------

export const getCategoryAction = (data) => {
  return getRequest('department', data).then((res) => {
    return res.data;
  });
};
export const addCategoryAction = (data) => {
  return postRequest('addCategory', data).then((res) => {
    return res.data;
  });
};
export const UpdateCategoryAction = (data) => {
  return PutRequest(`department/${data.id}`, data, 'PATCH').then((res) => {
    return res.data;
  });
};

export const deleteCategoryAction = (data) => {
  return PutRequest(`department/delete/${data.id}`, { isDeleted: true }, 'PATCH').then((res) => {
    return res.data;
  });
};

export const categoryStatusUpdateAction = (data) => {
  return PutRequest(`department/status/${data.id}`, { isActive: !data.status }, 'PATCH').then((res) => {
    return res.data;
  });
};

export const subCategoryStatusUpdateAction = (data) => {
  return PutRequest(`category/status/${data.id}`, { isActive: !data.status }, 'PATCH').then((res) => {
    return res.data;
  });
};

// Sub-Category Section---------------------------------------------------------
export const getSubCategoryAction = (data) => {
  return getRequest('category', data).then((res) => {
    return res.data;
  });
};

export const addSubCategoryAction = (data) => {
  return postRequestFormData('category', data).then((res) => {
    return res.data;
  });
};

export const UpdateSubCategoryAction = (data) => {
  return putRequestFormData(`category/${data.id}`, data, 'PATCH').then((res) => {
    return res.data;
  });
};

export const deleteSubCategoryAction = (data) => {
  return PutRequest(`category/delete/${data.id}`, { isDeleted: true }, 'PATCH').then((res) => {
    return res.data;
  });
};

// Sub-Inner-Category Section---------------------------------------------------
export const getInnerCategoryAction = (data) => {
  return getRequest('getSubcategories', data).then((res) => {
    return res.data;
  });
};

export const addInnerCategoryAction = (data) => {
  return postRequest('insertSubcategories', {
    name: data.name,
    name_ru: data.name_ru,
    category_id: Number(data.categoryId),
  }).then((res) => {
    return res.data;
  });
};

export const UpdateInnerCategoryAction = (data) => {
  return PutRequest(`updateSubcategories`, data, 'PATCH').then((res) => {
    return res.data;
  });
};

export const deleteInnerCategoryAction = (data) => {
  return PutRequest(`deleteSubcategories`, data, 'PATCH').then((res) => {
    return res.data;
  });
};

// Delivery charges and taxes Section---------------------------------------------------

export const getDeliveriesAction = () => {
  return getRequest('getDeliveries').then((res) => {
    return res.data;
  });
};

export const getDeliverAndTaxListAction = (data) => {
  return getRequest('getDeliverAndTaxList', data).then((res) => {
    return res.data;
  });
};

export const insertDeliverAndTaxAction = (data) => {
  return postRequest('insertDeliverAndTax', data).then((res) => {
    return res.data;
  });
};

export const updateDeliveryAndTaxAction = (data) => {
  return PutRequest('updateDeliveryAndTax', data, 'PATCH').then((res) => {
    return res.data;
  });
};

export const deleteDeliveryAndTaxesAction = (data) => {
  return PutRequest(`deleteDeliveryAndTaxes`, data, 'DELETE').then((res) => {
    return res.data;
  });
};

export const updateDeliverAndTaxStatusAction = (data) => {
  return PutRequest('updateDeliverAndTaxStatus', data).then((res) => {
    return res.data;
  });
};

// Product Type-Category Section--------------------------------------------------------

export const insertProductTypeAction = (data) => {
  return postRequest('productType', {
    subcategoryId: data.subcategoryId,
    name: data.name,
    name_ru: data.name_ru,
  }).then((res) => {
    return res.data;
  });
};

export const getProductTypeListAction = (data) => {
  return getRequest('productType', data).then((res) => {
    return res.data;
  });
};

export const getInnerCategoryListAction = (data) => {
  return getRequest('subcategory', data).then((res) => {
    return res.data;
  });
};

export const updateProductTypeAction = (data) => {
  return PutRequest(`productType/${data.id}`, { name: data.name, nameRu: data.nameRu }, 'PATCH').then((res) => {
    return res.data;
  });
};

export const updateProductTypeStatusAction = (data) => {
  return PutRequest(`productType/status/${data.id}`, { isActive: !data.status }, 'PATCH').then((res) => {
    return res.data;
  });
};

export const productStatusUpdateAction = (data) => {
  return PutRequest(`product/status/${data.id}`, { isActive: !data.status }, 'PATCH').then((res) => {
    return res.data;
  });
};

export const deleteProductTypeAction = (data) => {
  return PutRequest(`productType/delete/${data.id}`, { isDeleted: true }, 'PATCH').then((res) => {
    return res.data;
  });
};

// Sub-Inner-Category Section---------------------------------------------------

export const innerCategoryStatusUpdateAction = (data) => {
  return PutRequest(`updateSubcategoriesStatus`, data, 'PATCH').then((res) => {
    return res.data;
  });
};

//----------------------------------|| PRODUCTS ||------------------------------------------

export const getProductListAction = (data) => {
  return getRequest('product', data).then((res) => {
    return res.data;
  });
};

export const getProductInfoByIdAction = (data) => {
  return getRequest(`product/${data.id}`, data).then((res) => {
    return res.data;
  });
};

export const getBrandListAction = (data) => {
  return getRequest('brands', data).then((res) => {
    return res.data;
  });
};

export const addProductAction = (data) => {
  return postRequestFormData('product', data).then((res) => {
    return res.data;
  });
};

export const updateProductAction = (data) => {
  return putRequestFormData(`product/${data.id}`, data, 'PATCH').then((res) => {
    return res.data;
  });
};

export const getProductByIdAndColorProductIdAction = (data) => {
  return getRequest('getProductByIdAndColorProductId', data).then((res) => {
    return res.data;
  });
};

export const deleteProductAction = (data) => {
  return PutRequest(`product/delete/${data.id}`, { isDeleted: true }, 'PATCH').then((res) => {
    return res.data;
  });
};

// CMS modal ---------------------------------------------

// terms of promotions---------------
export const getTermsOfPromotionAction = (data) => {
  return getRequest('getTermsOfPromotion', data).then((res) => {
    return res.data;
  });
};
export const InsertTermsofPromotionAction = (data) => {
  return postRequest('insertTermsOfPromotionData', data).then((res) => {
    return res.data;
  });
};
export const UpdateTermsofPromotionAction = (data) => {
  return PutRequest('updateTermsOfPromotion', data).then((res) => {
    return res.data;
  });
};
export const DeleteProductAction = (data) => {
  return deleteRequest('deleteTermsOfPromotion', data).then((res) => {
    return res.data;
  });
};
// accessibility section---------------
export const getaccessibilityAction = (data) => {
  return getRequest('getAccessibility', data).then((res) => {
    return res.data;
  });
};
export const updateAccessibilityAction = (data) => {
  return PutRequest('updateAccessibility', data).then((res) => {
    return res.data;
  });
};

export const getUsersListAction = (data) => {
  return getRequest('getUserList', data).then((res) => {
    return res.data;
  });
};

export const userStatusUpdateAction = (data) => {
  return PutRequest(`userStatusUpdate/${data.id}`, { isActive: !data.status }, 'PATCH').then((res) => {
    return res.data;
  });
};

export const deleteUserAction = ({ id }) => {
  return PutRequest(`deleteUser/${id}`, { isDeleted: true }, 'PATCH').then((res) => {
    return res.data;
  });
};

//--------------------|| SIZE ||------------------------

export const getProductSizeListAction = (data) => {
  return getRequest('getSizes', data).then((res) => {
    return res.data;
  });
};

export const addProductSizeAction = (data) => {
  return postRequest('addSize', data).then((res) => {
    return res.data;
  });
};

export const updateSizeStatusAction = (data) => {
  return PutRequest('updateSizeStatus', data).then((res) => {
    return res.data;
  });
};

export const deleteSizesAction = (data) => {
  return PutRequest(`deleteSize`, data, 'DELETE').then((res) => {
    return res.data;
  });
};

export const getRegionListAction = (data) => {
  return getRequest('getRegionList', data).then((res) => {
    return res.data;
  });
};

export const updateProductSizeAction = (data) => {
  return PutRequest(`updateSize`, data, 'PATCH').then((res) => {
    return res.data;
  });
};

//--------------------|| BRANDS ||------------------------

export const insertBrandNameAction = (data) => {
  return postRequest('insertBrand', data).then((res) => {
    return res.data;
  });
};

export const updateBrandNameAction = (data) => {
  return PutRequest('updateBrand', data, 'PATCH').then((res) => {
    return res.data;
  });
};

export const updateBrandStatusAction = (data) => {
  return PutRequest('updateBrandStatus', data, 'PATCH').then((res) => {
    return res.data;
  });
};

export const deleteBrandAction = (data) => {
  return PutRequest(`deleteBrand`, data, 'DELETE').then((res) => {
    return res.data;
  });
};

//--------------------|| REGIONS ||------------------------

export const insertRegionNameAction = (data) => {
  return postRequestFormData('insertRegion', data).then((res) => {
    return res.data;
  });
};

export const updateRegionNameAction = (data) => {
  return putRequestFormData('updateRegion', data, 'PATCH').then((res) => {
    return res.data;
  });
};

export const updateRegionStatusAction = (data) => {
  return PutRequest('updateRegionStatus', data, 'PATCH').then((res) => {
    return res.data;
  });
};

export const deleteRegionAction = (data) => {
  return PutRequest(`deleteRegion`, data, 'DELETE').then((res) => {
    return res.data;
  });
};

//----------------------------------|| PRODUCT COMBINATION ||------------------------------------------

export const getCombinationListAction = (data) => {
  return getRequest('getCombinationList', data).then((res) => {
    return res.data;
  });
};

export const getCombinationListByIdAction = (data) => {
  return getRequest('getCombinationListById', data).then((res) => {
    return res.data;
  });
};

export const insertItemCombinationAction = (data) => {
  return postRequestFormData('insertItemCombination', data).then((res) => {
    return res.data;
  });
};

export const updateItemCombinationAction = (data) => {
  return putRequestFormData('updateItemCombination', data).then((res) => {
    return res.data;
  });
};

export const combninationStatusUpdateAction = (data) => {
  return PutRequest('combninationStatusUpdate', data).then((res) => {
    return res.data;
  });
};

export const deleteCombinationAction = (data) => {
  return PutRequest(`deleteCombination`, data).then((res) => {
    return res.data;
  });
};

//----------------------------------|| ADMIN PROFILE ||------------------------------------------

export const adminChangePasswordAction = (data) => {
  return PutRequest('adminChangePassword', data).then((res) => {
    return res.data;
  });
};

//----------------------------------|| PROMOCODE ||------------------------------------------

export const getPromoCodeAction = (data) => {
  return getRequest('getPromocodes', data).then((res) => {
    return res.data;
  });
};

export const insertPromoCodeAction = (data) => {
  return postRequest('insertPromoCode', data).then((res) => {
    return res.data;
  });
};

export const updatePromoCodeAction = (data) => {
  return PutRequest('updatePromoCode', data, 'PATCH').then((res) => {
    return res.data;
  });
};

export const updatePromoCodeStatusAction = (data) => {
  return PutRequest('updatePromocodeStatus', data, 'PATCH').then((res) => {
    return res.data;
  });
};

export const deletePromocodeAction = (data) => {
  return PutRequest(`deletePromocode`, data, 'DELETE').then((res) => {
    return res.data;
  });
};

//----------------------------------|| ORDER ||------------------------------------------

export const getOrderListAction = (data) => {
  return getRequest('getOrderList', data).then((res) => {
    return res.data;
  });
};

export const getOrderStatusesAction = (data) => {
  return getRequest('getOrderStatuses', data)
    .then((res) => {
      return res.data;
    })
    .catch((error) => console.log(error));
};

export const updateOrderStatusAction = (data) => {
  return PutRequest(`updateOrderStatus`, data).then((res) => {
    return res.data;
  });
};

export const updateOrderStatusWithCommentAction = (data) => {
  return PutRequest(`updateOrderItem`, data, 'PATCH').then((res) => {
    return res.data;
  });
};

export const updateCancelReasonAction = (data) => {
  return PutRequest(`updateCancelReason`, data).then((res) => {
    return res.data;
  });
};

export const cancelThisOrderAction = (data) => {
  return postRequest(`cancelThisOrder`, data).then((res) => {
    return res.data;
  });
};

export const getOrderDetailsByIdAction = (data) => {
  return getRequest('getOrderDetailsById', data).then((res) => {
    return res.data;
  });
};

export const getCancelAndReturnListAction = (data) => {
  return getRequest('getCancelAndReturnList', data).then((res) => {
    return res.data;
  });
};

export const updateCancelAndReturnStatusAction = (data) => {
  return PutRequest(`updateCancelAndReturnStatus`, data).then((res) => {
    return res.data;
  });
};

export const updateOrderStatusToRejectAction = (data) => {
  return PutRequest(`updateOrderStatusToReject`, data).then((res) => {
    return res.data;
  });
};
// get Cancel And Return List by Id Action
export const getCancelAndReturnListIdAction = (data) => {
  return getRequest('getCancelAndReturnDetailById', data).then((res) => {
    return res.data;
  });
};

export const getSupplierListAction = (data) => {
  return getRequest('getDeliveries', data).then((res) => {
    return res.data;
  });
};

export const updateDeliveries = (data) => {
  return PutRequest(`updateDeliveries`, data, 'PATCH').then((res) => {
    return res.data;
  });
};

export const addDeliveries = (data) => {
  return postRequest('addDeliveries', data).then((res) => {
    return res.data;
  });
};

export const getCMSPagesAction = (data) => {
  return getRequest('getPages', data).then((res) => {
    return res.data;
  });
};

export const deleteCmsPageAction = (data) => {
  return PutRequest(`deletePage`, data, 'DELETE').then((res) => {
    return res.data;
  });
};

export const insertCMSPagesAction = (data) => {
  return postRequest('addPages', data).then((res) => {
    return res.data;
  });
};

export const updateCMSPagesAction = (data) => {
  return PutRequest('updatePage', data, 'PATCH').then((res) => {
    return res.data;
  });
};

export const updatePagesStatusAction = (data) => {
  return PutRequest('updatePageStatus', data, 'PATCH').then((res) => {
    return res.data;
  });
};

export const getCMSContentByPageAction = (data) => {
  return getRequest('getTabsByPage', data).then((res) => {
    return res.data;
  });
};

export const getCMSContentByIdAction = (data) => {
  return getRequest('getTab', data).then((res) => {
    return res.data;
  });
};
// insert cms content
export const insertCMSContentAction = (data) => {
  return postRequest('addTab', data).then((res) => {
    return res.data;
  });
};

export const updateCMSContentAction = (data) => {
  return PutRequest('updateTab', data, 'PATCH').then((res) => {
    return res.data;
  });
};

export const updatePagesContentStatusAction = (data) => {
  return PutRequest('updatePagesContentStatus', data).then((res) => {
    return res.data;
  });
};

export const deletedPageContentAction = (data) => {
  return PutRequest('deleteTab', data, 'DELETE').then((res) => {
    return res.data;
  });
};

export const getContactUsListAction = (data) => {
  return getRequest('getContactUsList', data).then((res) => {
    return res.data;
  });
};

export const getFeedbackListAction = (data) => {
  return getRequest('getFeedbackList', data).then((res) => {
    return res.data;
  });
};

export const getContactUsDetailByIdAction = (data) => {
  return getRequest('getContactUsDetailById', data).then((res) => {
    return res.data;
  });
};

export const getMarginPercentListAction = (data) => {
  return getRequest('getMarginList', data).then((res) => {
    return res.data;
  });
};

export const insertMarginPercentAction = (data) => {
  return postRequest('insertMargin', data).then((res) => {
    return res.data;
  });
};

export const deleteMargin = (data) => {
  return deleteRequest('deleteMargin', data).then((res) => {
    return res.data;
  });
};

export const deleteDeliveries = (data) => {
  return deleteRequest('deleteDeliveries ', data).then((res) => {
    return res.data;
  });
};

export const updateMarginPercentAction = (data) => {
  return PutRequest('updateMargin', data, 'PATCH').then((res) => {
    return res.data;
  });
};

export const insertTextTranslationAction = (data) => {
  return postRequest('insertTextTranslation', data).then((res) => {
    return res.data;
  });
};

//--------------------|| REGIONS ||------------------------

export const getBannerListAction = (data) => {
  return getRequest('getBannerList', data).then((res) => {
    return res.data;
  });
};

export const updateHomeBannerAction = (data) => {
  return putRequestFormData('updateHomeBanner', data).then((res) => {
    return res.data;
  });
};

export const updateHomeBannerStatusAction = (data) => {
  return PutRequest('updateHomeBannerStatus', data).then((res) => {
    return res.data;
  });
};

//--------------------|| COLOR ||------------------------

export const getColorList = (data) => {
  return getRequest('getColorList', data).then((res) => {
    return res.data;
  });
};

export const addColor = (data) => {
  return postRequest('addColor', data).then((res) => {
    return res.data;
  });
};

export const updateColor = (data) => {
  return PutRequest('updateColor', data, 'PATCH').then((res) => {
    return res.data;
  });
};

export const updateColorStatus = (data) => {
  return PutRequest('updateColorStatus', data).then((res) => {
    return res.data;
  });
};

export const deleteColor = (data) => {
  return PutRequest('deleteColor', data, 'DELETE').then((res) => {
    return res.data;
  });
};

export const getColorListForProduct = (data) => {
  return getRequest('getColorList', data).then((res) => {
    return res.data;
  });
};

export const addTypeAction = (data) => {
  return postRequest('createFilter', data).then((res) => {
    return res.data;
  });
};

export const filterStatusUpdate = (data) => {
  return PutRequest('updateFilterStatus', data, 'PATCH').then((res) => {
    return res.data;
  });
};

export const updateFilterType = (data) => {
  return PutRequest('updateFilter', data, 'PATCH').then((res) => {
    return res.data;
  });
};

export const getMainPageModes = (data) => {
  return getRequest('getMainPageModes', data).then((res) => {
    return res.data;
  });
};

export const getMainPageSubcategories = (data) => {
  return getRequest('getMainPageSubcategories', data).then((res) => {
    return res.data;
  });
};

export const getMainPageSubcategory = (data) => {
  return getRequest('getMainPageSubcategory', data).then((res) => {
    return res.data;
  });
};

export const deleteMainPageSubcategory = (data) => {
  return PutRequest('deleteMainPageSubcategory', data, 'DELETE').then((res) => {
    return res.data;
  });
};

export const getCategoriesByDepartment = (data) => {
  return getRequest('getCategoriesByDepartment', data).then((res) => {
    return res.data;
  });
};

export const getSubcategoriesByCategory = (data) => {
  return getRequest('getSubcategoriesByCategory', data).then((res) => {
    return res.data;
  });
};

export const updateMainPageSubcategory = (data) => {
  return PutRequest('updateMainPageSubcategory', data, 'PATCH').then((res) => {
    return res.data;
  });
};

export const insertMainPageSubcategory = (data) => {
  return postRequest('insertMainPageSubcategory', data).then((res) => {
    return res.data;
  });
};

export const getDepartments = (data) => {
  return getRequest('getDepartments', data).then((res) => {
    return res.data;
  });
};

export const getMainProducts = (data) => {
  return getRequest('getMainProducts', data).then((res) => {
    return res.data;
  });
};

export const searchProducts = (data) => {
  return getRequest('searchProducts', data).then((res) => {
    return res.data;
  });
};

export const addMainProduct = (data) => {
  return postRequest('addMainProduct', data).then((res) => {
    return res.data;
  });
};

export const removeMainProduct = (data) => {
  return PutRequest('removeMainProduct', data, 'DELETE').then((res) => {
    return res.data;
  });
};

export const changeMainPageMode = (data) => {
  return PutRequest('changeMainPageMode', data, 'PATCH').then((res) => {
    return res.data;
  });
};

export const getSelectedCategory = (data) => {
  return getRequest('getSelectedCategory', data).then((res) => {
    return res.data;
  });
};

export const setSelectedCategory = (data) => {
  return postRequest('setSelectedCategory', data).then((res) => {
    return res.data;
  });
};

export const getBanners = (data) => {
  return getRequest('getBanners', data).then((res) => {
    return res.data;
  });
};

export const setBanner = (data) => {
  return postRequestFormData('setBanner', data).then((res) => {
    return res.data;
  });
};

export const getMainBanner = (data) => {
  return getRequest('getMainBanner', data).then((res) => {
    return res.data;
  });
};

export const mainPageStatus = (id) => {
  return PutRequest(`updateSubcategoriesOnDepartmentPage`, { id }, 'PATCH').then((res) => {
    return res.data;
  });
};

export const getSubDepartmentsByDepartment = (data) => {
  return getRequest('getSubDepartmentsByDepartment', data).then((res) => {
    return res.data;
  });
};

export const getCategoryBySubDepartment = (data) => {
  return getRequest('getCategoryBySubDepartment', data).then((res) => {
    return res.data;
  });
};

export const insertDeliveryPrices = (data) => {
  return postRequest('insertDeliveryPrices', data).then((res) => {
    return res.data;
  });
};

export const getDeliveryPrices = (data) => {
  return getRequest('getDeliveryPrices', data).then((res) => {
    return res.data;
  });
};

export const getStores = (data) => {
  return getRequest('getStores', data).then((res) => {
    return res.data;
  });
};

export const deleteDeliveryPrices = (data) => {
  return PutRequest('deleteDeliveryPrices', data, 'DELETE').then((res) => {
    return res.data;
  });
};

export const updateDeliveryPrices = (data) => {
  return PutRequest('updateDeliveryPrices', data, 'PATCH').then((res) => {
    return res.data;
  });
};

export const getRecommendationsModes = (data) => {
  return getRequest('getRecommendationModes', data).then((res) => {
    return res.data;
  });
};

export const updateRecommendationModeStatus = (data) => {
  return PutRequest('updateRecommendationModeStatus', data, 'PATCH').then((res) => {
    return res.data;
  });
};

export const getRecommendationSubcategories = (data) => {
  return getRequest('getRecommendationSubcategories', data).then((res) => {
    return res.data;
  });
};

export const insertRecommendationSubcategory = (data) => {
  return postRequest('insertRecommendationSubcategory', data).then((res) => {
    return res.data;
  });
};
export const deleteRecommendationSubcategory = (data) => {
  return PutRequest('deleteRecommendationSubcategory', data, 'DELETE').then((res) => {
    return res.data;
  });
};

export const getSubdepartments = (data) => {
  return getRequest('getSubdepartments', data).then((res) => {
    return res.data;
  });
};

export const insertSubdepartments = (data) => {
  return postRequest('insertSubdepartments', data).then((res) => {
    return res.data;
  });
};

export const deleteSubdepartments = (data) => {
  return PutRequest('deleteSubdepartments', data, 'DELETE').then((res) => {
    return res.data;
  });
};

export const updateSubdepartments = (data) => {
  return PutRequest('updateSubdepartments', data, 'PATCH').then((res) => {
    return res.data;
  });
};

export const getInnerCategories = (data) => {
  return getRequest('getInnerCategories', data).then((res) => {
    return res.data;
  });
};

export const getSubcategories = (data) => {
  return getRequest('getSubcategories', data).then((res) => {
    return res.data;
  });
};

export const insertInnerCategories = (data) => {
  return postRequest('insertInnerCategories', data).then((res) => {
    return res.data;
  });
};

export const updateInnerCategories = (data) => {
  return PutRequest('updateInnerCategories', data, 'PATCH').then((res) => {
    return res.data;
  });
};

export const deleteInnerCategories = (data) => {
  return deleteRequest('deleteInnerCategories', data).then((res) => {
    return res.data;
  });
};

export const delete_store_from_db = (data) => {
  return postRequest('delete_store_from_db', data).then((res) => {
    return res.data;
  });
};

export const getStoresSoft = (data) => {
  return getRequest('getStores', data).then((res) => {
    return res.data;
  });
};
