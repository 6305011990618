// Department refactoe and rename are needed
import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import Swal from 'sweetalert2';
import { fetchAndSetTranslations } from '../../i18n';
import { useTranslation } from 'react-i18next';

import {
  getCategoryAction,
  addCategoryAction,
  UpdateCategoryAction,
  categoryStatusUpdateAction,
  deleteCategoryAction,
} from '../../Action/user.action';
import 'react-responsive-modal/styles.css';
import 'react-toastify/dist/ReactToastify.css';
import { Modal } from 'react-responsive-modal';
import { ToastContainer, toast } from 'react-toastify';
import { tableStyles } from '../../config/tableStyles';
import { DELETE_MODAL_CONFIG } from '../../const';
import { Loader } from '../UI/Loader';

const Department = () => {
  const { t, i18n } = useTranslation();
  const [loader, setLoader] = useState(true);
  const [translation, setTranslation] = useState();
  const [listing, setListing] = useState([]);
  const [open, setOpen] = useState(false);
  const [modalSecond, setModalSecond] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState({});
  const [formData, setFormData] = useState({
    categoryName: '',
    categoryName_ru: '',
  });
  const [validationErrors, setValidationErrors] = useState({});

  useEffect(() => {
    getListingDetails();
    fetchTranslations();
  }, []);

  const fetchTranslations = async () => {
    try {
      const translations = await fetchAndSetTranslations('ru');
      setTranslation(translations);
    } catch (error) {
      console.error('An error occurred while fetching translations:', error);
    }
  };

  const handleCategoryNameChange = (e) => {
    setSelectedCategory({
      ...selectedCategory,
      [e.target.name]: e.target.value,
    });
    console.log(selectedCategory);
  };

  const clearValidationError = (fieldName) => {
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      [fieldName]: undefined,
    }));
  };

  // Add category ----------------------------------------
  const addCategory = async (e) => {
    e.preventDefault();
    const errors = {};
    if (!formData.categoryName_ru && formData.categoryName_ru === '') {
      errors.categoryName_ru = 'Category name is required';
    }

    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
    } else {
      // Clear validation errors
      setValidationErrors({});
      try {
        const res = await addCategoryAction(formData);
        if (res.success) {
          toast.success(res.msg, {
            position: toast.POSITION.TOP_CENTER,
          });
          onCloseModal();
          getListingDetails();
          fetchTranslations();
        } else {
          toast.error(res.msg, {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      } catch (error) {
        console.error('An error occurred while adding the category:', error);
      }
    }
  };

  const StatusUpdate = async (data) => {
    let res = await categoryStatusUpdateAction({
      id: data.id,
      status: data.isActive,
    });
    if (res.success) {
      getListingDetails();
    } else {
      Swal.fire('Failed!', res.msg, 'error');
    }
  };

  const updateDeleteStatus = async (data) => {
    Swal.fire(DELETE_MODAL_CONFIG).then(async (result) => {
      if (result.isConfirmed) {
        let res = await deleteCategoryAction({
          id: data.id,
          status: data.status === 1,
        });
        if (res.success) {
          getListingDetails();
        } else {
          Swal.fire('Failed!', res.msg, 'error');
        }
      }
    });
  };

  // Update category  ---------------------------------
  const updateCategory = async (e) => {
    e.preventDefault();
    const errors = {};
    if (!selectedCategory.name || selectedCategory.nameRu === '') {
      errors.nameRu = 'Name is required';
    }
    console.log(selectedCategory, 'Selected Category');
    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
    } else {
      // Clear validation errors
      setValidationErrors({});
      try {
        const res = await UpdateCategoryAction({
          id: selectedCategory.id,
          name: selectedCategory.name,
          nameRu: selectedCategory.nameRu,
        });
        if (res.success) {
          toast.success('Success', {
            position: toast.POSITION.TOP_CENTER,
          });
          onCloseModalSecond();
          setTimeout(() => {
            fetchTranslations();
            getListingDetails();
          }, 0);
        } else {
          toast.error(res.msg, {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      } catch (error) {
        console.error('An error occurred while updating the category:', error);
      }
    }
  };

  // Get listing -------------------------------
  const getListingDetails = async () => {
    try {
      setLoader(true);
      const res = await getCategoryAction();
      if (res.success) {
        setLoader(false);
        setListing(res.data);
      }
    } catch (error) {
      console.error('An error occurred while getting the listing:', error);
    }
  };

  const columns = [
    {
      name: 'Category',
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: 'Status',
      selector: (row) => {
        return (
          <>
            {row.isActive ? (
              <div style={{ color: 'green' }}> Active</div>
            ) : (
              <div style={{ color: 'red' }}> Inactive</div>
            )}
          </>
        );
      },
      sortable: true,
    },
    {
      name: 'Action',
      cell: (row) => {
        return (
          <div className="button_wrp">
            <button onClick={() => onOpenModalSecond(row)} className="btn btn-outline-primary">
              <i class="fa fa-pencil-square-o fa-xs" aria-hidden="true"></i>
            </button>
            {row.isActive == 1 ? (
              <button onClick={() => StatusUpdate(row)} className="btn btn-outline-success">
                <i class="fa fa-check  fa-xs" aria-hidden="true"></i>
              </button>
            ) : row.isActive == 0 ? (
              <button onClick={() => StatusUpdate(row)} className="btn btn-outline-danger">
                <i class="fa fa-times fa-s" aria-hidden="true"></i>
              </button>
            ) : null}
            <button onClick={() => updateDeleteStatus(row)} className="btn btn-outline-danger">
              <i class="fa fa-trash fa-xs" aria-hidden="true"></i>
            </button>
          </div>
        );
      },
    },
  ];

  // Modal code  ---------------------------------------
  const onOpenModalSecond = (category) => {
    setSelectedCategory(category);
    setModalSecond(true);
  };

  const onCloseModalSecond = () => setModalSecond(false);

  const onOpenModal = () => setOpen(true);

  const onCloseModal = () => {
    setFormData({ categoryName: '' });
    setOpen(false);
  };

  return (
    <>
      <div className="content">
        {/* Start::main-content */}
        <div className="main-content">
          <div className="block justify-between page-header md:flex"></div>

          <div className="grid grid-cols-12 gap-6">
            <div className="col-span-12">
              <div className="box">
                <div className="box-header">
                  <h5 className="box-title font-normal">Departments List</h5>
                </div>

                <div className="box-body">
                  {!loader ? (
                    <div className="overflow-hidden table-bordered">
                      <DataTable columns={columns} data={listing} pagination customStyles={tableStyles} />
                    </div>
                  ) : (
                    <Loader />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Start::main-content */}
      </div>
      {/* First modal: Add category -------------- */}
      <Modal open={open} onClose={onCloseModal} center>
        <label className="mb-2" htmlFor="categoryId">
          <h4>Category</h4>
        </label>
        <hr />
        <div className="form-group mb-3">
          <label className="mb-2" htmlFor="categoryName">
            Category (EN)
          </label>
          <input
            type="text"
            name="categoryName"
            value={formData.categoryName}
            onChange={(e) =>
              setFormData({ ...formData, categoryName: e.target.value }, clearValidationError('categoryName'))
            }
            className="form-control"
            id="categoryName"
            style={{ marginTop: '10px', marginBottom: '10px' }}
            placeholder="Category (EN)"
          />
          {validationErrors.categoryName && <span className="text-danger">{validationErrors.categoryName}</span>}
        </div>

        {/* Add Category in Russian */}

        <div className="form-group mb-3">
          <label className="mb-2" htmlFor="categoryName">
            Category (RU)
          </label>
          <input
            type="text"
            name="categoryName_ru"
            value={formData.categoryName_ru}
            onChange={(e) =>
              setFormData({ ...formData, categoryName_ru: e.target.value }, clearValidationError('categoryName_ru'))
            }
            className="form-control"
            id="categoryName_ru"
            style={{ marginTop: '10px', marginBottom: '10px' }}
            placeholder="Category (RU)"
          />
          {validationErrors.categoryName_ru && <span className="text-danger">{validationErrors.categoryName_ru}</span>}
        </div>
        {/* <br/> */}
        <button type="submit" onClick={addCategory} className="btn btn-outline-primary ">
          Add
        </button>
      </Modal>
      {/* Edit modal code --------- */}
      <Modal open={modalSecond} onClose={onCloseModalSecond} center closeOnOverlayClick={false} closeOnEsc={false}>
        <label className="mb-2" htmlFor="categoryId font-normal">
          Department
        </label>
        <div className="form-group mb-3">
          <input
            type="text"
            name="name"
            value={selectedCategory.name}
            onChange={(e) => {
              handleCategoryNameChange(e);
              clearValidationError('name');
            }}
            className="form-control select"
            id="name"
            placeholder="Department name"
          />
          {validationErrors.name && <span className="text-danger">{validationErrors.name}</span>}
        </div>

        <div className="form-group mb-3">
          <input
            type="text"
            name="nameRu"
            value={selectedCategory.nameRu}
            onChange={(e) => {
              handleCategoryNameChange(e);
              clearValidationError('nameRu');
            }}
            className="form-control select"
            id="nameRu"
            placeholder="Department name ru"
          />
          {validationErrors.nameRu && <span className="text-danger">{validationErrors.nameRu}</span>}
        </div>

        {/* <br /> */}
        <button type="submit" onClick={updateCategory} className="btn btn-outline-primary">
          Update
        </button>
      </Modal>
      <ToastContainer />
    </>
  );
};

export default Department;
