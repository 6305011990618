import { apiSlice } from "./apiSlice";

export const categoryApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCategories: builder.query({
      query: (params) => ({
        params: params,
        url: "category",
      }),
      providesTags: ["Categories"],
    }),
    createCategory: builder.mutation({
      query: (body) => ({
        body: body,
        url: "category",
        method: "POST",
      }),
      invalidatesTags: ["Categories"],
    }),
    getFilters: builder.query({
      query: (params) => ({
        params: params,
        url: "getFilters",
      }),
      providesTags: ["Filters"],
    }),
    getFiltersValue: builder.query({
      query: (params) => ({
        params: params,
        url: "getFilterDetail",
      }),
      providesTags: ["FiltersValues"],
    }),
  }),
});

export const { useGetCategoriesQuery, useCreateCategoryMutation, useGetFiltersQuery, useGetFiltersValueQuery } = categoryApi;
