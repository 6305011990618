import { AiFillShop } from 'react-icons/ai';
import { FaUserShield, FaUsers } from 'react-icons/fa';
import { IoIosCart } from 'react-icons/io';
import { IoQrCodeOutline } from 'react-icons/io5';
import { LuRefreshCwOff } from 'react-icons/lu';
import { MdProductionQuantityLimits } from 'react-icons/md';
import { TbBrandTether, TbTruckReturn } from 'react-icons/tb';

export const DASHBOARD_CONFIG = [
  {
    title: 'Total Orders',
    key: 'totalOrders',
    icon: <IoIosCart className="total_icons" />,
    link: 'order',
  },
  {
    title: 'Today Placed Orders',
    key: 'todayOrders',
    icon: <AiFillShop className="total_icons" />,
    link: 'order',
  },
  {
    title: 'Total Users',
    key: 'totalUsers',
    icon: <FaUsers className="total_icons" />,
    link: 'users',
  },
  {
    title: 'Total Active Users',
    key: 'activeUsers',
    icon: <FaUserShield className="total_icons" />,
    link: 'users',
  },
  {
    title: 'Total Products',
    key: 'totalProducts',
    icon: <MdProductionQuantityLimits className="total_icons" />,
    link: 'productType',
  },
  {
    title: 'Total Brands',
    key: 'totalBrands',
    icon: <TbBrandTether className="total_icons" />,
    link: 'brand',
  },
  {
    title: 'Total Promo Code',
    key: 'totalPromocodes',
    icon: <IoQrCodeOutline className="total_icons" />,
  },
  {
    title: 'Total Cancel Orders',
    key: 'totalCancelOrders',
    icon: <LuRefreshCwOff className="total_icons" />,
    link: 'order',
  },
  {
    title: 'Total Return Orders',
    key: 'totalReturns',
    icon: <TbTruckReturn className="total_icons" />,
    link: 'order',
  },
];
