export const Loader = () => {
  return (
    <>
      <br />
      <br />
      <center>
        <h4>
          <i className="fa fa-spinner fa-spin"></i> &nbsp; Please wait
        </h4>
      </center>
      <br />
      <br />
    </>
  );
};
