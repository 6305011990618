import { useQuery, useMutation } from '@tanstack/react-query';
import { deleteSubdepartments, getSubdepartments } from '../../../Action/user.action';
import DataTable from 'react-data-table-component';
import { tableStyles } from '../../../config/tableStyles';
import { AddSubdepartment } from './AddSubdepartment';
import { UpdateSubdepartment } from './UpdateSubdepartment';

export const Subdepartment = () => {
  const {
    data: subdepartments,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ['subdepartments'],
    queryFn: () => getSubdepartments().then((res) => res.data),
  });

  const mutation = useMutation({
    mutationFn: deleteSubdepartments,
    onSuccess: () => {
      refetch();
    },
    onError: (error) => {
      console.log(error);
    },
    onMutate: () => {},
  });

  const onOpenModalSecond = (row) => {
    console.log(row);
  };

  const updateDeleteStatus = (row) => {
    mutation.mutate({ id: row.id });
  };

  const columns = [
    {
      name: 'SubDepartment',
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: 'Department',
      selector: (row) => row.department,
      sortable: true,
    },
    {
      name: 'Action',
      cell: (row) => {
        return (
          <div className="button_wrp">
            <UpdateSubdepartment row={row} />
            <button onClick={() => updateDeleteStatus(row)} className="btn btn-outline-danger">
              <i class="fa fa-trash fa-xs" aria-hidden="true"></i>
            </button>
          </div>
        );
      },
    },
  ];

  return (
    <div className="content">
      <div className="main-content">
        <div className="grid grid-cols-12 gap-6 mt-5">
          <div className="col-span-12">
            <div className="box">
              <div className="box-header">
                <h5 className="box-title font-normal">Subdepartments List</h5>
                <AddSubdepartment />
              </div>
              <div className="box-body">
                <div className="overflow-hidden table-bordered">
                  <DataTable columns={columns} data={subdepartments} pagination customStyles={tableStyles} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
