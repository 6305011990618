import { useQuery } from '@tanstack/react-query';
import { getInnerCategories, deleteInnerCategories } from '../../../Action/user.action';
import { AddInnerCategories } from './AddInnerCategories';
import { UpdateInnerCategories } from './UpdateInnerCategories';
import DataTable from 'react-data-table-component';
import { tableStyles } from '../../../config/tableStyles';

export const InnerCategories = () => {
  const { data: inner_categories, refetch } = useQuery({
    queryKey: ['inner_categories'],
    queryFn: () => getInnerCategories().then((res) => res.data),
  });

  const updateDeleteStatus = async (row) => {
    try {
      const res = await deleteInnerCategories({ id: row.id });
      console.log(res);
    } catch (error) {
      console.log(error);
    } finally {
      refetch();
    }
  };

  const columns = [
    {
      name: 'Category',
      selector: (row) => row.category_name,
      sortable: true,
    },
    {
      name: 'Inner Category',
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: 'Action',
      cell: (row) => {
        return (
          <div className="button_wrp">
            <UpdateInnerCategories row={row} />
            <button onClick={() => updateDeleteStatus(row)} className="btn btn-outline-danger">
              <i class="fa fa-trash fa-xs" aria-hidden="true"></i>
            </button>
          </div>
        );
      },
    },
  ];

  return (
    <div className="content">
      <div className="main-content">
        <div className="grid grid-cols-12 gap-6 mt-5">
          <div className="col-span-12">
            <div className="box">
              <div className="box-header">
                <h5 className="box-title font-normal">Inner Categories List</h5>
                <AddInnerCategories />
              </div>
              <div className="box-body">
                <div className="overflow-hidden table-bordered">
                  <DataTable columns={columns} data={inner_categories} pagination customStyles={tableStyles} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
