import React, { useState } from 'react';
import { Modal } from 'react-responsive-modal';
import { useForm, Controller } from 'react-hook-form';
import DataTable from 'react-data-table-component';
import { tableStyles } from '../../config/tableStyles';
import { useQuery } from '@tanstack/react-query';
import {
  deleteDeliveryPrices,
  getDeliveriesAction,
  getDeliveryPrices,
  getRegionListAction,
  getStores,
  getSupplierListAction,
} from '../../Action/user.action';
import { UpdateDeliveryModal } from './updateModal';
import DeliveryAndTaxAdd from './DeliveryAndTaxAdd';

export default function DeliveryAndTaxUpdate() {
  const [open, setOpen] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [selectedDelivery, setSelectedDelivery] = useState(null);

  const { data: regions } = useQuery({
    queryKey: ['regions'],
    queryFn: async () => await getRegionListAction().then((res) => res.data),
  });

  const { data: deliveryData, refetch: refetchDeliveryData } = useQuery({
    queryKey: ['deliveryData'],
    queryFn: async () => await getDeliveryPrices().then((res) => res.data ?? []),
  });

  const { data: stores } = useQuery({
    queryKey: ['stores'],
    queryFn: async () => await getStores().then((res) => res.data ?? []),
  });

  const { data: delivery_names } = useQuery({
    queryKey: ['delivery_names'],
    queryFn: async () => await getSupplierListAction().then((res) => res.data ?? []),
  });

  const setSecondModal = (delivery) => {
    setSelectedDelivery(delivery);
    setEditModal(true);
  };

  const deleteDelivery = async (id) => {
    try {
      const res = await deleteDeliveryPrices({ id: id.id });
    } catch (err) {
      console.log(err);
    } finally {
      refetchDeliveryData();
    }
  };

  const columns = [
    {
      name: 'ID',
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: 'Отправитель',
      selector: (row) => row.sender_region,
      sortable: true,
    },
    {
      name: 'Получатель',
      selector: (row) => row.receiver_region,
      sortable: true,
    },
    {
      name: 'Магазин',
      selector: (row) => row.store,
      sortable: true,
    },
    {
      name: 'Курьерская служба №1',
      selector: (row) => row.delivery_1,
    },
    {
      name: 'Стоимость',
      selector: (row) => row.delivery_price_1,
      sortable: true,
    },
    {
      name: 'Курьерская служба №2',
      selector: (row) => row.delivery_2,
    },
    {
      name: 'Стоимость',
      selector: (row) => row.delivery_price_2,
      sortable: true,
    },
    {
      name: 'Дополнительные расходы',
      cell: (row) => (
        <div>
          {Object.entries(row.additional_charges).map(([key, value]) => (
            <div key={key}>{`${key}: ${value}`}</div>
          ))}
        </div>
      ),
    },
    {
      name: 'Маржа',
      selector: (row) => row.margin_price,
      sortable: true,
    },
    {
      name: 'Итог',
      selector: (row) => row.total,
      sortable: true,
    },
    {
      name: 'Action',
      cell: (row) => {
        return (
          <div className="button_wrp">
            <button onClick={() => setSecondModal(row)} className="btn btn-outline-primary">
              <i class="fa fa-pencil-square-o fa-xs" aria-hidden="true"></i>
            </button>
            <button onClick={() => deleteDelivery(row)} className="btn btn-outline-danger">
              <i class="fa fa-trash fa-xs" aria-hidden="true"></i>
            </button>
          </div>
        );
      },
    },
  ];

  return (
    <div className="content">
      <div className="main-content">
        <DeliveryAndTaxAdd
          stores={stores}
          open={open}
          setOpen={setOpen}
          regions={regions}
          refetchDeliveryData={refetchDeliveryData}
          delivery_names={delivery_names}
        />
        <UpdateDeliveryModal
          stores={stores}
          editModal={editModal}
          setEditModal={setEditModal}
          regions={regions}
          delivery={selectedDelivery}
          refetchDeliveryData={refetchDeliveryData}
          delivery_names={delivery_names}
        />

        <div className="mt-8">
          <div className="flex flex-row justify-between items-center">
            <h2 className="text-2xl font-bold mb-4">Delivery Calculations</h2>
            <button
              type="button"
              onClick={() => setOpen(true)}
              className="ti-btn ti-btn-outline-primary h-8"
              style={{ float: 'right' }}
            >
              Add
            </button>
          </div>
          <DataTable
            customStyles={tableStyles}
            columns={columns}
            data={deliveryData ?? []}
            pagination
            responsive
            highlightOnHover
            striped
          />
        </div>
      </div>
    </div>
  );
}
