import { useQuery } from '@tanstack/react-query';
import DataTable from 'react-data-table-component';
import {
  deleteMainPageSubcategory,
  getCategoriesByDepartment,
  getDepartments,
  getMainPageSubcategory,
  getSubcategoriesByCategory,
  insertMainPageSubcategory,
  updateMainPageSubcategory,
} from '../../Action/user.action';
import Modal from 'react-responsive-modal';
import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { tableStyles } from '../../config/tableStyles';

export const RandomMode = ({ id }) => {
  const [randomModeIdForUpdate, setRandomDataIdForUpdate] = useState(null);
  const [randomModeAddModal, setRandomModeAddmodal] = useState(false);
  const [randomModeUpdateModal, setRandomModeUpdateModal] = useState(false);

  const { register, watch, setValue } = useForm({
    defaultValues: {
      type: 0,
      department: '',
      category: '',
      subCategory: '',
      count: '',
      search_product: '',
      product: '',
    },
  });

  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const { data: randomModeData, refetch: refetchRandomModeData } = useQuery({
    queryKey: [`randomModeData-${id}`, id],
    queryFn: () => getMainPageSubcategory({ department_id: id }).then((res) => res.data ?? []),
  });

  const chosenCat = watch('category');
  const chosenSubCat = watch('subCategory');
  const chosenCount = watch('count');

  const { data: categories } = useQuery({
    queryKey: [`categories-${id}`, id],
    queryFn: () => getCategoriesByDepartment({ department_id: id }).then((res) => res.data ?? []),
    enabled: !!id,
  });
  const { data: subCategories } = useQuery({
    queryKey: [`subCategories-${id}`, chosenCat],
    queryFn: () => getSubcategoriesByCategory({ category_id: chosenCat }).then((res) => res.data ?? []),
    enabled: !!chosenCat,
  });

  const deleteData = async (id) => {
    await deleteMainPageSubcategory({ id })
      .then((res) => refetchRandomModeData())
      .catch((err) => console.log(err));
  };

  const addRandomModeData = async () => {
    setRandomModeAddmodal(false);
    await insertMainPageSubcategory({
      subcategory_id: chosenSubCat,
      count: Number(chosenCount),
      department_id: id,
    })
      .then((res) => refetchRandomModeData())
      .catch((err) => console.log(err));
  };

  const updateRandomModeData = (id) => {
    setRandomDataIdForUpdate(id);
    setRandomModeUpdateModal(true);
  };

  const updateRandomModeData2 = async () => {
    setRandomModeUpdateModal(false);
    await updateMainPageSubcategory({
      subcategory_id: chosenSubCat,
      count: Number(chosenCount),
      id: randomModeIdForUpdate,
    })
      .then((res) => refetchRandomModeData())
      .catch((err) => console.log(err));
  };

  const columns = [
    {
      name: 'ID',
      selector: (row) => `${row.id}`,
      sortable: true,
    },
    {
      name: 'Subcategory',
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: 'Quantity',
      selector: (row) => row.count,
      sortable: true,
    },
    {
      name: 'Action',
      cell: (row) => {
        return (
          <div className="button_wrp">
            <button onClick={() => updateRandomModeData(row.id)} className="btn btn-outline-primary">
              <i class="fa fa-pencil-square-o fa-xs" aria-hidden="true"></i>
            </button>
            <button onClick={() => deleteData(row.id)} className="btn btn-outline-danger">
              <i class="fa fa-trash fa-xs" aria-hidden="true" />
            </button>
          </div>
        );
      },
    },
  ];
  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          padding: '1rem',
          gap: '20px',
          width: isMobile ? '100%' : 'calc(33% - 20px)',
          border: '1px solid #ccc',
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <label>Choose a department</label>
          {id === 1 && <p>For Men</p>}
          {id === 2 && <p>For Women</p>}
          {id === 3 && <p>For Kids</p>}
        </div>
        <DataTable
          columns={columns}
          data={randomModeData ?? []}
          customStyles={tableStyles}
          noDataComponent={
            randomModeData && randomModeData.length === 0
              ? 'No data in the department'
              : 'Choose a department to display data'
          }
        />
        {id && (
          <button
            type="button"
            onClick={() => setRandomModeAddmodal(true)}
            className="ti-btn ti-btn-primary"
            style={{ float: 'right' }}
          >
            Add
          </button>
        )}
      </div>
      <Modal open={randomModeAddModal} onClose={() => setRandomModeAddmodal(false)} center>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '20px', width: '400px' }}>
          <label>Add</label>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <label>Select category</label>
            <select {...register('category')}>
              {categories &&
                categories.length > 0 &&
                categories.map((category) => (
                  <option key={category.id} value={category.id}>
                    {category.name}
                  </option>
                ))}
            </select>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <label>Select Subcategory</label>
            <select {...register('subCategory')}>
              {subCategories &&
                subCategories.length > 0 &&
                subCategories.map((subCategory) => (
                  <option key={subCategory.id} value={subCategory.id}>
                    {subCategory.name}
                  </option>
                ))}
            </select>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <label>Count</label>
            <input type="number" {...register('count')} placeholder="Количество товаров" />
          </div>
          <button
            onClick={() => addRandomModeData()}
            className="btn btn-primary"
            disabled={!chosenCat || !chosenSubCat || isNaN(Number(chosenCount))}
          >
            Add
          </button>
        </div>
      </Modal>
      <Modal open={randomModeUpdateModal} onClose={() => setRandomModeUpdateModal(false)} center>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '20px', width: '400px' }}>
          <label>Update</label>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <label>Select category</label>
            <select {...register('category')}>
              {categories &&
                categories.length > 0 &&
                categories.map((category) => (
                  <option key={category.id} value={category.id}>
                    {category.name}
                  </option>
                ))}
            </select>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <label>Select Subcategory</label>
            <select {...register('subCategory')}>
              {subCategories &&
                subCategories.length > 0 &&
                subCategories.map((subCategory) => (
                  <option key={subCategory.id} value={subCategory.id}>
                    {subCategory.name}
                  </option>
                ))}
            </select>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <label>Count</label>
            <input type="number" {...register('count')} placeholder="Количество товаров" />
          </div>
          <button
            onClick={() => updateRandomModeData2()}
            className="btn btn-primary"
            disabled={!chosenCat || !chosenSubCat || isNaN(Number(chosenCount))}
          >
            Update
          </button>
        </div>
      </Modal>
    </>
  );
};
