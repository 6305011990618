import React, { useEffect, useState } from 'react';
import 'react-responsive-modal/styles.css';
import 'react-toastify/dist/ReactToastify.css';
import { Modal } from 'react-responsive-modal';
import { ToastContainer, toast } from 'react-toastify';
import DataTable from 'react-data-table-component';
import config from '../../config/config';
import Swal from 'sweetalert2';
import { fetchAndSetTranslations } from '../../i18n';
import { useTranslation } from 'react-i18next';
import {
  getSubCategoryAction,
  addSubCategoryAction,
  UpdateSubCategoryAction,
  getCategoryAction,
  subCategoryStatusUpdateAction,
  deleteSubCategoryAction,
} from '../../Action/user.action';
import { tableStyles } from '../../config/tableStyles';
import { Loader } from '../UI/Loader';
import { DELETE_MODAL_CONFIG } from '../../const';
import { useCreateCategoryMutation, useGetCategoriesQuery } from '../../store/api/categoriesApi';
import { useGetActiveDepartmentsQuery } from '../../store/api/departmentsApi';

const Categories = () => {
  const { t, i18n } = useTranslation();
  const [open, setOpen] = useState(false);
  const [modalSecond, setModalSecond] = useState(false);
  const [formData, setFormData] = useState({});
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedSubcategory, setSelectedSubcategory] = useState();
  const [subcategories, setSubcategories] = useState([]);
  const [validationErrors, setValidationErrors] = useState({});
  const [image1, setImage1] = useState('');
  const [searchText, setSearchText] = useState('');
  const [imagePreview, setImagePreview] = useState(null);
  const [imagePreviewForAdd, setImagePreviewForAdd] = useState(null);
  const [oldImagePreview, setOldImagePreviw] = useState(null);

  const { data: departments } = useGetActiveDepartmentsQuery();

  const { data: categories, isLoading: isCategoriesLoading, refetch } = useGetCategoriesQuery();

  const [createCategory] = useCreateCategoryMutation();

  useEffect(() => {
    fetchTranslations();
  }, []);

  const fetchTranslations = async () => {
    try {
      const translations = await fetchAndSetTranslations('ru');
    } catch (error) {
      console.error('An error occurred while fetching translations:', error);
    }
  };

  const handleCategoryChange = (e) => {
    const selectedCategoryId = e.target.value;
    setFormData({ ...formData, departmentId: selectedCategoryId });
    setSelectedCategory(selectedCategoryId);
  };

  const clearValidationError = (fieldName) => {
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      [fieldName]: undefined,
    }));
  };

  const handleSubcategoryNameChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

  const handleImage = (e) => {
    setImage1(e.target.files);
    const previewURL = URL.createObjectURL(e.target.files[0]);
    setImagePreviewForAdd(previewURL);
  };

  const handleImageChange = (e) => {
    setImage1(e.target.files);
    const previewURL = URL.createObjectURL(e.target.files[0]);
    setImagePreview(previewURL);
    setOldImagePreviw('');
  };

  // add Sub category-----
  const addSubCategory = async (e) => {
    e.preventDefault();
    const errors = {};

    console.log('formData0000', formData);

    if (formData.departmentId === '') {
      errors.categoryId = 'Department is required';
    }
    if (formData.name === '') {
      errors.subCategoryName = 'Category is required';
    }
    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
    } else {
      setValidationErrors({});
      let formDataReal = new FormData();
      formDataReal.append('backgroundImage', image1[0]);
      formDataReal.append('name', formData.name);
      formDataReal.append('nameRu', formData.nameRu);
      formDataReal.append('departmentId', formData.departmentId);
      try {
        const response = await createCategory(formDataReal);
        if (response.data.success) {
          toast.success('Success', {
            position: toast.POSITION.TOP_CENTER,
          });
          onCloseModal();
          setTimeout(() => {
            fetchTranslations();
          }, 0);
        } else {
          toast.error(response.msg, {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      } catch (error) {
        console.error('An error occurred while adding the category:', error);
      }
    }
  };

  const onOpenModalSecond = (subcategory) => {
    setSelectedCategory(subcategory.departmentId);
    setSelectedSubcategory(subcategory);
    setOldImagePreviw(subcategory.backgroundImage);
    setFormData({
      departmentId: subcategory.departmentId,
      name: subcategory.name,
      nameRu: subcategory.nameRu,
    });
    setModalSecond(true);
  };

  const StatusUpdate = async (data) => {
    let res = await subCategoryStatusUpdateAction({
      id: data.id,
      status: data.isActive,
    });
    if (res.success) {
      refetch();
    } else {
      Swal.fire('Failed!', res.msg, 'error');
    }
  };

  const updateDeleteStatus = async (data) => {
    Swal.fire(DELETE_MODAL_CONFIG).then(async (result) => {
      if (result.isConfirmed) {
        let res = await deleteSubCategoryAction({
          id: data.id,
          status: data.status,
        });
        if (res.success) {
        } else {
          Swal.fire('Failed!', res.msg, 'error');
        }
      }
    });
  };

  // Edit Sub category -------
  const updateCategory = async (e) => {
    e.preventDefault();
    const errors = {};
    if (formData.departmentId === '') {
      errors.departmentId = 'Department name is required';
    }
    if (formData.categoryId === '') {
      errors.categoryId = 'Category name is required';
    }
    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
    } else {
      // Clear validation errors
      setValidationErrors({});
      try {
        console.log('sss', selectedSubcategory);
        let data = {
          id: selectedSubcategory.id,
          departmentId: formData.departmentId,
          name: formData.name,
          nameRu: formData.nameRu,
        };
        console.log('image1[0]', image1[0]);
        if (image1[0]) {
          data.backgroundImage = image1[0] ? image1[0] : selectedSubcategory.backgroundImage;
        } else {
          data.backgroundImage = selectedSubcategory.backgroundImage;
        }
        const response = await UpdateSubCategoryAction(data);
        if (response.success) {
          toast.success('Success', {
            position: toast.POSITION.TOP_CENTER,
          });
          setImage1([null]);
          onCloseModalSecond();
          setTimeout(() => {
            fetchTranslations();
          }, 0);
        } else {
          console.error('Failed to update category');
        }
      } catch (error) {
        console.error('An error occurred while updating the category:', error);
      }
    }
  };

  const onOpenModal = () => setOpen(true);
  const onCloseModalSecond = () => {
    setSelectedSubcategory(null);
    setFormData({ categoryId: '', subCategoryName: '', backgroundImage: '' });
    setImagePreview('');

    setModalSecond(false);
  };

  const onCloseModal = () => {
    setFormData({
      categoryId: '',
      subCategoryName: '',
      subCategoryName_ru: '',
    });
    setImagePreviewForAdd('');
    setOpen(false);
  };

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  const filteredData = React.useMemo(
    () =>
      categories?.data.filter((item) => {
        item = Object.values(item).some((value) => String(value).toLowerCase().includes(searchText.toLowerCase()));
        return item;
      }),
    [categories, searchText]
  );

  const columns = [
    {
      name: 'Department',
      selector: (row) => row.departmentName,
      sortable: true,
    },
    {
      name: 'Category',
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: 'Background Image',
      text: 'backgroundImage',
      cell: (item) => {
        return (
          <>
            {item.backgroundImage && (
              <a
                href={process.env.REACT_APP_MEDIA_URL + item.backgroundImage}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  style={{
                    width: '160px',
                    height: '80px',
                    borderColor: 'black',
                  }}
                  src={process.env.REACT_APP_MEDIA_URL + item.backgroundImage}
                  alt="image"
                  className="py-2"
                />
              </a>
            )}
          </>
        );
      },
    },
    {
      name: 'Action',
      cell: (row) => {
        return (
          <div className="button_wrp">
            <button onClick={() => onOpenModalSecond(row)} className="btn btn-outline-primary">
              <i class="fa fa-pencil-square-o fa-xs" aria-hidden="true"></i>
            </button>
            {row.isActive ? (
              <button onClick={() => StatusUpdate(row)} className="btn btn-outline-success">
                <i class="fa fa-check  fa-xs" aria-hidden="true"></i>
              </button>
            ) : (
              <button onClick={() => StatusUpdate(row)} className="btn btn-outline-danger redclosebtn">
                <i class="fa fa-times fa-s " aria-hidden="true"></i>
              </button>
            )}
            <button onClick={() => updateDeleteStatus(row)} className="btn btn-outline-danger">
              <i class="fa fa-trash fa-xs" aria-hidden="true"></i>
            </button>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <div className="content">
        <div className="main-content">
          <div className="block justify-between page-header md:flex"></div>
          <div className="grid grid-cols-12 gap-6">
            <div className="col-span-12">
              <div className="box">
                <div className="box-header">
                  <h5 className="box-title font-normal">Categories List</h5>
                  <button
                    type="button"
                    onClick={onOpenModal}
                    className="ti-btn ti-btn-outline-primary"
                    style={{ float: 'right', marginTop: '-35px' }}
                  >
                    Add
                  </button>
                </div>
                <div className="box-body">
                  {/* Search input */}
                  <input
                    type="text"
                    value={searchText}
                    onChange={handleSearch}
                    placeholder="Search..."
                    className="px-2 py-1 border rounded mb-4"
                  />
                  {!isCategoriesLoading ? (
                    <div className="overflow-hidden table-bordered">
                      <DataTable
                        columns={columns}
                        data={filteredData}
                        pagination
                        paginationTotalRows={filteredData.length}
                        customStyles={tableStyles}
                      />
                    </div>
                  ) : (
                    <Loader />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        open={open}
        onClose={onCloseModal}
        center
        closeOnOverlayClick={false}
        closeOnEsc={false}
        classNames={{ modal: 'modal__radius' }}
      >
        <label className="mb-2 font-normal" htmlFor="categoryId">
          Add Category
        </label>
        <hr />
        <div className="form-group mb-2 font-light">
          <label className="mb-2 font-light" htmlFor="departmentId">
            Department
          </label>
          <br />
          <select
            name="departmentId"
            value={formData.departmentId}
            // onChange={handleCategoryChange}
            onChange={(e) => {
              handleCategoryChange(e);
              clearValidationError('departmentId');
            }}
            className="select font-light"
            id="departmentId"
          >
            <option value="">Select a department</option>
            {departments &&
              departments.map((department) => (
                <option key={department.id} value={department.id}>
                  {department.name}
                </option>
              ))}
          </select>
          {validationErrors.departmentId && <span className="text-danger">{validationErrors.departmentId}</span>}
        </div>
        <div className=" mb-2 font-light ">
          <label className="mb-2" htmlFor="name">
            Category Name
          </label>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={(e) => {
              handleSubcategoryNameChange(e);
              clearValidationError('name');
            }}
            className="select"
            id="name"
            placeholder="Category name (EN)"
          />
          {validationErrors.name && <span className="text-danger">{validationErrors.name}</span>}
        </div>
        <div className="  mb-2 font-light ">
          <input
            type="text"
            name="nameRu"
            value={formData.nameRu}
            onChange={(e) => {
              handleSubcategoryNameChange(e);
              clearValidationError('nameRu');
            }}
            className="select"
            id="nameRu"
            placeholder="Category name (RU)"
          />
          {validationErrors.nameRu && <span className="text-danger">{validationErrors.nameRu}</span>}
        </div>
        <div className=" mb-2 font-light">
          <label className="mb-2" htmlFor="backgroundImage">
            Background Image
          </label>
          <input
            accept="image/x-png,image/gif,image/jpeg"
            type="file"
            name="backgroundImage"
            // value={formData.backgroundImage}
            onChange={(e) => {
              handleImage(e);
              clearValidationError('backgroundImage');
            }}
            className="file font-light"
          />
          {validationErrors.backgroundImage && <span className="text-danger">{validationErrors.backgroundImage}</span>}
          {imagePreviewForAdd && (
            <img
              src={imagePreviewForAdd}
              alt="Selected Image Preview"
              style={{
                marginTop: '10px',
                maxWidth: '100%',
                maxHeight: '150px',
              }}
            />
          )}
        </div>
        <br />
        <button type="submit" onClick={addSubCategory} className="btn btn-outline-primary ">
          Add
        </button>
      </Modal>
      <Modal open={modalSecond} onClose={onCloseModalSecond} center closeOnOverlayClick={false} closeOnEsc={false}>
        <label className="mb-2 font-normal" htmlFor="departmentId">
          Edit Category
        </label>
        <hr />
        <div className="form-group mb-3">
          <label className="mb-2 font-normal" htmlFor="departmentId">
            Department
          </label>
          <br />
          <select
            name="departmentId"
            value={formData.departmentId}
            onChange={(e) => {
              handleCategoryChange(e);
              clearValidationError('departmentId');
            }}
            className="form-control select"
            id="departmentId"
          >
            <option value="">Select a department</option>
            {departments &&
              departments.map((category) => (
                <option key={category.id} value={category.id} className="h5">
                  {category.name}
                </option>
              ))}
          </select>
          {validationErrors.categoryId && <span className="text-danger">{validationErrors.categoryId}</span>}
        </div>

        <div className="form-group mb-3">
          <label className="mb-2 font-normal" htmlFor="name">
            Category
          </label>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={(e) => {
              handleSubcategoryNameChange(e);
              clearValidationError('subCategoryName');
            }}
            className="form-control select"
            id="name"
            placeholder="Sub category"
          />
          {validationErrors.subCategoryName && <span className="text-danger">{validationErrors.subCategoryName}</span>}
        </div>

        <div className="form-group mb-3">
          <input
            type="text"
            name="nameRu"
            value={formData.nameRu}
            onChange={(e) => {
              handleSubcategoryNameChange(e);
              clearValidationError('nameRu');
            }}
            className="form-control select"
            id="nameRu"
            placeholder="Category name (RU)"
          />
          {validationErrors.nameRu && <span className="text-danger">{validationErrors.nameRu}</span>}
        </div>

        <div className="form-group mb-3">
          <label className="mb-2 font-normal" htmlFor="backgroundImage">
            Background Image
          </label>
          <input
            accept="image/x-png,image/gif,image/jpeg"
            type="file"
            name="backgroundImage"
            onChange={(e) => {
              handleImageChange(e);
              clearValidationError('backgroundImage');
            }}
            className="file form-light"
          />
          {validationErrors.backgroundImage && <span className="text-danger">{validationErrors.backgroundImage}</span>}
          {oldImagePreview && (
            <img
              src={`${process.env.REACT_APP_MEDIA_URL + oldImagePreview}`}
              alt="Image Preview"
              style={{
                marginTop: '10px',
                maxWidth: '100%',
                maxHeight: '150px',
              }}
            />
          )}
          {imagePreview && (
            <img
              src={imagePreview}
              alt="Selected Image Preview"
              style={{
                marginTop: '10px',
                maxWidth: '100%',
                maxHeight: '150px',
              }}
            />
          )}
        </div>

        <br />
        <button type="submit" onClick={updateCategory} className="btn btn-outline-primary ">
          Update
        </button>
      </Modal>
      <ToastContainer />
    </>
  );
};

export default Categories;
