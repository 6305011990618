import { apiSlice } from "./apiSlice";

export const userApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getUsers: builder.query({
      query: (params) => ({
        params: params,
        url: "getUserList",
      }),
      providesTags: ["Users"],
    }),
    updateUserStatus: builder.mutation({
      query: (body) => ({
        body: body,
        url: `userStatusUpdate/${body.id}`,
        method: "PATCH",
      }),
      invalidatesTags: ["Users"],
    }),
    deleteUser: builder.mutation({
      query: (payload) => ({
        url: `deleteUser/${payload.id}`,
        body: { isDeleted: payload.isDeleted },
        method: "PATCH",
      }),
      invalidatesTags: ["Users"],
    }),
  }),
});

export const {
  useGetUsersQuery,
  useUpdateUserStatusMutation,
  useDeleteUserMutation,
} = userApi;
