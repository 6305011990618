import { useQuery } from '@tanstack/react-query';
import DataTable from 'react-data-table-component';
import { addMainProduct, getMainProducts, removeMainProduct, searchProducts } from '../../Action/user.action';
import Modal from 'react-responsive-modal';
import { useState, useEffect, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { tableStyles } from '../../config/tableStyles';

export const ManualMode = ({ id }) => {
  const columnsManual = [
    {
      name: 'ID',
      selector: (row, index) => `${index + 1}`,
      sortable: true,
    },
    {
      name: 'Subcategory',
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: 'Search ID',
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: 'Quantity',
      selector: (row) => 1,
      sortable: true,
    },
    {
      name: 'Action',
      cell: (row) => {
        return (
          <div className="button_wrp">
            <button onClick={() => deleteManualData(row.id)} className="btn btn-outline-danger">
              <i class="fa fa-trash fa-xs" aria-hidden="true" />
            </button>
          </div>
        );
      },
    },
  ];

  const [manualModeAddModal, setManualModeAddModal] = useState(false);
  const [debouncedValue, setDebouncedValue] = useState('');

  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const { register, watch, setValue } = useForm({
    defaultValues: {
      type: 1,
      department: '',
      category: '',
      subCategory: '',
      count: '',
      search_product: '',
      product: '',
    },
  });

  const {
    data: manualModeData,
    refetch: refetchManualModeData,
    isFetching,
    isLoading,
  } = useQuery({
    queryKey: ['manualModeData', id],
    queryFn: () => getMainProducts({ department_id: id }).then((res) => res.data ?? []),
    enabled: !!id,
  });

  const chosenMode = watch('type');
  const searchProduct = watch('search_product');
  const chosenProduct = watch('product');

  const [selectedProduct, setSelectedProduct] = useState(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleOptionClick = (product) => {
    setSelectedProduct(product);
    setValue('product', product.id);
    setIsDropdownOpen(false);
  };

  const debounceHandler = useCallback(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(searchProduct);
    }, 300);

    return () => {
      clearTimeout(handler);
    };
  }, [searchProduct]);

  useEffect(() => {
    const clearDebounce = debounceHandler();
    return clearDebounce;
  }, [debounceHandler]);

  const { data: searchedProducts } = useQuery({
    queryKey: ['searchProducts', debouncedValue],
    queryFn: () =>
      searchProducts({ department_id: id, keyword: debouncedValue })
        .then((res) => res.data)
        .catch((err) => console.log(err)),
    enabled: !!debouncedValue,
  });

  const deleteManualData = async (id) => {
    await removeMainProduct({ product_id: id })
      .then((res) => refetchManualModeData())
      .catch((err) => console.log(err));
  };

  const addManualModeData = async () => {
    setSelectedProduct(null);
    setManualModeAddModal(false);
    setValue('search_product', '');
    setValue('product', '');

    await addMainProduct({ product_id: chosenProduct })
      .then((res) => refetchManualModeData())
      .catch((err) => console.log(err));
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: isMobile ? '100%' : 'calc(33% - 20px)',
          padding: '1rem',
          gap: '20px',
          border: '1px solid #ccc',
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <label>Choose a department</label>

          {id === 1 && <p>For Men</p>}
          {id === 2 && <p>For Women</p>}
          {id === 3 && <p>For Kids</p>}
        </div>
        <DataTable
          columns={columnsManual}
          data={manualModeData ?? []}
          customStyles={tableStyles}
          noDataComponent={
            isFetching || isLoading
              ? 'Loading...'
              : manualModeData && manualModeData.length === 0
                ? 'No data in the department'
                : 'Choose a department to display data'
          }
        />
        {id && (
          <button
            type="button"
            onClick={() => setManualModeAddModal(true)}
            className="ti-btn ti-btn-primary"
            style={{ float: 'right' }}
          >
            Add
          </button>
        )}
      </div>
      <Modal open={manualModeAddModal} onClose={() => setManualModeAddModal(false)} center>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '20px', width: '400px' }}>
          <label>Add</label>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <label>Search Product</label>
            <input style={{ border: '1px solid #ccc', padding: '5px' }} {...register('search_product')} />
          </div>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div className="custom-dropdown">
              <div className="selected-option" onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
                {selectedProduct ? (
                  <div style={{ display: 'flex', flexDirection: 'row', gap: '10px', alignItems: 'center' }}>
                    <img
                      width={100}
                      src={`${process.env.REACT_APP_MEDIA_URL}${selectedProduct.cover_image}`}
                      alt={selectedProduct.name}
                    />
                    <span>{selectedProduct.name}</span>
                  </div>
                ) : (
                  <p style={{ cursor: 'pointer' }}>Select a product (Нажать для отображения)</p>
                )}
              </div>
              {isDropdownOpen && (
                <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }} className="options">
                  {searchedProducts &&
                    searchedProducts.length > 0 &&
                    searchedProducts.map((product) => (
                      <div
                        key={product.id}
                        className="option"
                        onClick={() => handleOptionClick(product)}
                        style={{ display: 'flex', flexDirection: 'row', gap: '10px', alignItems: 'center' }}
                      >
                        <img
                          width={100}
                          src={`${process.env.REACT_APP_MEDIA_URL}${product.cover_image}`}
                          alt={product.name}
                        />
                        <span>{product.name}</span>
                      </div>
                    ))}
                </div>
              )}
            </div>
          </div>
          <button onClick={() => addManualModeData()} className="btn btn-primary" disabled={!chosenProduct}>
            Add
          </button>
        </div>
      </Modal>
    </>
  );
};
