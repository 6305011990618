import Dashboard from './components/Dashboard/Dashboard';
import Department from './components/categories/Department';
import Categories from './components/categories/Categories';
import SubCategories from './components/categories/Subcategories';
import ProductTypes from './components/categories/ProductTypes';
import ProductList from './components/product/ProductList';
import AddProduct from './components/product/AddProduct';
import EditProduct from './components/product/EditProduct';
import TermsOfPromotion from './CMS/termsOfPromotion';
import Accessibility from './CMS/Accessibility';
import Users from './components/users/users';
import Size from './components/size/Size';
import Brand from './components/brand/Brand';
import Region from './components/region/Region';
import CombinationList from './components/ItemCombination/CombinationList';
import AddCombination from './components/ItemCombination/AddCombination';
import Changepassword from './components/AdminProfile/changepassword';
import EditCombination from './components/ItemCombination/EditCombination';
import Promocode from './components/Promocode/Promocode';
import AddPromocode from './components/Promocode/AddPromocode';
import EditPromocode from './components/Promocode/EditPromocode';
import Order from './components/Order/Order';
import DeliveryAndTax from './components/DeliveryAndTax/DeliveryAndTax';
import Supplier from './components/Supplier/Supplier';
import CMS from './components/CMS/CMS';
import CMSContentList from './components/CMS/CMSContentList';
import ContactUs from './components/CMS/ContactUs';
import Feedback from 'react-bootstrap/esm/Feedback';
import AddCMSContent from './components/CMS/AddCMSContent';
import EditCMSContent from './components/CMS/EditCMSContent';
import MarginPercent from './components/marginPercent/MarginPercent';
import { Translation } from 'react-i18next';
import Color from './components/color/Color';
import { createBrowserRouter } from 'react-router-dom';
import { Layout } from './components/Layout/Layout';
import Login from './components/signin';
import AdditionalFilters from './components/AdditionalFilters/AdditionalFilters';
import FilterDetail from './components/AdditionalFilters/FilterDetail';
import ImportDuty from './components/importDuty/importDuty';
import { MainProductType } from './components/MainProductType/MainProductType';
import { DepartmentBanner } from './components/DepartmentBanner/DepartmentBanner';
import DeliveryAndTaxUpdate from './components/DeliveryAndTax/DeliveryAndTaxUpdate';
import { Recommendations } from './components/recommendations/Recommendations';
import { Subdepartment } from './components/categories/Subdepartments/Subdepartment';
import { InnerCategories } from './components/categories/InnerCategories/InnerCategories';
import { Delete } from './components/Delete/Delete';

export const router = createBrowserRouter([
  {
    path: process.env.REACT_APP_BASE_URL,
    element: <Layout />,
    children: [
      { path: 'dashboard', element: <Dashboard /> },
      { path: 'department', element: <Department /> },
      { path: 'categories', element: <Categories /> },
      {
        path: 'subcategories',
        element: <SubCategories />,
      },
      {
        path: 'productType',
        element: <ProductTypes />,
      },
      {
        path: 'productList',
        element: <ProductList />,
      },
      {
        path: 'additionalFilters',
        element: <AdditionalFilters />,
      },
      {
        path: 'additionalFilters/:id',
        element: <FilterDetail />,
      },
      {
        path: 'addProduct',
        element: <AddProduct />,
      },
      {
        path: 'editProduct/:id',
        element: <EditProduct />,
      },

      {
        path: 'TermsOfPromotion',
        element: <TermsOfPromotion />,
      },
      {
        path: 'Accessibility',
        element: <Accessibility />,
      },
      {
        path: 'recommendations',
        element: <Recommendations />,
      },
      {
        path: 'Users',
        element: <Users />,
      },
      {
        path: 'size',
        element: <Size />,
      },
      {
        path: 'inner-categories',
        element: <InnerCategories />,
      },
      {
        path: 'delete',
        element: <Delete />,
      },
      {
        path: 'brand',
        element: <Brand />,
      },
      {
        path: 'regions',
        element: <Region />,
      },
      {
        path: 'subdepartment',
        element: <Subdepartment />,
      },
      {
        path: 'importduty',
        element: <ImportDuty />,
      },
      {
        path: 'combinationlist',
        element: <CombinationList />,
      },
      {
        path: 'department-banner',
        element: <DepartmentBanner />,
      },
      {
        path: 'addcombination',
        element: <AddCombination />,
      },
      {
        path: 'editcombination/:id',
        element: <EditCombination />,
      },

      {
        path: 'changepassword',
        element: <Changepassword />,
      },
      {
        path: 'main-product-type',
        element: <MainProductType />,
      },
      {
        path: 'promocode',
        element: <Promocode />,
      },

      {
        path: 'addpromocode',
        element: <AddPromocode />,
      },
      {
        path: 'editpromocode/:id',
        element: <EditPromocode />,
      },
      {
        path: 'regionDelivery',
        element: <EditPromocode />,
      },
      {
        path: 'order',
        element: <Order />,
      },
      {
        path: 'deliveryandtax',
        element: <DeliveryAndTaxUpdate />,
      },
      {
        path: 'supplier',
        element: <Supplier />,
      },
      {
        path: 'cms',
        element: <CMS />,
      },
      {
        path: 'cmscontentlist/:id',
        element: <CMSContentList />,
      },
      {
        path: 'contactus',
        element: <ContactUs />,
      },
      {
        path: 'feedback',
        element: <Feedback />,
      },
      {
        path: 'addcmscontent/:id',
        element: <AddCMSContent />,
      },
      {
        path: 'editcmscontent/:id',
        element: <EditCMSContent />,
      },
      {
        path: 'marginpercent',
        element: <MarginPercent />,
      },
      {
        path: 'translation',
        element: <Translation />,
      },
      {
        path: 'color',
        element: <Color />,
      },
    ],
  },
  { path: `${process.env.REACT_APP_BASE_URL}login`, element: <Login /> },
]);
