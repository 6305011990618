module.exports = {
  //  baseurl: "/4you/cp-admin/",
  // serverPath: "https://espsofttech.org:7979/api/admin",
  //  imageUrl : "https://espsofttech.org:7979/api/admin/uploads/",
  //  flgImageUrl : "https://espsofttech.org:7979/api/admin/uploads/flag/",
  //   serverPath:"http://localhost:8000/api/admin",

  //client server
  baseurl: "",
  serverPath: "https://foryou.org.kz/api/admin",
  imageUrl: "https://foryou.org.kz/media/",
  flgImageUrl: "https://foryou.org.kz/media/",
  //serverPath:"http://localhost:8000/api/admin",
  langUrl: "https://foryou.org.kz/api/user/language/ru.json",
};

//https://espsofttech.org/4you/cp-admin/
//https://4you.com.kz/for_ap/
