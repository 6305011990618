import {
  getDepartments,
  getCategoriesByDepartment,
  getSelectedCategory,
  setSelectedCategory,
} from '../../Action/user.action';
import { useForm } from 'react-hook-form';
import { useQuery } from '@tanstack/react-query';
import { useState, useEffect } from 'react';

export const CategoryMode = ({ id }) => {
  const { register, watch } = useForm({
    defaultValues: {
      type: 1,
      department: '',
      category: '',
      subCategory: '',
      count: '',
      search_product: '',
      product: '',
    },
  });

  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const chosenCat = watch('category');
  const chosenCount = watch('count');

  const { data: categories } = useQuery({
    queryKey: ['categories', id],
    queryFn: () => getCategoriesByDepartment({ department_id: id }).then((res) => res.data ?? []),
    enabled: !!id,
  });
  const { data: thirdModeData, refetch: refetchThirdModeData } = useQuery({
    queryKey: ['thirdModeData', id],
    queryFn: () => getSelectedCategory({ department_id: id }).then((res) => res.data ?? []),
    enabled: !!id,
  });

  const addThirdMode = async () => {
    await setSelectedCategory({ department_id: id, category_id: chosenCat, count: chosenCount })
      .then((res) => refetchThirdModeData())
      .catch((err) => console.log(err));
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: isMobile ? '100%' : 'calc(33% - 20px)',
        padding: '1rem',
        gap: '20px',
        border: '1px solid #ccc',
      }}
    >
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <label>Choose a department</label>
        {id === 1 && <p>For Men</p>}
        {id === 2 && <p>For Women</p>}
        {id === 3 && <p>For Kids</p>}
      </div>

      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <label>Select category</label>
        <select {...register('category')}>
          {categories &&
            categories.length > 0 &&
            categories.map((category) => (
              <option key={category.id} value={category.id}>
                {category.name}
              </option>
            ))}
        </select>
      </div>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <label>Count</label>
        <input type="number" {...register('count')} placeholder="Количество товаров" />
      </div>
      <button
        onClick={() => addThirdMode()}
        className="btn btn-primary"
        disabled={!chosenCat || isNaN(Number(chosenCount))}
      >
        Add
      </button>
    </div>
  );
};
