import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import config from '../../config/config';
import {
  getOrderListAction,
  updateOrderStatusWithCommentAction,
  getOrderDetailsByIdAction,
  getOrderStatusesAction,
  getDeliveriesAction,
} from '../../Action/user.action';
import 'react-responsive-modal/styles.css';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import moment from 'moment';
import 'react-responsive-modal/styles.css';
import 'react-toastify/dist/ReactToastify.css';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Row } from 'react-bootstrap';
import { tableStyles } from '../../config/tableStyles';
import { Loader } from '../UI/Loader';

const Order = () => {
  const [loader, setLoader] = useState(true);
  const [listing, setListing] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState('');
  const [searchText, setSearchText] = useState('');
  const [modalSecond, setModalSecond] = useState(false);
  const [statusUpdateData, setStatusUpdateData] = useState('');
  const [open, setOpen] = useState(false);
  const [statuses, setStatuses] = useState(null);

  const [formData, setFormData] = useState({
    id: '',
    deliveryId: '',
    trackUrl: '',
    expDeliveryDateByAdmin: '',
    reason: '',
  });
  const [validationErrors, setValidationErrors] = useState({});
  const [orderData, setOrderData] = useState('');
  const [listingByOrder, setListingByOrder] = useState([]);

  let currDate = new Date().toISOString().split('T')[0];

  const [deliveries, setDeliveries] = React.useState(null);

  const getDeliveries = async () => {
    try {
      const res = await getDeliveriesAction();
      setDeliveries(res.data);
    } catch (err) {
      console.error(err);
    }
  };

  React.useEffect(() => getDeliveries, []);

  useEffect(() => {
    fetchOrderList();
    getStatuses();
    getDeliveries();
  }, []);

  const getStatuses = async () => {
    const res = await getOrderStatusesAction();
    setStatuses(res);
  };

  const fetchOrderList = async () => {
    try {
      setLoader(true);
      const res = await getOrderListAction();
      if (res.success) {
        setLoader(false);
        setListing(res.data.items);
      }
      setLoader(false);
    } catch (error) {
      console.error('An error occurred while getting the listing:', error);
    }
  };

  const inputHandler = (e) => {
    console.log(e.target.value, e.target.name);
    const { name, value } = e.target;
    setFormData((old) => {
      return { ...old, [name]: value };
    });
  };

  const clearValidationError = (fieldName) => {
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      [fieldName]: undefined,
    }));
  };

  const updateStatusComment = async (e) => {
    e.preventDefault();
    const errors = {};
    console.log('formm', formData);
    if (formData.deliveryId === '' || formData.deliveryId === undefined || formData.deliveryId === null) {
      errors.deliveryId = 'Delivery partner is required';
    }
    if (formData.trackUrl === '' || formData.trackUrl === null || formData.trackUrl === undefined) {
      errors.trackUrl = 'Tracking Url/Id is required';
    }

    let data = {
      orderItemId: orderData.id,
      deliveryId: formData.deliveryId,
      trackUrl: formData.trackUrl,
      status: statusUpdateData,
    };

    console.log('dataaaaaaa', data);

    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
    } else {
      setValidationErrors({});
      try {
        const response = await updateOrderStatusWithCommentAction(data);
        if (response.success) {
          // toast.success("Success", {
          //   position: toast.POSITION.TOP_CENTER,
          // });
          console.log(response, ';ll;l;l;l;', formData.orderId, formData);
          fetchOrderList();
          await orderDetailId(formData.orderId);
          console.log(formData, '///////');
          handleShow(formData, 3, formData.orderId);
          handleClose();
        } else {
          toast.error(response.msg, {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      } catch (error) {
        console.error('An error occurred while adding the category:', error);
      }
    }
  };

  const updateCancelResaon = async (e) => {
    e.preventDefault();
    const errors = {};
    console.log(formData);
    if (formData.reason === '' || formData.reason == undefined) {
      errors.reason = 'Cancel reason is required';
    }
    let data = {
      status: statusUpdateData,
      orderItemId: orderData.id,
      reason: formData.reason,
      deliveryId: null,
      trackUrl: null,
    };
    console.log(data);

    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
    } else {
      setValidationErrors({});
      try {
        const response = await updateOrderStatusWithCommentAction(data);
        if (response.success) {
          toast.success('Success', {
            position: toast.POSITION.TOP_CENTER,
          });
          await orderDetailId(formData.orderId);
          handleClose2();
        } else {
          toast.error(response.msg, {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      } catch (error) {
        console.error('An error occurred while updating cancel reason:', error);
      }
    }
  };

  const orderDetailId = async (item) => {
    console.log(item, '!!!!!');
    try {
      const res = await getOrderDetailsByIdAction({
        orderId: item.id ? item.id : item,
      });
      if (res.success) {
        setListingByOrder(res.data);
        handleShow1();
        console.log(res.data);
      } else {
        console.error('Failed to fetch order details:', res.msg);
      }
    } catch (error) {
      console.error('An error occurred while getting the order details:', error);
    }
  };

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  // const filteredData = listing.filter((item) =>
  //   Object.values(item).some((value) =>
  //     String(value).toLowerCase().includes(searchText.toLowerCase())
  //   )
  // );

  const filteredData = listing?.filter((item, index) => {
    item.index = index;
    item = Object.values(item).some((value) => String(value).toLowerCase().includes(searchText.toLowerCase()));
    return item;
  });

  const columns = [
    {
      name: '№',
      selector: (row) => `${row.index + 1}`,
      sortable: true,
      width: '70px',
    },
    {
      name: 'User Name',
      selector: (row) => row.firstName,
      sortable: true,
      width: '100px',
    },
    {
      name: 'Email',
      selector: (row) => row.email,
      sortable: true,
      width: '100px',
    },
    {
      name: 'Phone',
      selector: (row) => row.phone,
      sortable: true,
      width: '100px',
    },
    {
      name: 'Track Number',
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: 'Order Number',
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: 'Amount',
      selector: (row) => row.count,
      sortable: true,
      width: '110px',
    },
    // {
    //   name: "Delivery Charges",
    //   selector: (row) => row.deliveryAmount,
    //   sortable: true,
    // },
    // {
    //   name: "VAT (%)",
    //   selector: (row) => row.tax_per ,
    //   sortable: true,
    // },
    {
      name: 'Date of order',
      selector: (row) => `${moment(row?.dateTime).format('DD-MM-YYYY')}`,
      sortable: true,
    },
    {
      name: 'Status',
      selector: (row) => row.status,
      sortable: true,
    },
    {
      name: 'Delivery Charges',
      cell: (row) => (
        <div>
          <div>{`${row.delivery} $`}</div>
        </div>
      ),
    },
    {
      name: 'Details',
      cell: (item) => {
        return (
          <>
            <div class="btn-group mb-2">
              <>
                <a
                  class="btn btn-dark btn-sm"
                  onClick={() => orderDetailId(item)}
                  data-toggle="modal"
                  data-target="#myModal"
                  style={{ borderRadius: '10px' }}
                  href="javascript:void(0)"
                >
                  <i className="fa fa-eye"></i>{' '}
                </a>
              </>
            </div>
          </>
        );
      },
    },
  ];

  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
  };

  const handleShow = (item, statusUpdate) => {
    console.log('formData', item);
    setFormData(item);
    setOrderData(item);
    setStatusUpdateData(statusUpdate);
    console.log(statusUpdateData);
    setShow(true);
  };

  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => {
    setShow1(true);
  };

  const [show2, setShow2] = useState(false);
  const handleClose2 = () => {
    setShow2(false);
    setFormData({ reason: '' });
  };
  const handleShow2 = (item, statusUpdate) => {
    setOrderData(item);
    setFormData(item);
    setStatusUpdateData(statusUpdate);
    setShow2(true);
  };

  return (
    <>
      <ToastContainer />
      <div className="content">
        <div className="main-content">
          <div className="block justify-between page-header md:flex"></div>

          <div className="grid grid-cols-12 gap-6">
            <div className="col-span-12">
              <div className="box">
                <div className="box-header">
                  <h5 className="box-title font-normal">Order List</h5>
                </div>
                <div className="box-body">
                  <input
                    type="text"
                    value={searchText}
                    onChange={handleSearch}
                    placeholder="Search..."
                    className="px-2 py-1 border rounded mb-4"
                  />
                  {!loader ? (
                    <div className="overflow-hidden table-bordered">
                      <DataTable
                        columns={columns}
                        data={filteredData}
                        pagination
                        paginationTotalRows={filteredData?.length}
                        customStyles={tableStyles}
                      />
                    </div>
                  ) : (
                    <Loader />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ToastContainer />

      {/* Order update modal */}
      <Modal show={show} onHide={handleClose} size="lg" closeOnOverlayClick={false} closeOnEsc={false}>
        <Modal.Header closeButton>
          <Modal.Title>Update order details</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="form-group">
            <label className="p-1" htmlFor="deliveryId">
              Delivery Partner
            </label>
            <select
              name="deliveryId"
              value={+formData.deliveryId}
              onChange={(e) => {
                inputHandler(e);
                clearValidationError('deliveryId');
              }}
              className="form-control"
              placeholder="Delivery Partner"
            >
              {deliveries?.length && deliveries.map((i) => <option value={i.id}>{i.name}</option>)}
              {console.log(formData, 'FORMDATA')}
            </select>
            {validationErrors.delivery && <span className="text-danger">{validationErrors.deliveryId}</span>}
          </div>

          <div className="form-group">
            <label className="p-1" htmlFor="trackUrl">
              Tracking Url/Id
            </label>
            <input
              type="text"
              name="trackUrl"
              value={formData.trackUrl}
              onChange={(e) => {
                inputHandler(e);
                clearValidationError('trackUrl');
              }}
              className="form-control"
              placeholder="Tracking Url/Id"
            />
            {validationErrors.trackUrl && <span className="text-danger">{validationErrors.trackUrl}</span>}
          </div>

          <div className="form-group">
            <label className="p-1" htmlFor="expDeliveryDate">
              Expected Delivery Date
            </label>
            <input
              type="date"
              name="expDeliveryDateByAdmin" //expDeliveryDateByAdmin
              value={
                formData.expDeliveryDateByAdmin ? moment(formData.expDeliveryDateByAdmin).format('YYYY-MM-DD') : ''
              }
              onChange={(e) => {
                inputHandler(e);
                clearValidationError('expDeliveryDateByAdmin');
              }}
              className="form-control"
              min={currDate}
            />
            {validationErrors.expDeliveryDateByAdmin && (
              <span className="text-danger">{validationErrors.expDeliveryDateByAdmin}</span>
            )}
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button type="submit" onClick={updateStatusComment} variant="primary">
            Update
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Order Detail modal */}
      <Modal show={show1} onHide={handleClose1} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Order Details (#{listingByOrder?.items?.[0]?.orderNumber})</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Row>
            {listingByOrder?.items?.map((item) => {
              return (
                <>
                  <div className="row">
                    <div className="col-md-4">
                      <div className="block_address">
                        <img
                          src={process.env.REACT_APP_MEDIA_URL + item.image}
                          style={{
                            height: '214px',
                            width: '160px',
                            borderRadius: '10px',
                          }}
                          alt="Image"
                          class="img-fluid imagesize"
                        />
                        <div></div>
                      </div>
                    </div>

                    <div className="col-md-5">
                      <b>Name : </b> <span>{item.name}</span>
                      <br />
                      <b>Price : </b>
                      <span>
                        {item.price} {item.currency}
                      </span>
                      <br />
                      {item.size !== null ? (
                        <>
                          <b>Size : </b>
                          <span>{item.size}</span>
                          <br />
                        </>
                      ) : (
                        ''
                      )}
                      <b>Quantity : </b>
                      <span>{item.quantity}</span>
                      <br />
                      {item.reason !== null ? (
                        <>
                          <b>Reason : </b>
                          <span>{item.reason}</span>
                          <br />
                        </>
                      ) : (
                        ''
                      )}
                      <b>Product URL : </b>
                      <a target="_blank" href={`${item.url}`}>
                        URL to product
                      </a>
                      <br />
                      <b>Track Number : </b>
                      <span>{item.orderNumber}</span>
                      <br />
                      <b>Market ID : </b>
                      <span>{item.market_id}</span>
                      <br />
                    </div>
                    {item.status !== 4 && item.status !== 5 && item.status !== 6 ? (
                      <div className="col-md-3">
                        <div class="dropdown">
                          <button
                            class="btn btn-primary dropdown-toggle"
                            type="button"
                            id="dropdownMenuButton1"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            Action
                          </button>
                          <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                            <li>
                              {statuses?.data.map((i) => (
                                <span
                                  class="dropdown-item mb-1"
                                  onClick={
                                    i.name.toLowerCase() !== 'canceled'
                                      ? () => handleShow(item, i.id, item.orderId)
                                      : () => handleShow2(item, i.id, item.orderId)
                                  }
                                >
                                  {i.name}
                                </span>
                              ))}
                            </li>
                          </ul>
                        </div>
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                  <hr className="mt-3" />
                </>
              );
            })}

            <div className="row">
              <div className="col-md-12 mt-2">
                <b>Delivery Address:</b>
                <br />
                <span>
                  {console.log(listingByOrder, 'KUUUUUU')}
                  {listingByOrder.address?.firstName} {listingByOrder.address?.lastName},
                  {listingByOrder.address?.address}, {listingByOrder.address?.city},{listingByOrder.address?.state},{' '}
                  {listingByOrder.address?.country}
                </span>
                <br />
              </div>
            </div>
          </Row>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose1}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Cancel modal */}
      <Modal show={show2} onHide={handleClose2} size="md" closeOnOverlayClick={false} closeOnEsc={false}>
        <Modal.Header closeButton>
          <Modal.Title>Order Cancel</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Row>
            <div className="form-group">
              <label>Cancellation reason</label>
              <textarea
                type="text"
                name="reason"
                value={formData.reason}
                onChange={(e) => {
                  inputHandler(e);
                  clearValidationError('reason');
                }}
                className="form-control"
                id="reason"
                style={{ marginTop: '10px', marginBottom: '10px' }}
                placeholder="Type cancellation reason"
              />
              {validationErrors.reason && <span className="text-danger">{validationErrors.reason}</span>}
            </div>
          </Row>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose2}>
            Close
          </Button>
          <Button variant="primary" onClick={updateCancelResaon}>
            Update
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Order;
