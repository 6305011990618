import Modal from 'react-responsive-modal';
import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { getDepartments, getCategoriesByDepartment, updateInnerCategories } from '../../../Action/user.action';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';

const updateInnerCategory = async (data) => {
  return await updateInnerCategories(data);
};

export const UpdateInnerCategories = ({ row }) => {
  const [open, setOpen] = useState(false);
  const queryClient = useQueryClient();
  const mutate = useMutation({
    mutationFn: updateInnerCategory,
    onSuccess: (data) => {
      setOpen(false);
      queryClient.invalidateQueries({ queryKey: ['inner_categories'] });
    },
    onError: (error) => {
      setOpen(false);
    },
    onSettled: () => {},
    onMutate: (data) => {
      setOpen(false);
    },
  });
  const { register, handleSubmit, reset, watch } = useForm({
    defaultValues: {
      id: '',
      department_id: '',
      category_id: '',
      name: '',
      name_ru: '',
    },
  });

  const department_id = watch('department_id');

  const { data: departments } = useQuery({
    queryKey: ['departments'],
    queryFn: () => getDepartments().then((res) => res.data),
  });

  const { data: categories } = useQuery({
    queryKey: ['categories', department_id],
    queryFn: () => getCategoriesByDepartment({ department_id }).then((res) => res.data),
    enabled: !!departments && !!department_id,
  });

  const onSubmit = async (data) => {
    try {
      const res = await mutate.mutateAsync({ ...data, id: row.id });
      console.log(res);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    reset({
      name: row.name,
      name_ru: row.name_ru,
    });
  }, [row]);

  return (
    <>
      <button onClick={() => setOpen(true)} className="btn btn-outline-primary">
        <i class="fa fa-pencil-square-o fa-xs" aria-hidden="true"></i>
      </button>
      <Modal
        closeOnOverlayClick
        open={open}
        onClose={() => setOpen(false)}
        center
        classNames={{ modal: 'modal__radius' }}
      >
        <form className="flex flex-col gap-2 mt-2" onSubmit={handleSubmit(onSubmit)}>
          <label>Update Inner Category</label>
          <div className="flex flex-col gap-2">
            <label htmlFor="department_id">Department</label>
            <select id="department_id" {...register('department_id', { required: true })}>
              <option value="">Select Department</option>
              {departments?.map((department) => (
                <option key={department.id} value={department.id}>
                  {department.name}
                </option>
              ))}
            </select>
          </div>
          <div className="flex flex-col gap-2">
            <label htmlFor="category_id">Category</label>
            <select id="category_id" {...register('category_id', { required: true })}>
              <option value="">Select Category</option>
              {categories?.map((category) => (
                <option key={category.id} value={category.id}>
                  {category.name}
                </option>
              ))}
            </select>
          </div>
          <div className="flex flex-col gap-2">
            <label htmlFor="name">Name</label>
            <input type="text" id="name" {...register('name', { required: true })} />
          </div>
          <div className="flex flex-col gap-2">
            <label htmlFor="name_ru">Name RU</label>
            <input type="text" id="name_ru" {...register('name_ru', { required: true })} />
          </div>
          <button type="submit" className="btn btn-outline-primary">
            Add
          </button>
        </form>
      </Modal>
    </>
  );
};
