import React, { useEffect, useState, useMemo } from "react";
import Sidebar from "../../directives/sidebar";
import Header from "../../directives/header";
import Footer from "../../directives/footer";
import JoditEditor from "jodit-react";
import { useParams } from "react-router-dom";
import { insertCMSContentAction } from "../../Action/user.action";
import { ToastContainer, toast } from "react-toastify";
import { baseurl } from "../../config/config";

const AddCMSContent = () => {
  const { id } = useParams();
  const [title, setTitle] = useState("");
  const [title_ru, setTitle_ru] = useState("");
  const [CMSdata, setCmsData] = useState({ CMSdata: "" });
  const [CMSdata_ru, setCmsData_ru] = useState({ CMSdata_ru: "" });
  const [validationErrors, setValidationErrors] = useState({});
  var pathArray = window.location.pathname.split("/");
  var page = pathArray[3];

  const config = useMemo(
    () => ({
      controls: {
        font: {
          list: {
            'Didact Gothic, sans-serif': 'Didact Gothic',
            'Rubik, sans-serif': 'Rubik',
            'Arial, sans-serif': 'Arial',
            'Georgia, serif': 'Georgia'
          }
        }
      }
    }),
    []
  );

  const handleSave = async () => {
    try {
      const data = {
        text: CMSdata.CMSdata, // Use CMSdata.CMSdata
        textRu: CMSdata_ru.CMSdata_ru,
        page: id,
        name: title,
        nameRu: title_ru,
      };

      const errors = {};

      if (!data.text && data.text.trim() === "") {
        errors.description = "Description is required";
      }
      if (data.text.trim() === "") {
        errors.description = "Description is required";
      }
      if (!data.textRu && data.textRu.trim() === "") {
        errors.textRu = "Description is required";
      }

      if (Object.keys(errors).length > 0) {
        setValidationErrors(errors);
      } else {
        // Clear validation errors
        clearValidationErrors();

        const res = await insertCMSContentAction(data);
        if (res.success) {
          toast.success("Success", {
            position: toast.POSITION.TOP_CENTER,
          });
          clearValidationErrors();
          setTimeout(() => {
            window.location.href = `${baseurl}cmscontentlist/` + id;
          }, 1200);
        } else {
          toast.error("Failed to save CMS content");
        }
      }
    } catch (error) {
      console.error("An error occurred while saving the CMS content:", error);
    }
  };

  const handleCMSChange = (newContent) => {
    setCmsData({ CMSdata: newContent });
    clearValidationErrors(); // Clear validation errors when content changes
  };

  const handleCMSChange_ru = (newContent) => {
    setCmsData_ru({ CMSdata_ru: newContent });
    clearValidationErrors(); // Clear validation errors when content changes
  };

  const clearValidationErrors = () => {
    setValidationErrors({});
  };

  return (
    <>
      <div className="content">
        <div className="grid grid-cols-12 gap-x-6">
          <div className="col-span-12">
            <div className="box fields">
              <div className="box-header">
                <h5 className="box-title font-normal">Add CMS Content</h5>
              </div>
              <div className="box-body">
                <form>
                  <div className="space-y-4">
                    <div className="space-y-2">
                      <label
                        htmlFor="exampleFormControlInput1"
                        className="form-label"
                      >
                        Title
                      </label>
                      <input
                        type="text"
                        className={`form-control ${validationErrors.title ? "is-invalid" : ""}`}
                        id="exampleFormControlInput1"
                        placeholder="Enter title name (EN)"
                        onChange={(e) => {
                          setTitle(e.target.value);
                        }}
                      />
                    </div>

                    <div className="space-y-2">
                      <input
                        type="text"
                        className={`form-control ${validationErrors.title_ru ? "is-invalid" : ""}`}
                        id="exampleFormControlInput1"
                        placeholder="Enter title name (RU)"
                        onChange={(e) => {
                          setTitle_ru(e.target.value);
                        }}
                      />
                    </div>

                    <div className="space-y-2">
                      <label className="ti-form-label mb-2">
                        Description (EN)
                      </label>
                      <JoditEditor
                        value={CMSdata.CMSdata}
                        onChange={handleCMSChange}
                        config={config}
                      />
                      {validationErrors.description && (
                        <span className="text-red-500 text-sm">
                          {validationErrors.description}
                        </span>
                      )}
                    </div>

                    <div className="space-y-2">
                      <label className="ti-form-label mb-2">
                        Description (RU)
                      </label>
                      <JoditEditor
                        value={CMSdata_ru.CMSdata_ru}
                        onChange={handleCMSChange_ru}
                        config={config}
                      />
                      {validationErrors.description_ru && (
                        <span className="text-red-500 text-sm">
                          {validationErrors.description_ru}
                        </span>
                      )}
                    </div>
                  </div>
                  <button
                    type="button"
                    className="ti-btn ti-btn-primary"
                    onClick={handleSave}
                  >
                    Save
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ToastContainer />
    </>
  );
};

export default AddCMSContent;
