import React from "react";
import DataTable from "react-data-table-component";
import Swal from "sweetalert2";
import "react-responsive-modal/styles.css";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import moment from "moment";
import { tableStyles } from "../../config/tableStyles";
import { DELETE_MODAL_CONFIG } from "../../const";
import {
  useDeleteUserMutation,
  useGetUsersQuery,
  useUpdateUserStatusMutation,
} from "../../store/api/userApi";
import { Loader } from "../UI/Loader";

const Users = () => {
  const [searchText, setSearchText] = React.useState("");
  const { data: users, isLoading: isUsersLoading } = useGetUsersQuery();
  const [updateUserStatus] = useUpdateUserStatusMutation();
  const [deleteUser] = useDeleteUserMutation();

  const updateStatus = (data) => async () => {
    let res = await updateUserStatus({
      id: data.id,
      isActive: !data.isActive,
    });
    if (res.data.success) {
    } else {
      Swal.fire("Failed!", res.msg, "error");
    }
  };

  const updateDeleteStatus = (data) => async () => {
    Swal.fire(DELETE_MODAL_CONFIG).then(async (result) => {
      if (result.isConfirmed) {
        let res = await deleteUser({ id: data.id, isDeleted: !data.isDeleted });
        if (res.data.success) {
        } else {
          Swal.fire("Failed!", res.msg, "error");
        }
      }
    });
  };

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  const filteredData = React.useMemo(
    () =>
      users?.data.filter((item, index) => {
        item = Object.values(item).some((value) =>
          String(value).toLowerCase().includes(searchText.toLowerCase()),
        );
        return item;
      }),
    [users, searchText],
  );

  const columns = [
    {
      name: "№",
      selector: (row) => `${row.index + 1}`,
      sortable: true,
    },
    {
      name: "Username",
      selector: (row) => row.firstName,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: "Date of joined",
      selector: (row) => {
        return (
          <>
            {row.birthDay ? (
              moment(row.birthDay).format("DD.MM.YYYY HH:mm:SS")
            ) : (
              <>No date of birth</>
            )}
          </>
        );
      },
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => {
        return (
          <>
            {row.isActive ? (
              <div style={{ color: "green" }}> Active</div>
            ) : (
              <div style={{ color: "red" }}> Inactive</div>
            )}
          </>
        );
      },
      sortable: true,
    },
    {
      name: "Action",
      cell: (row) => {
        return (
          <div className="button_wrp">
            {row.isActive == 1 ? (
              <button
                onClick={updateStatus(row)}
                className="btn btn-outline-success"
              >
                <i class="fa fa-check fa-xs" aria-hidden="true"></i>
              </button>
            ) : row.isActive == 0 ? (
              <button
                onClick={updateStatus(row)}
                className="btn btn-outline-danger"
              >
                {console.log(row)}
                <i className="fa fa-times  fa-s" aria-hidden="true"></i>
              </button>
            ) : (
              ""
            )}
            <button
              onClick={updateDeleteStatus(row)}
              className="btn btn-outline-danger"
            >
              <i class="fa fa-trash fa-xs" aria-hidden="true"></i>
            </button>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <div className="content">
        <div className="main-content">
          <div className="block justify-between page-header md:flex"></div>
          <div className="grid grid-cols-12 gap-6">
            <div className="col-span-12">
              <div className="box">
                <div className="box-header">
                  <h5 className="box-title font-normal">Users List</h5>
                </div>
                <div className="box-body">
                  {isUsersLoading && <Loader />}
                  <>
                    <input
                      type="text"
                      value={searchText}
                      onChange={handleSearch}
                      placeholder="Search..."
                      className="px-2 py-1 border rounded mb-4 searchtype"
                    />
                    {!!filteredData?.length && (
                      <div className="overflow-hidden table-bordered font-normal">
                        <DataTable
                          columns={columns}
                          data={filteredData}
                          pagination
                          paginationTotalRows={filteredData.length}
                          customStyles={tableStyles}
                        />
                      </div>
                    )}
                  </>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default Users;
