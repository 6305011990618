// Department refactoe and rename are needed
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import Swal from "sweetalert2";
import { fetchAndSetTranslations } from "../../i18n";
import { useTranslation } from "react-i18next";

import {
  getDutiesAction,
  createDuty,
  updateDuty,
  deleteDuty,
  categoryStatusUpdateAction,
} from "../../Action/user.action";
import "react-responsive-modal/styles.css";
import "react-toastify/dist/ReactToastify.css";
import { Modal } from "react-responsive-modal";
import { ToastContainer, toast } from "react-toastify";
import { tableStyles } from "../../config/tableStyles";
import { DELETE_MODAL_CONFIG } from "../../const";
import { Loader } from "../UI/Loader";

const ImportDuty = () => {
  const { t, i18n } = useTranslation();
  const [loader, setLoader] = useState(true);
  const [translation, setTranslation] = useState();
  const [listing, setListing] = useState([]);
  const [open, setOpen] = useState(false);
  const [modalSecond, setModalSecond] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState({});
  const [formData, setFormData] = useState({
    categoryName: "",
    categoryName_ru: "",
  });
  const [validationErrors, setValidationErrors] = useState({});

  useEffect(() => {
    getListingDetails();
    fetchTranslations();
  }, []);

  const fetchTranslations = async () => {
    try {
      const translations = await fetchAndSetTranslations("ru");
      setTranslation(translations);
    } catch (error) {
      console.error("An error occurred while fetching translations:", error);
    }
  };

  const handleCategoryNameChange = (e) => {
    setSelectedCategory({
      ...selectedCategory,
      [e.target.name]: e.target.value,
    });
    console.log(selectedCategory);
  };

  const clearValidationError = (fieldName) => {
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      [fieldName]: undefined,
    }));
  };

  // Add category ----------------------------------------
  const addCategory = async (e) => {
    e.preventDefault();
    const errors = {};
    if (!formData.free && formData.percent === "") {
      errors.categoryName_ru = "free or percent is required";
    }

    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
    } else {
      // Clear validation errors
      setValidationErrors({});
      try {
        const res = await createDuty(formData);
        if (res.success) {
          toast.success(res.msg, {
            position: toast.POSITION.TOP_CENTER,
          });
          onCloseModal();
          getListingDetails();
          fetchTranslations();
        } else {
          toast.error(res.msg, {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      } catch (error) {
        console.error("An error occurred while adding the category:", error);
      }
    }
  };

  const StatusUpdate = async (data) => {
    let res = await categoryStatusUpdateAction({
      id: data.id,
      status: data.isActive,
    });
    if (res.success) {
      getListingDetails();
    } else {
      Swal.fire("Failed!", res.msg, "error");
    }
  };

  const updateDeleteStatus = async (data) => {
    Swal.fire(DELETE_MODAL_CONFIG).then(async (result) => {
      if (result.isConfirmed) {
        let res = await deleteDuty({
          id: data.id
        });
        if (res.success) {
          getListingDetails();
        } else {
          Swal.fire("Failed!", res.msg, "error");
        }
      }
    });
  };

  // Update category  ---------------------------------
  const updateCategory = async (e) => {
    e.preventDefault();
    const errors = {};
    if (!selectedCategory.free || selectedCategory.percent === "") {
      errors.nameRu = "free or percent are required";
    }
    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
    } else {
      // Clear validation errors
      setValidationErrors({});
      try {
        const res = await updateDuty({id: selectedCategory.id, free: selectedCategory.free, percent: selectedCategory.percent});
        if (res.success) {
          toast.success("Success", {
            position: toast.POSITION.TOP_CENTER,
          });
          onCloseModalSecond();
          setTimeout(() => {
            fetchTranslations();
            getListingDetails();
          }, 0);
        } else {
          toast.error(res.msg, {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      } catch (error) {
        console.error("An error occurred while updating the category:", error);
      }
    }
  };

  // Get listing -------------------------------
  const getListingDetails = async () => {
    try {
      setLoader(true);
      const res = await getDutiesAction();
      if (res.success) {
        setLoader(false);
        setListing(res.data);
      }
    } catch (error) {
      console.error("An error occurred while getting the listing:", error);
    }
  };

  const columns = [
   
    {
      name: "Free",
      selector: (row) => row.free,
      sortable: true,
    },
    {
      name: "Percent",
      selector: (row) => row.percent,
      sortable: true,
    },
    {
      name: "Action",
      cell: (row) => {
        return (
          <div className="button_wrp">
            <button
              onClick={() => onOpenModalSecond(row)}
              className="btn btn-outline-primary"
            >
              <i class="fa fa-pencil-square-o fa-xs" aria-hidden="true"></i>
            </button>
            {row.isActive == 1 ? (
              <button
                onClick={() => StatusUpdate(row)}
                className="btn btn-outline-success"
              >
                <i class="fa fa-check  fa-xs" aria-hidden="true"></i>
              </button>
            ) : row.isActive == 0 ? (
              <button
                onClick={() => StatusUpdate(row)}
                className="btn btn-outline-danger"
              >
                <i class="fa fa-times fa-s" aria-hidden="true"></i>
              </button>
            ) : null}
            <button
              onClick={() => updateDeleteStatus(row)}
              className={
                !row.isDeleted
                  ? "btn btn-outline-dark"
                  : "btn btn-outline-danger"
              }
            >
              <i class="fa fa-trash fa-xs" aria-hidden="true"></i>
            </button>
          </div>
        );
      },
    },
  ];

  // Modal code  ---------------------------------------
  const onOpenModalSecond = (category) => {
    setSelectedCategory(category);
    setModalSecond(true);
  };

  const onCloseModalSecond = () => setModalSecond(false);

  const onOpenModal = () => setOpen(true);

  const onCloseModal = () => {
    setFormData({ categoryName: "" });
    setOpen(false);
  };

  return (
    <>
      <div className="content">
        {/* Start::main-content */}
        <div className="main-content">
          <div className="block justify-between page-header md:flex"></div>

          <div className="grid grid-cols-12 gap-6">
            <div className="col-span-12">
              <div className="box">
                <div className="box-header">
                  <h5 className="box-title font-normal">Import Duty</h5>
                  <button
                    type="button"
                    onClick={onOpenModal}
                    className="ti-btn ti-btn-outline-primary"
                    style={{ float: "right", marginTop: "-35px" }}
                  >
                    Add
                  </button>
                </div>

                <div className="box-body">
                  {!loader ? (
                    <div className="overflow-hidden table-bordered">
                      <DataTable
                        columns={columns}
                        data={listing}
                        pagination
                        customStyles={tableStyles}
                      />
                    </div>
                  ) : (
                    <Loader />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Start::main-content */}
      </div>
      {/* First modal: Add category -------------- */}
      <Modal open={open} onClose={onCloseModal} center>
        <label className="mb-2" htmlFor="categoryId">
          <h4>Duty</h4>
        </label>
        <hr />
        <div className="form-group mb-3">
          <label className="mb-2" htmlFor="free">
            Free 
          </label>
          <input
            type="text"
            name="free"
            value={formData.free}
            onChange={(e) =>
              setFormData(
                { ...formData, free: e.target.value },
                clearValidationError("free"),
              )
            }
            className="form-control"
            id="free"
            style={{ marginTop: "10px", marginBottom: "10px" }}
            placeholder="free"
          />
          {validationErrors.free && (
            <span className="text-danger">{validationErrors.free}</span>
          )}
        </div>

        {/* Add Category in Russian */}

        <div className="form-group mb-3">
          <label className="mb-2" htmlFor="percent">
            Percent
          </label>
          <input
            type="text"
            name="percent"
            value={formData.percent}
            onChange={(e) =>
              setFormData(
                { ...formData, percent: e.target.value },
                clearValidationError("percent"),
              )
            }
            className="form-control"
            id="percent"
            style={{ marginTop: "10px", marginBottom: "10px" }}
            placeholder="percent"
          />
          {validationErrors.percent && (
            <span className="text-danger">
              {validationErrors.percent}
            </span>
          )}
        </div>
        {/* <br/> */}
        <button
          type="submit"
          onClick={addCategory}
          className="btn btn-outline-primary "
        >
          Add
        </button>
      </Modal>
      {/* Edit modal code --------- */}
      <Modal
        open={modalSecond}
        onClose={onCloseModalSecond}
        center
        closeOnOverlayClick={false}
        closeOnEsc={false}
      >
        <label className="mb-2" htmlFor="categoryId font-normal">
          Duty
        </label>
        <div className="form-group mb-3">
          <input
            type="text"
            name="free"
            value={selectedCategory.free}
            onChange={(e) => {
              handleCategoryNameChange(e);
              clearValidationError("free");
            }}
            className="form-control select"
            id="free"
            placeholder="free"
          />
          {validationErrors.free && (
            <span className="text-danger">{validationErrors.free}</span>
          )}
        </div>

        <div className="form-group mb-3">
          <input
            type="text"
            name="percent"
            value={selectedCategory.percent}
            onChange={(e) => {
              handleCategoryNameChange(e);
              clearValidationError("percent");
            }}
            className="form-control select"
            id="percent"
            placeholder="percent"
          />
          {validationErrors.percent  && (
            <span className="text-danger">{validationErrors.percent}</span>
          )}
        </div>

        {/* <br /> */}
        <button
          type="submit"
          onClick={updateCategory}
          className="btn btn-outline-primary"
        >
          Update
        </button>
      </Modal>
      <ToastContainer />
    </>
  );
};

export default ImportDuty;
