import { useQuery, useMutation } from '@tanstack/react-query';
import { getRecommendationsModes, updateRecommendationModeStatus } from '../../Action/user.action';
import { Controller, useForm } from 'react-hook-form';
import { useEffect } from 'react';
import { ManualMode } from './ManualMode';

export const Recommendations = () => {
  const { data: modes, isLoading } = useQuery({
    queryKey: ['recommendationsMode'],
    queryFn: () => getRecommendationsModes().then((res) => res.data),
  });

  const { control, watch, setValue } = useForm({
    defaultValues: {
      type: '',
    },
  });

  const chosenMode = watch('type');

  const mutation = useMutation({
    mutationFn: updateRecommendationModeStatus,
  });

  useEffect(() => {
    if (modes && !chosenMode) {
      const activeMode = modes.find((mode) => mode.is_active);
      if (activeMode) {
        setValue('type', activeMode.id);
      }
    }
  }, [modes, chosenMode, setValue]);

  useEffect(() => {
    if (chosenMode) {
      mutation.mutate({ id: chosenMode });
    }
  }, [chosenMode]);

  return (
    <div className="content">
      <div className="main-content">
        <div className="radio-groups">
          <h3 className="mt-3">Modes</h3>
          <Controller
            name="type"
            control={control}
            render={({ field }) => (
              <>
                {modes?.map((mode) => (
                  <div key={mode.id} style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                    <input
                      type="radio"
                      checked={field.value === mode.id}
                      value={mode.id}
                      onChange={() => field.onChange(mode.id)}
                    />
                    {mode.id === 1 && 'Random Mode'}
                    {mode.id === 2 && 'Manual Mode'}
                  </div>
                ))}
              </>
            )}
          />
        </div>
        {chosenMode === 1 && (
          <div className="mt-5">
            <h3>Random Mode</h3>
          </div>
        )}
        {chosenMode === 2 && (
          <div className="mt-5">
            <ManualMode />
          </div>
        )}
      </div>
    </div>
  );
};
