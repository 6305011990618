import axios from 'axios';
import { useForm } from 'react-hook-form';
import { useQuery } from '@tanstack/react-query';
import { delete_store_from_db, getStoresSoft } from '../../Action/user.action';

export const Delete = () => {
  const { register, watch, handleSubmit } = useForm({
    defaultValues: {
      dbname: '',
      store_id: '',
    },
  });

  const dbname = watch('dbname');

  const { data: stores, isLoading } = useQuery({
    queryKey: ['stores', dbname],
    queryFn: () => getStoresSoft({ dbname: 'exp' }).then((res) => res.data),
  });

  const onSubmit = async (data) => {
    const response = await delete_store_from_db({ dbname: 'exp', store_id: data.store_id });
    console.log(response.data);
  };

  return (
    <div className="content">
      <div className="main-content">
        <div className="block justify-between page-header md:flex">
          <div></div>
        </div>
        <div className="grid grid-cols-12 gap-6">
          <div className="col-span-12">
            <div className="box">
              <div className="box-header font-normal">Delete DB</div>
              <form onSubmit={handleSubmit(onSubmit)} className="box-body flex flex-row gap-4">
                <select className="w-1/3" {...register('store_id')}>
                  <option value="">Select store id</option>
                  {stores && stores?.map((store) => <option value={store.id}>{store.name}</option>)}
                </select>
                <button type="submit" className="w-1/3">
                  Delete
                </button>
                <button type="submit" className="w-1/3">
                  Delete all
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
