import React, { useEffect, useState } from "react";
import "react-responsive-modal/styles.css";
import "react-toastify/dist/ReactToastify.css";
import { Modal } from "react-responsive-modal";
import { ToastContainer, toast } from "react-toastify";
import DataTable from "react-data-table-component";
import Swal from "sweetalert2";
import { fetchAndSetTranslations } from "../../i18n";
import { useTranslation } from "react-i18next";
import {
  getProductTypeListAction,
  insertProductTypeAction,
  updateProductTypeAction,
  updateProductTypeStatusAction,
  getCategoryAction,
  getSubCategoryAction,
  getInnerCategoryListAction,
  deleteProductTypeAction,
} from "../../Action/user.action";
import { tableStyles } from "../../config/tableStyles";
import { Loader } from "../UI/Loader";
import { DELETE_MODAL_CONFIG } from "../../const";

const ProductTypes = () => {
  const [loader, setLoader] = useState(true);
  const { t, i18n } = useTranslation();
  const [sublisting, setSubListing] = useState([]);
  const [open, setOpen] = useState(false);
  const [modalSecond, setModalSecond] = useState(false);
  const [formData, setFormData] = useState({
    id: "",
    departmentId: "",
    categoryId: "",
    subcategoryId: "",
    name: "",
    name_ru: "",
  });
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedSubCategory, setSelectedSubCategory] = useState("");
  const [selectedInnerCategory, setSelectedInnerCategory] = useState("");
  const [categories, setCategories] = useState([]);
  const [subCategory, setSubCategory] = useState([]);
  const [innerCategory, setInnerCategory] = useState([]);
  const [productType, setProductType] = useState([]);
  const [validationErrors, setValidationErrors] = useState({});
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    fetchCategories();
    fetchSubCategory();
    fetchProductTypeList();
    fetchInnerCategory();
    fetchTranslations();
  }, []);

  const fetchTranslations = async () => {
    try {
      const translations = await fetchAndSetTranslations("ru");
    } catch (error) {
      console.error("An error occurred while fetching translations:", error);
    }
  };

  const handleCategoryChange = (e) => {
    const selectedCategoryId = e.target.value;
    setFormData({ ...formData, departmentId: selectedCategoryId });
    setSelectedCategory(selectedCategoryId);
  };

  const handleSubCategoryChange = (e) => {
    const selectedSubCategoryId = e.target.value;
    setFormData({ ...formData, categoryId: selectedSubCategoryId });
    setSelectedSubCategory(selectedSubCategoryId);
  };

  const handleInnerCategoryChange = (e) => {
    const selectedInnerCategoryId = e.target.value;
    setFormData({ ...formData, subcategoryId: selectedInnerCategoryId });
    setSelectedInnerCategory(selectedInnerCategoryId);
  };

  const handleProductTypeNameChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const clearValidationError = (fieldName) => {
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      [fieldName]: undefined,
    }));
  };

  // add Product Type -----
  const addProductType = async (e) => {
    e.preventDefault();
    const errors = {};

    if (formData.departmentId === "") {
      errors.departmentId = "Category is required";
    }
    if (formData.categoryId === "") {
      errors.categoryId = "Sub category is required";
    }
    if (formData.subcategoryId === "") {
      errors.subcategoryId = "Inner category is required";
    }
    if (formData.name === "") {
      errors.name = "Product type name is required";
    }
    if (formData.name_ru === "") {
      errors.name_ru = "Product type name is required";
    }

    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
    } else {
      // Clear validation errors
      setValidationErrors({});
      try {
        const response = await insertProductTypeAction(formData);
        if (response.success) {
          toast.success("Success", {
            position: toast.POSITION.TOP_CENTER,
          });
          onCloseModal();
          setTimeout(() => {
            fetchProductTypeList();
            fetchTranslations();
          }, 0);
        } else {
          toast.error(response.msg, {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      } catch (error) {
        console.error(
          "An error occurred while adding the Product Type:",
          error,
        );
      }
    }
  };

  const onOpenModalSecond = (prodType) => {
    setSelectedCategory(prodType.departmentId);
    setSelectedSubCategory(prodType.categoryId);
    setSelectedInnerCategory(prodType.subcategoryId);
    setFormData({
      id: prodType.id,
      departmentId: prodType.departmentId,
      categoryId: prodType.categoryId,
      subcategoryId: prodType.subcategoryId,
      name: prodType.name,
      nameRu: prodType.nameru,
    });
    setModalSecond(true);
  };
  console.log("formData", formData);
  // Edit Product Type Name -------
  const updateProductTypeName = async (e) => {
    e.preventDefault();
    try {
      const errors = {};
      let data = {
        id: formData.id,
        departmentId: formData.departmentId,
        categoryId: formData.categoryId,
        subcategoryId: formData.subcategoryId,
        name: formData.name,
        nameRu: formData.nameRu,
      };
      if (data.departmentId == "") {
        errors.departmentId = "department name is required";
      }
      if (data.categoryId == "") {
        errors.categoryId = "category name is required";
      }
      if (data.subcategoryId == "") {
        errors.subcategoryId = "sub category name is required";
      }
      if (data.name === "") {
        errors.name = "Product type name is required";
      }
      if (data.nameRu === "") {
        errors.nameRu = "Product type name is required";
      }
      if (Object.keys(errors).length > 0) {
        setValidationErrors(errors);
      } else {
        // Clear validation errors
        setValidationErrors({});

        const response = await updateProductTypeAction(data);
        if (response.success) {
          toast.success("Success", {
            position: toast.POSITION.TOP_CENTER,
          });
          onCloseModalSecond();
        } else {
          console.error("Failed to update category");
        }
      }
    } catch (error) {
      console.error("An error occurred while updating the category:", error);
    }
  };

  const StatusUpdate = async (data) => {
    let res = await updateProductTypeStatusAction({
      id: data.id,
      status: data.isActive,
    });
    if (res.success) {
      fetchProductTypeList();
    } else {
      Swal.fire("Failed!", res.msg, "error");
    }
  };

  const updateDeleteStatus = async (data) => {
    Swal.fire(DELETE_MODAL_CONFIG).then(async (result) => {
      if (result.isConfirmed) {
        let res = await deleteProductTypeAction({
          id: data.id,
        });
        if (res.success) {
          fetchProductTypeList();
        } else {
          Swal.fire("Failed!", res.msg, "error");
        }
      }
    });
  };

  // fatch category for dropdown---
  const fetchCategories = async () => {
    try {
      const res = await getCategoryAction();
      if (res.success) {
        setCategories(res.data.filter((item) => item.isActive));
      }
    } catch (error) {
      console.error("An error occurred while getting categories:", error);
    }
  };

  // fatch Sub category for dropdown---
  const fetchSubCategory = async () => {
    try {
      const res = await getSubCategoryAction();
      if (res.success) {
        setSubCategory(res.data.filter((item) => item.isActive));
      }
    } catch (error) {
      console.error("An error occurred while getting sub categories:", error);
    }
  };

  // fatch Inner category for dropdown---
  const fetchInnerCategory = async () => {
    try {
      const res = await getInnerCategoryListAction();
      if (res.success) {
        setInnerCategory(res.data.filter((item) => item));
      }
    } catch (error) {
      console.error("An error occurred while getting Inner categories:", error);
    }
  };

  // fatch Product Type Name-----
  const fetchProductTypeList = async () => {
    try {
      setLoader(true);
      const res = await getProductTypeListAction();
      if (res.success) {
        setLoader(false);
        setProductType(res.data.productTypes);
        setSubListing(res.data.productTypes);
      }
      setLoader(false);
    } catch (error) {
      console.error(
        "An error occurred while getting product type names:",
        error,
      );
    }
  };

  const onOpenModal = () => {
    setOpen(true);
  };
  const onCloseModalSecond = () => {
    setSelectedSubCategory(null);
    setFormData({
      categoryId: "",
      subCategoryId: "",
      innerCategoryId: "",
      productTypeName: "",
    });
    setModalSecond(false);
  };

  const onCloseModal = () => {
    setFormData({
      categoryId: "",
      subCategoryId: "",
      innerCategoryId: "",
      productTypeName: "",
    });
    setOpen(false);
  };

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  const filteredData = React.useMemo(
    () =>
      sublisting.filter((item, index) => {
        item = Object.values(item).some((value) =>
          String(value).toLowerCase().includes(searchText.toLowerCase()),
        );
        return item;
      }),
    [sublisting, searchText],
  );

  const columns = [
    {
      name: "Department",
      selector: (row) => row.departmentName,
      sortable: true,
    },
    {
      name: "Category",
      selector: (row) => row.categoryName,
      sortable: true,
    },
    {
      name: "Sub category",
      selector: (row) => row.subcategoryName,
      sortable: true,
    },
    {
      name: "Product Type",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Action",
      cell: (row) => {
        return (
          <div className="button_wrp">
            <button
              onClick={() => onOpenModalSecond(row)}
              className="btn btn-outline-primary"
            >
              <i class="fa fa-pencil-square-o fa-xs" aria-hidden="true"></i>
            </button>
            {row.isActive == 0 ? (
              <button
                onClick={() => StatusUpdate(row)}
                className="btn btn-outline-danger redclosebtn"
              >
                <i class="fa fa-times fa-s" aria-hidden="true"></i>
              </button>
            ) : row.isActive == 1 ? (
              <button
                onClick={() => StatusUpdate(row)}
                className="btn btn-outline-success"
              >
                <i class="fa fa-check  fa-xs" aria-hidden="true"></i>
              </button>
            ) : (
              ""
            )}
            <button
              onClick={() => updateDeleteStatus(row)}
              className="btn btn-outline-danger"
            >
              <i class="fa fa-trash fa-xs" aria-hidden="true"></i>
            </button>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <div className="content">
        <div className="main-content">
          <div className="block justify-between page-header md:flex"></div>
          <div className="grid grid-cols-12 gap-6">
            <div className="col-span-12">
              <div className="box">
                <div className="box-header">
                  <h5 className="box-title font-normal">Product Type List</h5>
                  <button
                    type="button"
                    onClick={onOpenModal}
                    className="ti-btn ti-btn-outline-primary"
                    style={{ float: "right", marginTop: "-35px" }}
                  >
                    Add
                  </button>
                </div>
                <div className="box-body">
                  {/* Search input */}
                  <input
                    type="text"
                    value={searchText}
                    onChange={handleSearch}
                    placeholder="Search..."
                    className="px-2 py-1 border rounded mb-4"
                  />
                  {!loader ? (
                    <div className="overflow-hidden table-bordered">
                      <DataTable
                        columns={columns}
                        data={filteredData}
                        pagination
                        paginationTotalRows={filteredData.length}
                        customStyles={tableStyles}
                      />
                    </div>
                  ) : (
                    <Loader />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        open={open}
        onClose={onCloseModal}
        center
        closeOnOverlayClick={false}
        closeOnEsc={false}
      >
        <label className="mb-2 font-normal" htmlFor="departmentId">
          Add Product Type
        </label>
        <hr />
        <div className="form-group mb-3">
          <label className="mb-2 font-normal" htmlFor="departmentId">
            Department
          </label>
          <select
            name="departmentId"
            value={formData.departmentId}
            // onChange={handleCategoryChange}
            onChange={(e) => {
              handleCategoryChange(e);
              clearValidationError("departmentId");
            }}
            className="form-control select"
            id="departmentId"
          >
            <option value="" className="font-light">
              Select department
            </option>
            {categories.map((category) => (
              <option
                key={category.id}
                value={category.id}
                className="font-light"
              >
                {category.name}
              </option>
            ))}
          </select>
          {validationErrors.departmentId && (
            <span className="text-danger">{validationErrors.departmentId}</span>
          )}
        </div>

        {formData.departmentId !== "" ? (
          <div className="form-group mb-3">
            <label className="mb-2 font-notmal" htmlFor="categoryId">
              Category
            </label>
            <select
              name="categoryId"
              value={formData.categoryId}
              // onChange={handleSubCategoryChange}
              onChange={(e) => {
                handleSubCategoryChange(e);
                clearValidationError("categoryId");
              }}
              className="form-control select"
              id="categoryId"
            >
              <option value="" className="font-light">
                Select Category
              </option>
              {subCategory
                .filter((item) => item.departmentId == formData.departmentId)
                .map((subCategory) => (
                  <option
                    key={subCategory.id}
                    value={subCategory.id}
                    className="font-light"
                  >
                    {subCategory.name}
                  </option>
                ))}
            </select>
            {validationErrors.categoryId && (
              <span className="text-danger">{validationErrors.categoryId}</span>
            )}
          </div>
        ) : (
          ""
        )}

        {formData.categoryId !== "" ? (
          <div className="form-group mb-3">
            <label className="mb-2 font-normal" htmlFor="subcategoryId">
              Sub Category
            </label>
            <select
              name="subcategoryId"
              value={formData.subcategoryId}
              onChange={(e) => {
                handleInnerCategoryChange(e);
                clearValidationError("subcategoryId");
              }}
              className="form-control select"
              id="subcategoryId"
            >
              <option value="" className="font-light">
                Select Sub Category
              </option>
              {innerCategory
                .filter((item) => item.categoryId == formData.categoryId)
                .map((innerCategory) => (
                  <option
                    key={innerCategory.id}
                    value={innerCategory.id}
                    className="font-light"
                  >
                    {innerCategory.name}
                  </option>
                ))}
            </select>
            {validationErrors.subcategoryId && (
              <span className="text-danger">
                {validationErrors.subcategoryId}
              </span>
            )}
          </div>
        ) : (
          ""
        )}

        <div className="form-group mb-3">
          <label className="mb-2 font-normal" htmlFor="name">
            Product Type Name
          </label>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={(e) => {
              handleProductTypeNameChange(e);
              clearValidationError("name");
              <ol className="flex items-center whitespace-nowrap min-w-0">
                <li
                  className="text-sm text-gray-500 hover:text-primary dark:text-white/70"
                  aria-current="page"
                >
                  <button
                    type="button"
                    onClick={onOpenModal}
                    className="ti-btn ti-btn-outline-primary"
                  >
                    Add Category
                  </button>
                </li>
              </ol>;
            }}
            className="form-control select"
            id="productTypeName"
            placeholder="Product Type Name (EN)"
          />

          {validationErrors.name && (
            <span className="text-danger">{validationErrors.name}</span>
          )}
        </div>

        <div className="form-group mb-3">
          <input
            type="text"
            name="name_ru"
            value={formData.name_ru}
            onChange={(e) => {
              handleProductTypeNameChange(e);
              clearValidationError("name_ru");
              <ol className="flex items-center whitespace-nowrap min-w-0">
                <li
                  className="text-sm text-gray-500 hover:text-primary dark:text-white/70"
                  aria-current="page"
                >
                  <button
                    type="button"
                    onClick={onOpenModal}
                    className="ti-btn ti-btn-outline-primary"
                  >
                    Add Category
                  </button>
                </li>
              </ol>;
            }}
            className="form-control select"
            id="name_ru"
            placeholder="Product Type Name (RU)"
          />
          {validationErrors.name_ru && (
            <span className="text-danger">{validationErrors.name_ru}</span>
          )}
        </div>

        <button
          type="submit"
          onClick={addProductType}
          className="btn btn-outline-primary "
        >
          Add
        </button>
      </Modal>
      <Modal
        open={modalSecond}
        onClose={onCloseModalSecond}
        center
        closeOnOverlayClick={false}
        closeOnEsc={false}
      >
        <label className="mb-2 font-normal" htmlFor="departmentId">
          Edit Product Type
        </label>
        <hr />
        <div className="form-group mb-3">
          <label className="mb-2 font-normal" htmlFor="departmentId">
            Department
          </label>
          <select
            name="departmentId"
            value={formData.departmentId}
            onChange={(e) => {
              handleCategoryChange(e);
              clearValidationError("departmentId");
            }}
            className="form-control select"
            id="departmentId"
          >
            <option value="" className="font-light">
              Select department
            </option>
            {categories.map((category) => (
              <option key={category.id} value={category.id}>
                {category.name}
              </option>
            ))}
          </select>
          {validationErrors.departmentId && (
            <span className="text-danger">{validationErrors.departmentId}</span>
          )}
        </div>

        <div className="form-group mb-3">
          <label className="mb-2 font-normal" htmlFor="categoryId">
            Category
          </label>
          <select
            name="categoryId"
            value={formData.categoryId}
            onChange={(e) => {
              handleSubCategoryChange(e);
              clearValidationError("categoryId");
            }}
            className="form-control select"
            id="categoryId"
          >
            <option value="" className="font-light">
              Select Category
            </option>
            {subCategory
              .filter((item) => item.departmentId == formData.departmentId)
              .map((subCategory) => (
                <option
                  className="font-light"
                  key={subCategory.id}
                  value={subCategory.id}
                >
                  {subCategory.name}
                </option>
              ))}
          </select>
          {validationErrors.categoryId && (
            <span className="text-danger">{validationErrors.categoryId}</span>
          )}
        </div>

        <div className="form-group mb-3">
          <label className="mb-2" htmlFor="subcategoryId">
            Sub Category
          </label>
          <select
            name="subcategoryId"
            value={formData.subcategoryId}
            onChange={(e) => {
              handleInnerCategoryChange(e);
              clearValidationError("subcategoryId");
            }}
            className="form-control select"
            id="subcategoryId"
          >
            <option value="">Select sub category</option>
            {innerCategory
              .filter((item) => item.categoryId == formData.categoryId)
              .map((innerCategory) => (
                <option key={innerCategory.id} value={innerCategory.id}>
                  {innerCategory.name}
                </option>
              ))}
          </select>
          {validationErrors.subcategoryId && (
            <span className="text-danger">
              {validationErrors.subcategoryId}
            </span>
          )}
        </div>

        <div className="form-group mb-3">
          <label className="mb-2" htmlFor="name">
            Product Type Name
          </label>

          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={(e) => {
              handleProductTypeNameChange(e);
              clearValidationError("name");
            }}
            className="form-control select"
            id="name"
            placeholder="Product Type Name (EN)"
          />
          {validationErrors.name && (
            <span className="text-danger">{validationErrors.name}</span>
          )}
        </div>

        <div className="form-group mb-3">
          <input
            type="text"
            name="nameRu"
            value={formData.nameRu}
            onChange={(e) => {
              handleProductTypeNameChange(e);
              clearValidationError("name_ru");
            }}
            className="form-control select"
            id="nameRu"
            placeholder="Product Type Name (RU)"
          />
          {validationErrors.nameRu && (
            <span className="text-danger">{validationErrors.nameRu}</span>
          )}
        </div>

        <br />
        <button
          type="submit"
          onClick={updateProductTypeName}
          className="btn btn-outline-primary"
        >
          Update
        </button>
      </Modal>
      <ToastContainer />
    </>
  );
};

export default ProductTypes;
