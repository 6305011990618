import React, { useEffect, useState } from "react";
import Sidebar from "../../directives/sidebar";
import Header from "../../directives/header";
import Footer from "../../directives/footer";
import DataTable from "react-data-table-component";
import Swal from "sweetalert2";
import {
  getCMSContentByPageAction,
  deletedPageContentAction,
  updatePagesContentStatusAction,
} from "../../Action/user.action";
import "react-responsive-modal/styles.css";
import "react-toastify/dist/ReactToastify.css";
import Togglesidebar from "../../directives/togglesidebar";
import config from "../../config/config";
import { useParams } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import JoditEditor from "jodit-react";
import Button from "react-bootstrap/Button";
import { ToastContainer, toast } from "react-toastify";
import { tableStyles } from "../../config/tableStyles";
import { Loader } from "../UI/Loader";

const CMSContentList = () => {
  const [loader, setLoader] = useState(true);
  const { id } = useParams();
  const [listing, setListing] = useState([]);
  const [searchText, setSearchText] = useState("");
  var pathArray = window.location.pathname.split("/");
  var page = pathArray[3];

  useEffect(() => {
    getListingDetails();
  }, []);

  const getListingDetails = async () => {
    try {
      setLoader(true);
      const res = await getCMSContentByPageAction({ page: id });
      if (res.success) {
        setLoader(false);
        setListing(res.data);
      }
    } catch (error) {
      console.error("An error occurred while getting the listing:", error);
    }
  };

  const StatusUpdate = async (data) => {
    let res = await updatePagesContentStatusAction({
      id: data.id,
      status: data.status === 0 ? "1 " : "0",
    });
    if (res.success) {
      getListingDetails();
    } else {
      Swal.fire("Failed!", res.msg, "error");
    }
  };

  const updateDeleteStatus = async (data) => {
    Swal.fire({
      title: "Are you sure?",
      text: `You want to Delete it!`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: `Yes, Delete it!`,
    }).then(async (result) => {
      if (result.isConfirmed) {
        let res = await deletedPageContentAction({
          id: data.id,
          status: data.status === 1,
        });
        if (res.success) {
          getListingDetails();
        } else {
          Swal.fire("Failed!", res.msg, "error");
        }
      }
    });
  };

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  const filteredData = listing.filter((item, index) => {
    item.index = index;
    item = Object.values(item).some((value) =>
      String(value).toLowerCase().includes(searchText.toLowerCase()),
    );
    return item;
  });

  const columns = [
    {
      name: "№",
      selector: (row) => `${row.index + 1}`,
      sortable: true,
    },
    {
      name: "Name",
      selector: (row) => (row.name ? row.name + " " : "----"),
      sortable: true,
    },
    {
      name: "Action",
      cell: (row) => {
        return (
          <>
            <a
              href={
                `${process.env.REACT_APP_BASE_URL}editcmscontent/` + row?.id
              }
            >
              <button className="btn btn-primary">
                <i class="fa fa-pencil-square-o fa-xs" aria-hidden="true"></i>
              </button>
            </a>
            &nbsp;
            {row.status == 0 ? (
              <button
                onClick={() => StatusUpdate(row)}
                className="btn btn-danger"
              >
                <i class="fa fa-times fa-s" aria-hidden="true"></i>
              </button>
            ) : row.status == 1 ? (
              <button
                onClick={() => StatusUpdate(row)}
                className="btn btn-success"
              >
                <i class="fa fa-check  fa-xs" aria-hidden="true"></i>
              </button>
            ) : (
              ""
            )}
            &nbsp;
            <button
              onClick={() => updateDeleteStatus(row)}
              className="btn btn-danger"
            >
              <i class="fa fa-trash fa-xs" aria-hidden="true"></i>
            </button>
          </>
        );
      },
    },
  ];

  return (
    <>
      <div className="content">
        <div className="main-content">
          <div className="block justify-between page-header md:flex"></div>
          <div className="grid grid-cols-12 gap-6">
            <div className="col-span-12">
              <div className="box">
                <div className="box-header">
                  <h5 className="box-title font-normal">Pages Content</h5>
                  <a
                    href={
                      `${process.env.REACT_APP_BASE_URL}addcmscontent/` + id
                    }
                    className="ti-btn ti-btn-primary"
                    style={{ float: "right", marginTop: "-35px" }}
                  >
                    Add
                  </a>
                </div>

                <div className="box-body">
                  <input
                    type="text"
                    value={searchText}
                    onChange={handleSearch}
                    placeholder="Search..."
                    className="px-2 py-1 border rounded mb-4"
                  />
                  {!loader ? (
                    <div className="overflow-hidden table-bordered">
                      <DataTable
                        columns={columns}
                        data={filteredData}
                        pagination
                        paginationTotalRows={filteredData.length}
                        customStyles={tableStyles}
                      />
                    </div>
                  ) : (
                    <Loader />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default CMSContentList;
