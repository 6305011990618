import React from 'react';
import { Modal } from 'react-responsive-modal';

const AddSubcategoryModal = ({
  open,
  onClose,
  formData,
  handleChange,
  handleSubmit,
  categories,
  subcategories,
  validationErrors,
  clearValidationError,
}) => {
  return (
    <Modal open={open} onClose={onClose} center closeOnOverlayClick={false} closeOnEsc={false}>
      <label className="mb-2" htmlFor="departmentId">
        <h4 className="font-normal">Add Sub Category</h4>
      </label>
      <hr />
      <form onSubmit={handleSubmit}>
        <div className="form-group mb-2">
          <label className="mb-2" htmlFor="departmentId">
            Department
          </label>
          <select
            name="departmentId"
            value={formData.departmentId}
            onChange={(e) => {
              handleChange(e);
              clearValidationError('departmentId');
            }}
            className="form-control select"
            id="departmentId"
          >
            <option value="" className="font-light">
              Select a department
            </option>
            {categories.map((category) => (
              <option key={category.id} value={category.id} className="font-light">
                {category.name}
              </option>
            ))}
          </select>
          {validationErrors.departmentId && <span className="text-danger">{validationErrors.departmentId}</span>}
        </div>

        {formData.departmentId !== '' && (
          <div className="form-group mb-2">
            <label className="mb-2 font-normal" htmlFor="categoryId">
              Category
            </label>
            <select
              name="categoryId"
              value={formData.categoryId}
              onChange={(e) => {
                handleChange(e);
                clearValidationError('categoryId');
              }}
              className="form-control select"
              id="categoryId"
            >
              <option value="" className="font-light">
                Select a category
              </option>
              {subcategories
                .filter((item) => String(item.departmentId) === String(formData.departmentId))
                .map((category) => (
                  <option key={category.id} value={category.id} className="font-light">
                    {category.name}
                  </option>
                ))}
            </select>
            {validationErrors.categoryId && <span className="text-danger">{validationErrors.categoryId}</span>}
          </div>
        )}

        <div className="form-group mb-2">
          <label className="mb-2 font-normal" htmlFor="name">
            Subcategory Name (EN)
          </label>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={(e) => {
              handleChange(e);
              clearValidationError('name');
            }}
            className="form-control select"
            id="name"
            placeholder="Subcategory name (EN)"
          />
          {validationErrors.name && <span className="text-danger">{validationErrors.name}</span>}
        </div>

        <div className="form-group mb-3">
          <label className="mb-2 font-normal" htmlFor="name_ru">
            Subcategory Name (RU)
          </label>
          <input
            type="text"
            name="name_ru"
            value={formData.name_ru}
            onChange={(e) => {
              handleChange(e);
              clearValidationError('name_ru');
            }}
            className="form-control select"
            id="name_ru"
            placeholder="Subcategory name (RU)"
          />
          {validationErrors.name_ru && <span className="text-danger">{validationErrors.name_ru}</span>}
        </div>

        <div className="form-group mb-2">
          <label className="mb-2" htmlFor="size_guide">
            Size Guide
          </label>
          <select
            name="size_guide"
            value={formData.size_guide}
            onChange={(e) => {
              handleChange(e);
            }}
            className="form-control"
            id="size_guide"
          >
            <option value="">Select size guide</option>

            <option value="top">Top</option>
            <option value="bottom">Bottom</option>
            <option value="shoes">Shoes</option>
            <option value="none">none</option>
          </select>
          {validationErrors.size_guide && <span className="text-danger">{validationErrors.size_guide}</span>}
        </div>
        <button type="submit" className="btn btn-outline-primary">
          Add
        </button>
      </form>
    </Modal>
  );
};

export default AddSubcategoryModal;
