import i18n from "i18next";
import axios from "axios";
import config from "./config/config";

i18n.init({
  interpolation: { escapeValue: false },
  lng: "ru",
});

const fetchTranslations = async (language) => {
  try {
    const res = await axios.get(`${config.langUrl}?lang=${language}`);
    return res.data;
  } catch (error) {
    console.error(`Error in fetching translations:`, error);
    return {};
  }
};

export const fetchAndSetTranslations = async (language) => {
  try {
    const translations = await fetchTranslations(language);
    i18n.addResourceBundle(language, "translation", translations);
    return translations;
  } catch (error) {
    console.error("Error fetching translations:", error);
    return {};
  }
};

fetchAndSetTranslations("ru");

export default i18n;
