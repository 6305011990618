import { useQuery } from '@tanstack/react-query';
import { LoaderIcon } from 'react-hot-toast';
import {
  getBanners,
  getCategoriesByDepartment,
  getDepartments,
  getMainBanner,
  setBanner,
} from '../../Action/user.action';
import { useForm } from 'react-hook-form';
import { useState, useEffect } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import { DepartmentCard } from './DepartmentCard';
import { DepartmentSubCard } from './DepartmentSubCard';
import Modal from 'react-responsive-modal';
import { AddDepModal } from './AddDepModal';

export const DepartmentBanner = () => {
  const loginData = !Cookies.get('loginSuccessFarfetchAdmin')
    ? []
    : JSON.parse(Cookies.get('loginSuccessFarfetchAdmin'));

  const [file, setFile] = useState(null);

  const [bannerData, setBannerData] = useState([]);
  const [loading, setLoader] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const { data: departments, isLoading } = useQuery({
    queryKey: ['department'],
    queryFn: () => getDepartments().then((res) => res.data),
  });

  const { register, reset, handleSubmit, watch } = useForm({
    department_id: '',
    category_id: '',
    title: '',
    title_ru: '',
    main_title_ru: '',
    main_title: '',
    banner: null,
    opacity: '',
  });

  const { data: mainBannerData, refetch: refetchMainBanner } = useQuery({
    queryKey: ['mainBannerData'],
    queryFn: () =>
      getMainBanner()
        .then((res) => {
          reset(res.data);
          return res.data;
        })
        .catch((err) => console.log(err)),
  });

  const title = watch('title');
  const title_ru = watch('title_ru');
  const main_title = watch('main_title');
  const main_title_ru = watch('main_title_ru');
  const opacity = watch('opacity');
  const selectedDep = watch('department_id');
  const selectedCat = watch('category_id');
  const banner = watch('banner');

  const { data: categories } = useQuery({
    queryKey: [`categories-main`, selectedDep],
    queryFn: () =>
      getCategoriesByDepartment({ department_id: selectedDep })
        .then((res) => res.data)
        .catch((err) => console.log(err)),
    enabled: !!selectedDep,
  });

  const { data: notBannerData, refetch: refetchBanners } = useQuery({
    queryKey: ['bannerData'],
    queryFn: () =>
      getBanners()
        .then((res) => res.data)
        .catch((err) => console.log(err)),
  });

  useEffect(() => {
    setBannerData(notBannerData);
  }, [notBannerData]);

  const onSubmit = async (data) => {
    setLoader(true);
    const formData = new FormData();
    formData.append('department_id', selectedDep);
    formData.append('title', title);
    formData.append('title_ru', title_ru);
    formData.append('main_title_ru', main_title_ru);
    formData.append('main_title', main_title);
    formData.append('opacity', opacity);
    if (banner) {
      formData.append('banner', banner?.[0]);
    }

    formData.append('category_id', selectedCat);
    formData.append('id', 19);

    await axios
      .patch(`${process.env.REACT_APP_API_URL}/updateMainBanner`, formData, {
        headers: {
          Authorization: loginData?.authToken,
        },
      })
      .then((res) => {
        refetchMainBanner();
        setLoader(false);
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
      });

    setLoader(false);
  };

  return (
    <div className="content">
      <div className="main-content">
        <div className="box-header">
          <h5 className="box-title font-normal">Banners List</h5>
          <button
            type="button"
            onClick={() => setOpenModal(true)}
            className="ti-btn ti-btn-outline-primary"
            style={{ float: 'right', marginTop: '-35px' }}
          >
            Add
          </button>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            gap: '20px',
            padding: '1rem',
            boxSizing: 'border-box',
            flexWrap: 'wrap',
          }}
        >
          {
            <div
              className="border p-5 dark:border-white/10"
              style={{ width: 'calc(25% - 40px)', minWidth: '313px', display: 'flex', flexDirection: 'column' }}
            >
              <h3>For Main</h3>
              <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                <label>Selected Banner for Department</label>
                {
                  <div
                    style={{
                      width: 'auto',
                      height: '300px',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      gap: '20px',
                    }}
                  >
                    <img
                      style={{ objectFit: 'contain', height: '100%' }}
                      src={`${process.env.REACT_APP_MEDIA_URL}/banners/${mainBannerData?.url}`}
                    />
                  </div>
                }
              </div>
              <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', marginTop: '20px' }}>
                <label>Add New Banner Image</label>
                <input {...register('banner')} type="file" accept=".webp" />
                <select {...register('department_id')}>
                  {departments?.map((dep) => (
                    <option key={dep.id} value={dep.id}>
                      {dep.name}
                    </option>
                  ))}
                </select>
                <select {...register('category_id')}>
                  <option value={null}>Select category</option>
                  {categories?.map((dep) => (
                    <option key={dep.id} value={dep.id}>
                      {dep.name}
                    </option>
                  ))}
                </select>
                <label>Title</label>
                <input {...register('title')} className="border p-2" />
                <label>Title ru</label>
                <input {...register('title_ru')} className="border p-2" />
                <label>Title</label>
                <input {...register('main_title')} className="border p-2" />
                <label>Title Ru</label>
                <input {...register('main_title_ru')} className="border p-2" />
                <label>Font Opacity</label>
                <input {...register('opacity')} className="border p-2" />
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: '20px',
                    marginTop: '10px',
                  }}
                >
                  <button
                    style={{ padding: '10px', border: '1px solid #ccc', width: '100%' }}
                    onClick={() => onSubmit()}
                    className="special_special_button flex items-center justify-center"
                    type="submit"
                  >
                    {loading ? <LoaderIcon /> : 'Submit'}
                  </button>
                </div>
              </div>
            </div>
          }
          {bannerData
            ?.sort((a, b) => b.is_active - a.is_active)
            .map((banner, index) =>
              banner.have_sub_department ? (
                <DepartmentSubCard
                  departments={departments}
                  key={`${index}-${banner.id}`}
                  banner={banner}
                  refetchBanners={refetchBanners}
                  setBannerData={setBannerData}
                />
              ) : (
                <DepartmentCard
                  departments={departments}
                  key={`${index}-${banner.id}`}
                  banner={banner}
                  refetchBanners={refetchBanners}
                  setBannerData={setBannerData}
                />
              )
            )}
        </div>
      </div>
      <AddDepModal
        departments={departments}
        setOpenModal={setOpenModal}
        refetchBanners={refetchBanners}
        openModal={openModal}
      />
    </div>
  );
};
