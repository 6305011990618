import React, { useEffect } from 'react';
import { Modal } from 'react-responsive-modal';
import { useForm, useFieldArray, useWatch } from 'react-hook-form';
import { updateDeliveryPrices } from '../../Action/user.action';

export const UpdateDeliveryModal = ({
  delivery,
  regions,
  editModal,
  setEditModal,
  refetchDeliveryData,
  stores,
  delivery_names,
}) => {
  const {
    register,
    control,
    handleSubmit,
    reset,
    setValue,
    watch,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      additional_charges: {},
    },
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'additional_charges',
  });

  useEffect(() => {
    if (delivery) {
      reset({
        ...delivery,
        store_id: stores.find((i) => i.name === delivery.store).id,
        additional_charges: delivery.additional_charges || {},
        delivery_id_1: delivery_names.find((i) => i.name === delivery.delivery_1).id,
        delivery_id_2: delivery_names.find((i) => i.name === delivery.delivery_2).id,
      });
    }
  }, [delivery, reset]);
  const onSubmit = async (data) => {
    try {
      const res = await updateDeliveryPrices(data);
    } catch (err) {
    } finally {
      refetchDeliveryData();
      setEditModal(false);
      reset();
    }
  };
  const addNewAdditionalCharge = () => {
    const newKey = prompt('Enter charge name'); // Prompt or dynamically generate a unique key
    if (newKey && !getValues(`additional_charges.${newKey}`)) {
      setValue(`additional_charges.${newKey}`, 0); // Set the default value
    } else {
      alert('Charge name is invalid or already exists.');
    }
  };

  const removeAdditionalCharge = (key) => {
    const charges = getValues('additional_charges') || {};
    delete charges[key]; // Remove the key from the object
    setValue('additional_charges', { ...charges }); // Update the form with the new object
  };

  const watchedFields = useWatch({
    control,
    name: ['delivery_price_1', 'delivery_price_2', 'margin_price', 'additional_charges'],
  });

  useEffect(() => {
    const [delivery_price_1, delivery_price_2, margin_price, additional_charges] = watchedFields;
    // Calculate total additional charges
    const delivery1 = parseFloat(delivery_price_1) || 0;
    const delivery2 = parseFloat(delivery_price_2) || 0;
    const margin = parseFloat(margin_price) || 0;

    // Calculate total additional charges
    const additionalTotal = Object.values(additional_charges)?.reduce(
      (total, value) => total + (parseFloat(value) || 0),
      0
    );

    // Calculate the total price
    const total = delivery1 + delivery2 + margin + additionalTotal;

    // Set the calculated total value
    setValue('total', total.toFixed(2)); // Format to 2 decimal places
  }, [watchedFields, setValue]);

  return (
    <Modal open={editModal} onClose={() => setEditModal(false)} center>
      <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
        <div>
          <label className="block">Страна отправления</label>
          <select className="w-full" {...register('sender_region_id')}>
            {regions?.map((reg) => (
              <option value={reg.id} key={reg.id}>
                {reg.name}
              </option>
            ))}
          </select>
          {errors.sender_region_id && <span className="text-red-500">This field is required</span>}
        </div>

        <div>
          <label className="block">Название Магазина</label>
          <select className="w-full" {...register('store_id')}>
            {stores?.map((reg) => (
              <option value={reg.id} key={reg.id}>
                {reg.name}
              </option>
            ))}
          </select>
          {errors.store_id && <span className="text-red-500">This field is required</span>}
        </div>

        <div>
          <label className="block">Страна назначения</label>
          <select className="w-full" {...register('receiver_region_id')}>
            {regions?.map((reg) => (
              <option value={reg.id} key={reg.id}>
                {reg.name}
              </option>
            ))}
          </select>
          {errors.receiver_region_id && <span className="text-red-500">This field is required</span>}
        </div>

        <div className="flex justify-center items-center" style={{ fontSize: '20px' }}>
          Delivery Calculation
        </div>

        <div>
          <label className="block">Название курьерской службы №1</label>
          <select className="w-full" {...register('delivery_id_1')}>
            {delivery_names?.map((del) => (
              <option key={del.id} value={del.id}>
                {del.name}
              </option>
            ))}
          </select>
          {errors.delivery_1 && <span className="text-red-500">This field is required</span>}
        </div>

        <div>
          <label className="block">Стоимость</label>
          <input
            type="number"
            min={0}
            {...register('delivery_price_1', {
              required: true,
              validate: (value) => value >= 0 || 'Price cannot be negative',
            })}
            className="w-full border p-2"
          />
          {errors.delivery_price_1 && <span className="text-red-500">{errors.delivery_price_1.message}</span>}
        </div>

        <div>
          <label className="block">Название курьерской службы №2</label>
          <select className="w-full" {...register('delivery_id_2')}>
            {delivery_names?.map((del) => (
              <option key={del.id} value={del.id}>
                {del.name}
              </option>
            ))}
          </select>
          {errors.delivery_2 && <span className="text-red-500">This field is required</span>}
        </div>

        <div>
          <label className="block">Стоимость</label>
          <input
            type="number"
            min={0}
            {...register('delivery_price_2', {
              required: true,
              validate: (value) => value >= 0 || 'Price cannot be negative',
            })}
            className="w-full border p-2"
          />
          {errors.delivery_price_2 && <span className="text-red-500">{errors.delivery_price_2.message}</span>}
        </div>

        <div>
          <label className="block">Дополнительные расходы</label>
          {Object.entries(watch('additional_charges') || {}).map(([key, value], index) => (
            <div key={index} className="flex space-x-2 mt-2">
              <input value={key} readOnly className="w-1/2 border p-2" />
              <input
                {...register(`additional_charges.${key}`, {
                  required: true,
                  validate: (value) => value >= 0 || 'Charge cannot be negative',
                })}
                defaultValue={value || 0}
                placeholder="Стоимость"
                className="w-1/2 border p-2"
                type="number"
                min={0}
              />
              <button
                type="button"
                onClick={() => removeAdditionalCharge(key)}
                className="px-2 py-1 bg-red-500 text-white rounded"
              >
                Удалить
              </button>
            </div>
          ))}
          <button
            type="button"
            onClick={addNewAdditionalCharge}
            className="mt-2 px-2 py-1 bg-green-500 text-white rounded"
          >
            Добавить
          </button>
        </div>

        <div>
          <label className="block">Маржа</label>
          <input
            type="number"
            min={0}
            {...register('margin_price', {
              required: true,
              validate: (value) => value >= 0 || 'Margin cannot be negative',
            })}
            className="w-full border p-2"
          />
          {errors.margin_price && <span className="text-red-500">{errors.margin_price.message}</span>}
        </div>

        <div>
          <label className="block">Total price</label>
          <input disabled={true} {...register('total', { required: true })} className="w-full border p-2" />
          {errors.total && <span className="text-red-500">This field is required</span>}
        </div>

        <button type="submit" className="w-full px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600">
          Добавить
        </button>
      </form>
    </Modal>
  );
};
