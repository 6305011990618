import React, { useEffect, useState } from "react";
import "react-responsive-modal/styles.css";
import "react-toastify/dist/ReactToastify.css";
import { Modal } from "react-responsive-modal";
import { ToastContainer, toast } from "react-toastify";
import { Link } from "react-router-dom";

import DataTable from "react-data-table-component";
import Swal from "sweetalert2";
import config from "../../config/config";
import {
  getProductListAction,
  productStatusUpdateAction,
  deleteProductAction,
} from "../../Action/user.action";
import { tableStyles } from "../../config/tableStyles";

const ProductList = () => {
  const [productList, setProductList] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [loader, setLoader] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [productName, setProductName] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  useEffect(() => {
    fetchProductsList();
  }, [currentPage, pageSize]);

  const StatusUpdate = async (data) => {
    let res = await productStatusUpdateAction({
      id: data.id,
      status: data.isActive,
    });
    if (res.success) {
      fetchProductsList();
    } else {
      Swal.fire("Failed!", res.msg, "error");
    }
  };

  const updateDeleteStatus = async (data) => {
    Swal.fire({
      title: "Are you sure?",
      text: `You want to Delete it!`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: `Yes, Delete it!`,
    }).then(async (result) => {
      if (result.isConfirmed) {
        let res = await deleteProductAction({
          id: data.id,
        });
        if (res.success) {
          fetchProductsList();
        } else {
          Swal.fire("Failed!", res.msg, "error");
        }
      }
    });
  };

  // Fetch Product List
  const fetchProductsList = async () => {
    try {
      setLoader(true);
      const res = await getProductListAction({
        page: currentPage,
        pageSize: pageSize,
      });
      if (res.success) {
        setLoader(false);
        setProductList(res.data);
      } else {
        setLoader(false);
      }
    } catch (error) {
      console.error(
        "An error occurred while getting product type names:",
        error,
      );
    }
  };

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    setPageSize(newPerPage);
    setCurrentPage(1); // Reset page to 1 when changing page size
  };

  const filteredData = productList.products?.filter((item, index) => {
    item.index = index;
    item = Object.values(item).some((value) =>
      String(value).toLowerCase().includes(searchText.toLowerCase()),
    );
    return item;
  });

  const handleRowClick = (row) => () => {
    setProductName(row.name);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const columns = [
    {
      name: "№",
      selector: (row) => `${row.index + 1}`,
      sortable: true,
    },
    {
      name: "Product Name",
      selector: (row) => (
        <button onClick={handleRowClick(row)} color="blue">
          {row.name.substring(0, 20) + "..."}
        </button>
      ),
      sortable: true,
    },
    {
      name: "Department Name",
      selector: (row) => row.departmentName,
      sortable: true,
    },
    {
      name: "Category Name",
      selector: (row) => row.categoryName,
      sortable: true,
    },
    {
      name: "Action",
      cell: (row) => {
        return (
          <div className="button_wrp">
            <a href={`${process.env.REACT_APP_BASE_URL}editProduct/` + row?.id}>
              <button className="btn  btn-outline-primary">
                <i class="fa fa-pencil-square-o fa-xs" aria-hidden="true"></i>
              </button>
            </a>
            {row.isActive == 0 ? (
              <button
                onClick={() => StatusUpdate(row)}
                className="btn btn-danger redclosebtn"
              >
                <i class="fa fa-times fa-s" aria-hidden="true"></i>
              </button>
            ) : row.isActive == 1 ? (
              <button
                onClick={() => StatusUpdate(row)}
                className="btn btn-success"
              >
                <i class="fa fa-check fa-xs" aria-hidden="true"></i>
              </button>
            ) : (
              ""
            )}
            <button
              onClick={() => updateDeleteStatus(row)}
              className="btn btn-outline-danger"
            >
              <i class="fa fa-trash fa-xs" aria-hidden="true"></i>
            </button>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <div className="content">
        <div className="main-content">
          <div className="block justify-between page-header md:flex"></div>

          <div className="grid grid-cols-12 gap-6">
            <div className="col-span-12">
              <div className="box">
                <div className="box-header">
                  <h5 className="box-title font-normal">Product List</h5>
                  <Link to={`${process.env.REACT_APP_BASE_URL}addProduct`}>
                    <span>
                      <button
                        type="button"
                        className="ti-btn ti-btn-outline-primary"
                        style={{ float: "right", marginTop: "-35px" }}
                      >
                        Add
                      </button>
                    </span>
                  </Link>
                </div>
                <div className="box-body">
                  {/* Search input */}
                  <input
                    type="text"
                    value={searchText}
                    onChange={handleSearch}
                    placeholder="Search..."
                    className="px-2 py-1 border rounded mb-4"
                  />
                  <div className="overflow-hidden table-bordered">
                    <DataTable
                      columns={columns}
                      data={filteredData}
                      progressPending={loader}
                      pagination
                      paginationServer
                      onChangePage={handlePageChange}
                      onChangeRowsPerPage={handlePerRowsChange}
                      paginationTotalRows={productList?.meta?.total}
                      customStyles={tableStyles}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ToastContainer />

      <Modal
        open={openModal}
        onClose={handleCloseModal}
        center
        styles={{ modal: { minWidth: 400 } }}
      >
        <h5>Product Name</h5>
        <hr />
        <p>{productName}</p>
      </Modal>
    </>
  );
};

export default ProductList;
