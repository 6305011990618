import { apiSlice } from "./apiSlice";

export const departmentsApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getDepartments: builder.query({
      query: (params) => ({
        params: params,
        url: "department",
      }),
      providesTags: ["Departments"],
    }),
    getActiveDepartments: builder.query({
      query: (params) => ({
        params: params,
        url: "department",
      }),
      providesTags: ["Departments"],
      transformResponse: (res) => res.data.filter((i) => i.isActive),
    }),
  }),
});

export const { useGetDepartmentsQuery, useGetActiveDepartmentsQuery } =
  departmentsApi;
