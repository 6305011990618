import { Outlet } from "react-router-dom";
import Header from "../../directives/header";
import Sidebar from "../../directives/sidebar";
import Togglesidebar from "../../directives/togglesidebar";
import Footer from "../../directives/footer";

export const Layout = () => {
  return (
    <div className="page">
      <div id="websidebar" className="">
        <Sidebar />
      </div>

      <div id="mobilesidebar" className="">
        <Togglesidebar />
      </div>
      <Header />
      <Outlet />
      <Footer />
    </div>
  );
};
