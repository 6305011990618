import { useQuery } from '@tanstack/react-query';
import {
  getCategoriesByDepartment,
  getDepartments,
  getRecommendationSubcategories,
  getSubcategoriesByCategory,
  insertRecommendationSubcategory,
  deleteRecommendationSubcategory,
} from '../../Action/user.action';
import DataTable from 'react-data-table-component';
import { tableStyles } from '../../config/tableStyles';
import { useState } from 'react';
import Modal from 'react-responsive-modal';
import { useForm } from 'react-hook-form';

export const ManualMode = () => {
  const [open, setOpen] = useState(false);
  const {
    data: subcategories,
    isLoading,
    isFetching,
    refetch,
  } = useQuery({
    queryKey: ['recommendationSubcategories'],
    queryFn: () => getRecommendationSubcategories().then((res) => res.data),
  });

  const { register, watch, setValue } = useForm({
    defaultValues: {
      department: '',
      category: '',
      subCategory: '',
    },
  });
  const chosenDepartment = watch('department');
  const chosenCat = watch('category');
  const chosenSubCat = watch('subCategory');
  const chosenCount = watch('count');

  const { data: departments } = useQuery({
    queryKey: [`departments`],
    queryFn: () => getDepartments().then((res) => res.data ?? []),
  });

  const { data: categories } = useQuery({
    queryKey: [`categories`, chosenDepartment],
    queryFn: () => getCategoriesByDepartment({ department_id: chosenDepartment }).then((res) => res.data ?? []),
    enabled: !!chosenDepartment,
  });
  const { data: subCategories } = useQuery({
    queryKey: [`subCategories`, chosenCat],
    queryFn: () => getSubcategoriesByCategory({ category_id: chosenCat }).then((res) => res.data ?? []),
    enabled: !!chosenCat,
  });

  const addRecommendationSubcategory = async (e) => {
    e.preventDefault();
    try {
      await insertRecommendationSubcategory({
        department_id: chosenDepartment,
        category_id: chosenCat,
        subcategory_id: chosenSubCat,
      });
      refetch();
    } catch (error) {
      console.log(error);
    } finally {
      setOpen(false);
    }
  };

  const deleteData = async (id) => {
    try {
      await deleteRecommendationSubcategory({ id });
      refetch();
    } catch (error) {
      console.log(error);
    } finally {
      setOpen(false);
    }
  };

  const columns = [
    {
      name: 'ID',
      selector: (row, index) => `${index + 1}`,
      sortable: true,
    },
    {
      name: 'Category',
      selector: (row) => row.category,
      sortable: true,
    },
    {
      name: 'Subcategory',
      selector: (row) => row.subcategory,
      sortable: true,
    },
    {
      name: 'Action',
      cell: (row) => {
        return (
          <div className="button_wrp">
            {/* <button onClick={() => updateRandomModeData(row.id)} className="btn btn-outline-primary">
              <i class="fa fa-pencil-square-o fa-xs" aria-hidden="true"></i>
            </button> */}
            <button onClick={() => deleteData(row.id)} className="btn btn-outline-danger">
              <i class="fa fa-trash fa-xs" aria-hidden="true" />
            </button>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          padding: '1rem',
          gap: '20px',
          border: '1px solid #ccc',
          width: '40%',
        }}
      >
        <DataTable
          columns={columns}
          data={subcategories ?? []}
          customStyles={tableStyles}
          noDataComponent={
            isFetching || isLoading
              ? 'Loading...'
              : subcategories && subcategories.length === 0
                ? 'No data in the department'
                : 'Choose a department to display data'
          }
        />

        <button
          type="button"
          onClick={() => {
            setOpen(true);
          }}
          className="ti-btn ti-btn-primary"
          style={{ float: 'right' }}
        >
          Add
        </button>
      </div>
      <Modal closeOnOverlayClick open={open} onClose={() => setOpen(false)} center>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '20px', width: '400px' }}>
          <label>Add</label>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <label>Select department</label>
            <select {...register('department')}>
              {departments &&
                departments.length > 0 &&
                departments.map((category) => (
                  <option key={category.id} value={category.id}>
                    {category.name}
                  </option>
                ))}
            </select>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <label>Select category</label>
            <select {...register('category')}>
              {categories &&
                categories.length > 0 &&
                categories.map((category) => (
                  <option key={category.id} value={category.id}>
                    {category.name}
                  </option>
                ))}
            </select>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <label>Select Subcategory</label>
            <select {...register('subCategory')}>
              {subCategories &&
                subCategories.length > 0 &&
                subCategories.map((subCategory) => (
                  <option key={subCategory.id} value={subCategory.id}>
                    {subCategory.name}
                  </option>
                ))}
            </select>
          </div>
          <button
            onClick={addRecommendationSubcategory}
            className="btn btn-primary"
            disabled={!chosenCat || !chosenSubCat}
          >
            Add
          </button>
        </div>
      </Modal>
    </>
  );
};
