import React, { useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useQuery, useMutation } from '@tanstack/react-query';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { getMainPageModes, changeMainPageMode } from '../../Action/user.action';
import { RandomMode } from './RandomMode';
import { ManualMode } from './ManualMode';
import { CategoryMode } from './CategoryMode';

export const MainProductType = () => {
  const { control, watch, setValue } = useForm({
    defaultValues: {
      type: '',
      department: '',
      category: '',
      subCategory: '',
      count: '',
      search_product: '',
      product: '',
    },
  });

  const chosenMode = watch('type');

  const { data: modes, isLoading: isModesLoading } = useQuery({
    queryKey: ['modes'],
    queryFn: () => getMainPageModes().then((res) => res.data),
  });

  const mutation = useMutation({
    mutationFn: changeMainPageMode,
  });

  useEffect(() => {
    if (modes && !chosenMode) {
      const activeMode = modes.find((mode) => mode.is_active);
      if (activeMode) {
        setValue('type', activeMode.id);
      }
    }
  }, [modes, chosenMode, setValue]);

  useEffect(() => {
    if (chosenMode) {
      mutation.mutate({ id: chosenMode });
    }
  }, [chosenMode]);

  if (isModesLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="content">
      <div className="main-content">
        <div className="radio-groups">
          <h3 className="mt-3">Modes</h3>
          <Controller
            name="type"
            control={control}
            render={({ field }) => (
              <>
                {modes?.map((mode) => (
                  <div key={mode.id} style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                    <input
                      type="radio"
                      checked={field.value === mode.id}
                      value={mode.id}
                      onChange={() => field.onChange(mode.id)}
                    />
                    {mode.id === 1 && 'Random Mode'}
                    {mode.id === 2 && 'Manual Mode'}
                    {mode.id === 3 && 'Selected Category'}
                  </div>
                ))}
              </>
            )}
          />
        </div>
        {chosenMode === 1 && (
          <div className="flex flex-col gap-[20px] mt-3 items-center">
            <h3>Random</h3>
            <div className="flex w-100 flex-col md:flex-row gap-[20px] items-start wrap justify-between">
              <RandomMode id={1} />
              <RandomMode id={2} />
              <RandomMode id={3} />
            </div>
          </div>
        )}
        {chosenMode === 2 && (
          <div className="flex flex-col gap-[20px] mt-3 items-center">
            <h3>Manual</h3>
            <div className="flex w-100 flex-col  md:flex-row gap-[20px] items-start wrap justify-between">
              <ManualMode id={1} />
              <ManualMode id={2} />
              <ManualMode id={3} />
            </div>
          </div>
        )}
        {chosenMode === 3 && (
          <div className="flex flex-col gap-[20px] mt-3 items-center">
            <h3>Category</h3>
            <div className="flex w-100 flex-col md:flex-row gap-[20px] items-start wrap justify-between">
              <CategoryMode id={1} />
              <CategoryMode id={2} />
              <CategoryMode id={3} />
            </div>
          </div>
        )}
      </div>
      <ToastContainer />
    </div>
  );
};
