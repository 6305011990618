import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import Swal from "sweetalert2";
import {
  getRegionListAction,
  insertRegionNameAction,
  updateRegionNameAction,
  updateRegionStatusAction,
  deleteRegionAction,
} from "../../Action/user.action";
import "react-responsive-modal/styles.css";
import "react-toastify/dist/ReactToastify.css";
import { Modal } from "react-responsive-modal";
import { ToastContainer, toast } from "react-toastify";
import config from "../../config/config";
import { Form } from "react-bootstrap";
import { tableStyles } from "../../config/tableStyles";
import { Loader } from "../UI/Loader";

const Region = () => {
  const [loader, setLoader] = useState(true);
  const [listing, setListing] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [open, setOpen] = useState(false);
  const [modalSecond, setModalSecond] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState({
    name: "",
    phoneCode: "",
    symbol: "",
    countryCode: "",
  });
  const [formData, setFormData] = useState({
    name: "",
    countryCode: "",
  });
  const [validationErrors, setValidationErrors] = useState({});
  const [imagePreviewForAdd, setImagePreviewForAdd] = useState(null);
  const [image1, setImage1] = useState("");
  const [imagePreview, setImagePreview] = useState(null);
  const [oldImagePreview, setOldImagePreviw] = useState(null);

  useEffect(() => {
    fetchBrandList();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSelectedCategory((old) => {
      return { ...old, [name]: value };
    });
  };

  const clearValidationError = (fieldName) => {
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      [fieldName]: undefined,
    }));
  };

  const handleImage = (e) => {
    setImage1(e.target.files);
    const previewURL = URL.createObjectURL(e.target.files[0]);
    setImagePreviewForAdd(previewURL);
  };

  const handleImageChange = (e) => {
    setImage1(e.target.files);
    const previewURL = URL.createObjectURL(e.target.files[0]);
    setImagePreview(previewURL);
    setOldImagePreviw("");
  };

  console.log("oldImagePreview", oldImagePreview);

  // Add category ----------------------------------------
  const addCategory = async (e) => {
    e.preventDefault();
    const errors = {};

    if (formData.name === "") {
      errors.name = "Region Name is required";
    }
    if (formData.phoneCode === "") {
      errors.phoneCode = "Currency is required";
    }
    if (formData.symbol === "") {
      errors.name = "Symbole is required";
    }
    if (formData.countryCode === "") {
      errors.phoneCode = "Country code is required";
    }
    if (image1 === "") {
      errors.image1 = "Image is required";
    }

    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
    } else {
      // Clear validation errors
      setValidationErrors({});
      try {
        console.log(image1[0]);
        let data = {
          ...formData,
          flag: image1[0],
        };
        console.log(data);

        const response = await insertRegionNameAction(data);
        if (response.success) {
          toast.success("Success !", {
            position: toast.POSITION.TOP_CENTER,
          });
          onCloseModal();
          fetchBrandList();
        } else {
          console.error("Failed to add category");
        }
      } catch (error) {
        console.error("An error occurred while adding the category:", error);
      }
    }
  };

  const StatusUpdate = async (id) => {
    let res = await updateRegionStatusAction({
      id: id,
    });
    if (res.success) {
      fetchBrandList();
    } else {
      Swal.fire("Failed!", res.msg, "error");
    }
  };

  const updateDeleteStatus = async (data) => {
    Swal.fire({
      title: "Are you sure?",
      text: `You want to Delete it!`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: `Yes, Delete it!`,
    }).then(async (result) => {
      if (result.isConfirmed) {
        let res = await deleteRegionAction({
          id: data.id,
        });
        if (res.success) {
          fetchBrandList();
        } else {
          Swal.fire("Failed!", res.msg, "error");
        }
      }
    });
  };

  // Update category  ---------------------------------
  const updateCategory = async (e) => {
    e.preventDefault();
    const errors = {};

    if (selectedCategory.name === "") {
      errors.name = "Region Name is required";
    }
    if (selectedCategory.phoneCode === "") {
      errors.phoneCode = "Currency is required";
    }
    if (selectedCategory.symbol === "") {
      errors.symbol = "Symbol is required"; // Corrected field name
    }
    if (selectedCategory.countryCode === "") {
      errors.countryCode = "Country code is required"; // Corrected field name
    }

    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
    } else {
      // Clear validation errors
      setValidationErrors({});
      console.log(formData);
      try {
        let data = {
          ...selectedCategory,
          id: selectedCategory.id, // Add the region ID to the data object
        };
        if (image1.length > 0) {
          data.flag = image1[0];
        }

        console.log(data);

        const response = await updateRegionNameAction(data);
        if (response.success) {
          toast.success("Success", {
            position: toast.POSITION.TOP_CENTER,
          });
          onCloseModalSecond();
          fetchBrandList();
        } else {
          console.error("Failed to update Region");
        }
      } catch (error) {
        console.error("An error occurred while updating the Region:", error);
      }
    }
  };

  // Get listing -------------------------------
  const fetchBrandList = async () => {
    try {
      setLoader(true);
      const res = await getRegionListAction();
      if (res.success) {
        setLoader(false);
        setListing(res.data);
      }
      setLoader(false);
    } catch (error) {
      console.error("An error occurred while getting the listing:", error);
    }
  };

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  const filteredData = listing.filter((item, index) => {
    item.index = index;
    item = Object.values(item).some((value) =>
      String(value).toLowerCase().includes(searchText.toLowerCase()),
    );
    return item;
  });

  const columns = [
    {
      name: "№",
      selector: (row) => `${row.index + 1}`,
      sortable: true,
    },
    {
      name: "Region Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Is Active",
      cell: (row) => {
        return (
          <Form>
            <Form.Check
              checked={row.isActive}
              onClick={() => StatusUpdate(row.id)}
            />
          </Form>
        );
      },
    },
    {
      name: "Country Code",
      selector: (row) => row.countryCode,
      sortable: true,
    },
    {
      name: "Flag ",
      text: "flag",
      cell: (item) => {
        return (
          <img
            style={{ width: "20%", borderColor: "black" }}
            src={process.env.REACT_APP_MEDIA_URL + item.flag}
            alt="image"
          />
        );
      },
    },
    {
      name: "Action",
      cell: (row) => {
        return (
          <div className="button_wrp">
            <button
              onClick={() => onOpenModalSecond(row)}
              className="btn btn-outline-primary"
            >
              <i class="fa fa-pencil-square-o fa-xs" aria-hidden="true"></i>
            </button>
            {row.status == 0 ? (
              <button
                onClick={() => StatusUpdate(row)}
                className="btn btn-outline-danger"
              >
                <i class="fa fa-times fa-s" aria-hidden="true"></i>
              </button>
            ) : row.status == 1 ? (
              <button
                onClick={() => StatusUpdate(row)}
                className="btn btn-success"
              >
                <i class="fa fa-check  fa-xs" aria-hidden="true"></i>
              </button>
            ) : (
              ""
            )}
            <button
              onClick={() => updateDeleteStatus(row)}
              className="btn btn-outline-danger"
            >
              <i class="fa fa-trash fa-xs" aria-hidden="true"></i>
            </button>
          </div>
        );
      },
    },
  ];

  // Modal code  ---------------------------------------
  const onOpenModalSecond = (category) => {
    setSelectedCategory(category);
    setModalSecond(true);

    setOldImagePreviw(category.flag);
  };

  const onCloseModalSecond = () => {
    setModalSecond(false);
    setOldImagePreviw(null);
    setImagePreview("");
  };

  const onOpenModal = () => setOpen(true);

  const onCloseModal = () => setOpen(false);

  return (
    <>
      <div className="content">
        <div className="main-content">
          <div className="block justify-between page-header md:flex">
            <div></div>
          </div>

          <div className="grid grid-cols-12 gap-6">
            <div className="col-span-12">
              <div className="box">
                <div className="box-header">
                  <h5 className="box-title font-normal">Region List</h5>
                  <button
                    type="button"
                    onClick={onOpenModal}
                    className="ti-btn btn-outline-primary"
                    style={{ float: "right", marginTop: "-35px" }}
                  >
                    Add
                  </button>
                </div>
                <div className="box-body">
                  <input
                    type="text"
                    value={searchText}
                    onChange={handleSearch}
                    placeholder="Search..."
                    className="px-2 py-1 border rounded mb-4 searchtype"
                  />
                  {!loader ? (
                    <div className="overflow-hidden table-bordered">
                      <DataTable
                        columns={columns}
                        data={filteredData}
                        pagination
                        paginationTotalRows={filteredData.length}
                        customStyles={tableStyles}
                      />
                    </div>
                  ) : (
                    <Loader />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Start::main-content */}
      </div>
      {/* First modal: Add category -------------- */}
      <Modal open={open} onClose={onCloseModal} center>
        <div className="form-group">
          <label className="mb-2 font-normal" htmlFor="categoryId">
            Add Region
          </label>
          <hr />
          <label className="" htmlFor="name">
            Region
          </label>
          <br />
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={(e) =>
              setFormData(
                { ...formData, name: e.target.value },
                clearValidationError("name"),
              )
            }
            className="form-control select"
            id="name"
            style={{ marginTop: "10px", marginBottom: "10px" }}
            placeholder="Region"
          />
          {validationErrors.name && (
            <span className="text-danger">{validationErrors.name}</span>
          )}
        </div>

        <div className="form-group">
          <label className="font-normal" htmlFor="phoneCode">
            Phone code
          </label>
          <br />

          <input
            type="text"
            name="phoneCode"
            value={formData.phoneCode}
            onChange={(e) =>
              setFormData(
                { ...formData, phoneCode: e.target.value },
                clearValidationError("phoneCode"),
              )
            }
            className="form-control select"
            id="phoneCode"
            style={{ marginTop: "10px", marginBottom: "10px" }}
            placeholder="phoneCode"
          />
          {validationErrors.phoneCode && (
            <span className="text-danger">{validationErrors.phoneCode}</span>
          )}
        </div>

        <div className="form-group">
          <label className="" htmlFor="countryCode">
            Country Code
          </label>
          <br />
          <input
            type="text"
            name="countryCode"
            value={formData.countryCode}
            onChange={(e) =>
              setFormData(
                { ...formData, countryCode: e.target.value },
                clearValidationError("countryCode"),
              )
            }
            className="form-control select"
            id="countryCode"
            style={{ marginTop: "10px", marginBottom: "10px" }}
            placeholder="Country Code"
          />
          {validationErrors.countryCode && (
            <span className="text-danger">{validationErrors.countryCode}</span>
          )}
        </div>

        <div className="form-group mb-3">
          <label className="mb-2" htmlFor="flag">
            Flag Image
          </label>
          <input
            accept="image/x-png,image/gif,image/jpeg"
            type="file"
            name="flag"
            onChange={(e) => {
              handleImage(e);
              clearValidationError("flag");
            }}
            className="file font-light"
          />
          {validationErrors.flag && (
            <span className="text-danger">{validationErrors.flag}</span>
          )}
          {imagePreviewForAdd && (
            <img
              src={imagePreviewForAdd}
              alt="Selected Image Preview"
              style={{
                marginTop: "10px",
                maxWidth: "100%",
                maxHeight: "150px",
              }}
            />
          )}
        </div>

        <button
          type="submit"
          onClick={addCategory}
          className="btn btn-outline-primary "
        >
          Add
        </button>
      </Modal>
      {/* Edit modal code --------- */}
      <Modal open={modalSecond} onClose={onCloseModalSecond} center>
        <label className="mb-2 font-normal" htmlFor="name">
          Edit Region
        </label>
        <hr />
        <div className="form-group mb-3">
          <label className="mb-2 font-normal" htmlFor="name">
            Region
          </label>
          <br />
          <input
            type="text"
            name="name"
            value={selectedCategory.name}
            onChange={(e) => {
              handleChange(e);
              clearValidationError("name");
            }}
            className="form-control select"
            id="name"
            placeholder="Region Name"
          />
          {validationErrors.name && (
            <span className="text-danger">{validationErrors.name}</span>
          )}
        </div>

        <div className="form-group mb-3">
          <label className="mb-2 font-normal" htmlFor="phoneCode">
            Phone Code
          </label>
          <input
            type="text"
            name="phoneCode"
            value={selectedCategory.phoneCode}
            onChange={(e) => {
              handleChange(e);
              clearValidationError("phoneCode");
            }}
            className="form-control select"
            id="phoneCode"
            placeholder="Currency"
          />
          {validationErrors.phoneCode && (
            <span className="text-danger">{validationErrors.phoneCode}</span>
          )}
        </div>

        <div className="form-group mb-3">
          <label className="mb-2 font-normal" htmlFor="countryCode">
            Country Code
          </label>
          <input
            type="text"
            name="countryCode"
            value={selectedCategory.countryCode}
            onChange={(e) => {
              handleChange(e);
              clearValidationError("countryCode");
            }}
            className="form-control select"
            id="countryCode"
            placeholder="countryCode"
          />
          {validationErrors.countryCode && (
            <span className="text-danger">{validationErrors.countryCode}</span>
          )}
        </div>

        <div className="form-group mb-3">
          <label className="mb-2 font-normal" htmlFor="flag">
            Flag Image
          </label>
          <input
            accept="image/x-png,image/gif,image/jpeg"
            type="file"
            name="flag"
            onChange={(e) => {
              handleImageChange(e);
              clearValidationError("flag");
            }}
            className="file font-light"
          />
          {validationErrors.flag && (
            <span className="text-danger">{validationErrors.flag}</span>
          )}
          {oldImagePreview && (
            <img
              src={`${config.flgImageUrl + oldImagePreview}`}
              alt="Image Preview"
              style={{
                marginTop: "10px",
                maxWidth: "100%",
                maxHeight: "150px",
              }}
            />
          )}
          {imagePreview && (
            <img
              src={imagePreview}
              alt="Selected Image Preview"
              style={{
                marginTop: "10px",
                maxWidth: "100%",
                maxHeight: "150px",
              }}
            />
          )}
        </div>
        <button
          type="submit"
          onClick={updateCategory}
          className="btn btn-primary "
        >
          Update
        </button>
      </Modal>
      <ToastContainer />
    </>
  );
};

export default Region;
