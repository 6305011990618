import { useQuery } from '@tanstack/react-query';
import Modal from 'react-responsive-modal';
import {
  getCategoriesByDepartment,
  getCategoryBySubDepartment,
  getSubDepartmentsByDepartment,
  setBanner,
} from '../../Action/user.action';
import { useForm } from 'react-hook-form';
import Cookies from 'js-cookie';
import axios from 'axios';
import { useEffect, useState } from 'react';

export const AddDepModal = ({ setOpenModal, openModal, departments, refetchBanners }) => {
  const loginData = !Cookies.get('loginSuccessFarfetchAdmin')
    ? []
    : JSON.parse(Cookies.get('loginSuccessFarfetchAdmin'));
  const {
    register,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      department_id: '',
      category_id: '',
      sub_department_id: '',
      file: null,
      title: '',
      title_ru: '',
      opacity: '',
    },
  });

  const selectedDep = watch('department_id');
  const selecetedSubDep = watch('sub_department_id');

  const { data: categories } = useQuery({
    queryKey: ['categories-add', selectedDep],
    queryFn: () => getCategoriesByDepartment({ department_id: selectedDep }).then((res) => res.data ?? []),
    enabled: !!selectedDep && selectedDep !== '3',
  });

  const { data: sub_departments } = useQuery({
    queryKey: ['subdep-add', selectedDep],
    queryFn: () => getSubDepartmentsByDepartment({ department_id: selectedDep }).then((res) => res.data ?? []),
    enabled: !!selectedDep && selectedDep === '3',
  });

  const { data: categories_s } = useQuery({
    queryKey: ['categories_s-add', selecetedSubDep],
    queryFn: () => getCategoryBySubDepartment({ sub_department_id: selecetedSubDep }).then((res) => res.data ?? []),
    enabled: !!selecetedSubDep,
  });

  const onSubmit = async (data) => {
    const formData = new FormData();
    formData.append('department_id', data.department_id);
    formData.append('category_id', data.category_id);
    if (data.sub_department_id && selectedDep === '3') {
      formData.append('sub_department_id', data.sub_department_id);
    }
    formData.append('banner', data.file[0]);
    formData.append('title', data.title);
    formData.append('title_ru', data.title_ru);
    formData.append('opacity', data.opacity);
    try {
      await axios
        .post(`${process.env.REACT_APP_API_URL}/setBanner`, formData, {
          headers: {
            Authorization: loginData?.authToken,
          },
        })
        .then((res) => {
          refetchBanners();
          setOpenModal(false);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Modal open={openModal} onClose={() => setOpenModal(false)} center>
      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{ display: 'flex', flexDirection: 'column', gap: '20px', width: '400px' }}
      >
        <label>Add new banner</label>
        <div className="flex flex-col gap-3">
          {/* File Input with Validation */}
          <div className="flex flex-col gap-1">
            <label>Select file</label>
            <input
              accept=".webp"
              type="file"
              {...register('file', {
                required: 'File is required',
                validate: {
                  fileFormat: (value) => value[0]?.type === 'image/webp' || 'Only .webp files are allowed',
                },
              })}
            />
            {errors.file && <p className="text-red-500">{errors.file.message}</p>}
          </div>

          {/* Department Select with Validation */}
          <div className="flex flex-col gap-1">
            <label>Select department</label>
            <select
              {...register('department_id', {
                required: 'Department is required',
                validate: (value) => value !== 'null' || 'Please select a department',
              })}
            >
              <option value="null">Select department</option>
              {departments?.map((dep) => (
                <option key={dep.id} value={dep.id}>
                  {dep.name}
                </option>
              ))}
            </select>
            {errors.department_id && <p className="text-red-500">{errors.department_id.message}</p>}
          </div>

          {/* Category Select with Validation */}
          {selectedDep !== '3' ? (
            <div className="flex flex-col gap-1">
              <label>Select category</label>
              <select
                {...register('category_id', {
                  required: 'Category is required',
                  validate: (value) => value !== 'null' || 'Please select a category',
                })}
              >
                <option value="null">Select category</option>
                {categories?.map((cat) => (
                  <option key={cat.id} value={cat.id}>
                    {cat.name}
                  </option>
                ))}
              </select>
              {errors.category_id && <p className="text-red-500">{errors.category_id.message}</p>}
            </div>
          ) : (
            <>
              <div className="flex flex-col gap-1">
                <label>Select sub-department</label>
                <select
                  {...register('sub_department_id', {
                    required: 'Sub Department is required',
                    validate: (value) => value !== 'null' || 'Please select a sub department',
                  })}
                >
                  <option value="null">Select sub department</option>
                  {sub_departments?.map((cat) => (
                    <option key={cat.id} value={cat.id}>
                      {cat.name}
                    </option>
                  ))}
                </select>
                {errors.category_id && <p className="text-red-500">{errors.category_id.message}</p>}
              </div>
              <div className="flex flex-col gap-1">
                <label>Select Category</label>
                <select
                  {...register('category_id', {
                    required: 'category is required',
                    validate: (value) => value !== 'null' || 'Please select a category',
                  })}
                >
                  <option value="null">Select category</option>
                  {categories_s?.map((cat) => (
                    <option key={cat.id} value={cat.id}>
                      {cat.name}
                    </option>
                  ))}
                </select>
                {errors.category_id && <p className="text-red-500">{errors.category_id.message}</p>}
              </div>
            </>
          )}

          {/* Title Input with Validation */}
          <div className="flex flex-col gap-1">
            <label>Title</label>
            <input className="form-control" {...register('title', { required: 'Title is required' })} />
            {errors.title && <p className="text-red-500">{errors.title.message}</p>}
          </div>

          {/* Title (RU) Input without Validation */}
          <div className="flex flex-col gap-1">
            <label>Title ru</label>
            <input className="form-control" {...register('title_ru')} />
          </div>

          <div className="flex flex-col gap-1">
            <label>Opacity</label>
            <input className="form-control" {...register('opacity', { required: 'Opacity is required' })} />
            {errors.opacity && <p className="text-red-500">{errors.opacity.message}</p>}
          </div>

          {/* Submit Button */}
          <button type="submit" className="btn btn-primary">
            Submit
          </button>
        </div>
      </form>
    </Modal>
  );
};
