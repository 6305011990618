import React, { useState, useEffect } from "react";
import "react-responsive-modal/styles.css";
import "react-toastify/dist/ReactToastify.css";
import Sidebar from "../../directives/sidebar";
import Header from "../../directives/header";
import Footer from "../../directives/footer";
import toast, { Toaster } from "react-hot-toast";
import { baseurl } from "../../config/config";
import {
  getCategoryAction,
  getSubCategoryAction,
  getInnerCategoryAction,
  getProductTypeListAction,
  getBrandListAction,
  addProductAction,
  getProductSizeListAction,
  getProductByIdAndColorProductIdAction,
  getColorListForProduct,
} from "../../Action/user.action";
import Togglesidebar from "../../directives/togglesidebar";

const AddProduct = () => {
  const [form, setForm] = useState({
    name: "",
    name: "",
    description: "",
    description_ru: "",
    baseValue: "",
    colorId: "",
    categoryId: "",
    subcategoryId: "",
    departmentId: "",
    productTypeId: "",
    brandId: "",
    isSizeAvailable: "",
    productQuantity: "",
  });

  const [color, setColor] = useState([]);
  const [category, setCategory] = useState([]);
  const [subCategory, setSubCategory] = useState([]);
  const [innerCategory, setInnerCategory] = useState([]);
  const [productType, setProductType] = useState([]);
  const [product, setProduct] = useState([]);
  const [brand, setBrand] = useState([]);
  const [sizes, setSize] = useState([]);
  const [categorySizes, setcategorySizes] = useState([]);
  const [defaultQty, setdefaultQty] = useState(false);
  const [qtyBasedOnSize, setqtyBasedOnSize] = useState(false);
  const [isSizeAvailable, setIsSizeAvailable] = useState("");
  const [isSubmitting, setisSubmitting] = useState(false);
  const [validationErrors, setValidationErrors] = useState({
    name: "",
    nameRu: "",
    baseValue: "",
    image1: "",
    categoryId: "",
    subcategoryId: "",
    departmentId: "",
    productTypeId: "",
    brandId: "",
  });
  const [image1, setImage1] = useState([]);
  const [image2, setImage2] = useState([]);
  const [image3, setImage3] = useState([]);
  const [image4, setImage4] = useState([]);
  const [image5, setImage5] = useState([]);
  const [imagePreviews, setImagePreviews] = useState({
    image1: null,
    image2: null,
    image3: null,
    image4: null,
    image5: null,
  });
  const handleQuantityType = (type) => {
    if (type === "0") {
      setIsSizeAvailable("0");
      setdefaultQty(true);
      setqtyBasedOnSize(false);
    } else if (type === "1") {
      setIsSizeAvailable("1");
      setdefaultQty(false);
      setqtyBasedOnSize(true);
    }
    validationErrors.isSizeAvailableErr = "";
  };

  const inputHandler = async (e) => {
    const { name, value } = e.target;
    setForm((old) => {
      return { ...old, [name]: value };
    });

    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      [name]: value.trim() === "" ? `` : "",
    }));
  };

  useEffect(() => {
    fetchCategoryList();
    fetchSubCategoryList();
    fetchInnerCategoryList();
    fetchProductTypeList();
    fetchBrandsList();
    fetchProductSizeList();
    // fetchProductList();
    fetchColorList();
  }, []);

  useEffect(() => {
    setcategorySizes(sizes.filter((o) => o.categoryId == form.categoryId));
    if (form.subCategoryId != "") {
      setcategorySizes(
        sizes.filter((o) => o.subCategoryId == form.subCategoryId),
      );
    }
  }, [form.categoryId, form.subCategoryId]);

  useEffect(() => {
    const savedFormData = JSON.parse(
      localStorage.getItem("addProductFormData"),
    );
    if (savedFormData) {
      setForm(savedFormData.form);
    }
  }, []);

  // Save form data to local storage whenever form state changes
  useEffect(() => {
    const formDataToSave = {
      form,
    };
    localStorage.setItem("addProductFormData", JSON.stringify(formDataToSave));
  }, [form]);

  const fetchCategoryList = async () => {
    try {
      const res = await getCategoryAction();
      if (res.success) {
        setCategory(res.data.filter((item) => item.isActive == 1));
      }
    } catch (error) {
      console.error("An error occurred while getting Category:", error);
    }
  };

  const fetchSubCategoryList = async () => {
    try {
      const res = await getSubCategoryAction();
      if (res.success) {
        setSubCategory(res.data.filter((item) => item.isActive == 1));
      }
    } catch (error) {
      console.error("An error occurred while getting Category:", error);
    }
  };

  const fetchInnerCategoryList = async () => {
    try {
      const res = await getInnerCategoryAction();
      if (res.success) {
        setInnerCategory(res.data.filter((item) => item.isActive == 1));
      }
    } catch (error) {
      console.error("An error occurred while getting Category:", error);
    }
  };

  const fetchProductTypeList = async () => {
    try {
      const res = await getProductTypeListAction();
      if (res.success) {
        setProductType(res.data.productTypes.filter((item) => item.isActive));
      }
    } catch (error) {
      console.error("An error occurred while getting Category:", error);
    }
  };

  const fetchBrandsList = async () => {
    try {
      const res = await getBrandListAction();
      if (res.success) {
        setBrand(res.data.filter((item) => item.isActive == 1));
      }
    } catch (error) {
      console.error("An error occurred while getting Category:", error);
    }
  };

  const fetchColorList = async () => {
    try {
      const res = await getColorListForProduct();
      if (res.success) {
        setColor(res.data);
      }
    } catch (error) {
      console.error("An error occurred while getting Category:", error);
    }
  };

  const fetchProductSizeList = async () => {
    try {
      const res = await getProductSizeListAction();
      if (res.success) {
        setSize(res.data);
      }
    } catch (error) {
      console.error("An error occurred while getting Category:", error);
    }
  };

  // const fetchProductList = async () => {
  //   try {
  //     const res = await getProductByIdAndColorProductIdAction();
  //     if (res.success) {
  //       let data = res.data;
  //       setProduct(data);
  //     }
  //   } catch (error) {
  //     console.error("An error occurred while getting Category:", error);
  //   }
  // };

  const [imgDimentionErr, setimgDimentionErr] = useState("");
  const handleImageChange = (e, i) => {
    const selectedImages = e.target.files;
    if (selectedImages.length > 0) {
      const image = selectedImages[0];
      validateImageDimensions(image, selectedImages, i);
    }
  };

  const validateImageDimensions = (image, selectedImages, i) => {
    const img = new Image();
    img.src = URL.createObjectURL(image);

    img.onload = () => {
      // if (img.width !== 480 || img.height !== 640) {
      //   setValidationErrors((prevErrors) => ({
      //     ...prevErrors,
      //     [`image${i}`]: "Width should be 480 and height should be 640 pixels.",
      //   }));
      //   setimgDimentionErr(
      //     "Width should be 480 and height should be 640 pixels.",
      //   );
      //   return;
      // } else {
      //   setValidationErrors((prevErrors) => ({
      //     ...prevErrors,
      //     [`image${i}`]: "", // Clear dimension error if dimensions are correct
      //   }));
      //   setimgDimentionErr("");
      // }

      // If all checks pass, set the image state
      switch (i) {
        case 1:
          setImage1(selectedImages);
          previewImage(selectedImages[0], "image1");
          break;
        case 2:
          setImage2(selectedImages);
          previewImage(selectedImages[0], "image2");
          break;
        case 3:
          setImage3(selectedImages);
          previewImage(selectedImages[0], "image3");
          break;
        case 4:
          setImage4(selectedImages);
          previewImage(selectedImages[0], "image4");
          break;
        case 5:
          setImage5(selectedImages);
          previewImage(selectedImages[0], "image5");
          break;
        default:
          break;
      }
    };
  };

  const previewImage = (file, key) => {
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreviews((prevPreviews) => ({
          ...prevPreviews,
          [key]: reader.result,
        }));
      };
      reader.readAsDataURL(file);
    }
    //   setValidationErrors((prevErrors) => ({
    //     ...prevErrors,
    //     [selectedImages]: image1.trim() === "" ? `${selectedImages} is required` : "",
    // }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    localStorage.removeItem("addProductFormData");
    setisSubmitting(true);
    if (!validateForm()) {
      setisSubmitting(false);
      return;
    }
    let qtySizeObj = [];
    if (isSizeAvailable == 1) {
      const categorySizesId = categorySizes.map(({ id }) => id);
      let totalQty = 0;
      categorySizesId.map((id) => {
        let obj = {
          sizeId: id,
          quantity: document.getElementById("qtyInput_" + id).value,
          onSizePrice: document.getElementById("amtInput_" + id).value,
        };
        obj.onSizePrice = Math.abs(form.price - obj.onSizePrice);

        qtySizeObj.push(obj);
        if (obj.quantity !== "") {
          totalQty += parseInt(obj.quantity);
        }
      });
      form.quantity = totalQty;
    }

    let data = {
      ...form,
      image1: image1[0],
      image2: image2[0] ? image2[0] : [],
      image3: image3[0] ? image3[0] : [],
      image4: image4[0] ? image4[0] : [],
      image5: image5[0] ? image5[0] : [],
      isSizeAvailable: isSizeAvailable,
      qtySizeObj: JSON.stringify(qtySizeObj),
    };

    const res = await addProductAction(data);
    if (res.success) {
      toast.success(res.msg);
      setTimeout(() => {
        window.location.href = `${baseurl}productList`;
      }, 1200);
    } else {
      toast.error(res.msg);
    }
  };

  const validateForm = () => {
    let isValid = true;
    const errors = {
      name: "",
      nameRu: "",
      baseValue: "",
      image1: "",
      categoryId: "",
      subcategoryId: "",
      departmentId: "",
      productTypeId: "",
      brandId: "",
    };

    if (form.name === "") {
      errors.name = "Product name is required";
      isValid = false;
    }

    if (form.nameRu === "") {
      errors.nameRu = "Product name is required";
      isValid = false;
    }

    if (form.baseValue === "") {
      errors.baseValue = "Price is required";
      isValid = false;
    }

    if (image1.length === 0) {
      if (imgDimentionErr != "") {
        errors.image1 = imgDimentionErr;
      } else {
        errors.image1 = "Image is required";
      }
      isValid = false;
    }

    if (form.categoryId === "") {
      errors.categoryId = "Category is required";
      isValid = false;
    }

    if (form.subcategoryId === "") {
      errors.subcategoryId = "Sub category is required";
      isValid = false;
    }

    if (form.departmentId === "") {
      errors.departmentId = "Inner category is required";
      isValid = false;
    }

    if (form.productTypeId === "") {
      errors.productTypeId = "Product type is required";
      isValid = false;
    }

    // if (form.brandId === "") {
    //   errors.brandId = "Brand name is required";
    //   isValid = false;
    // }
    if (isSizeAvailable == "") {
      errors.isSizeAvailableErr = "Quantity type is required";
      isValid = false;
    }

    setValidationErrors(errors);
    if (!isValid) {
      window.scrollTo(0, 0);
    }
    return isValid;
  };

  return (
    <>
      <div className="page">
        <div id="websidebar" className="">
          <Sidebar />
        </div>

        <div id="mobilesidebar" className="">
          <Togglesidebar />
        </div>
        <Header />
        <Toaster />
        <div className="content">
          <div className="main-content">
            <div className="block justify-between page-header md:flex"></div>
            <div className="grid grid-cols-12 gap-x-6">
              <div className="col-span-12">
                <div className="box">
                  <div className="box-header">
                    <h5 className="box-title">Add Product</h5>
                  </div>
                  <div className="box-body">
                    <form onSubmit={handleSubmit}>
                      <div className="space-y-4">
                        <div className="space-y-2">
                          <label className="ti-form-label mb-2">
                            Product Name
                          </label>
                          <input
                            type="text"
                            className="my-auto ti-form-input"
                            name="name"
                            placeholder="Product Name (EN)"
                            value={form.name}
                            onChange={inputHandler}
                          />
                          <span className="text-red-500 text-sm">
                            {validationErrors.name}
                          </span>
                        </div>

                        <div className="space-y-2">
                          <input
                            type="text"
                            className="my-auto ti-form-input"
                            name="nameRu"
                            placeholder="Product Name (RU)"
                            value={form.nameRu}
                            onChange={inputHandler}
                          />
                          <span className="text-red-500 text-sm">
                            {validationErrors.name}
                          </span>
                        </div>

                        <div className="space-y-2">
                          <label className="ti-form-label mb-2">
                            Description
                          </label>
                          <textarea
                            className="my-auto ti-form-input"
                            placeholder="Description"
                            name="description"
                            value={form.description}
                            onChange={inputHandler}
                          ></textarea>
                        </div>

                        <div className="space-y-2">
                          <textarea
                            className="my-auto ti-form-input"
                            placeholder="Description (RU)"
                            name="description_ru"
                            value={form.descriptionRu}
                            onChange={inputHandler}
                          ></textarea>
                        </div>

                        <div className="space-y-2">
                          <label className="ti-form-label mb-2">
                            Price (USD)
                          </label>
                          <input
                            type="number"
                            className="my-auto ti-form-input"
                            placeholder="Price"
                            name="baseValue"
                            value={form.baseValue}
                            min={0}
                            onChange={inputHandler}
                          />
                          <span className="text-red-500 text-sm">
                            {validationErrors.baseValue}
                          </span>
                        </div>

                        <div className="space-y-2">
                          <label className="ti-form-label mb-2">
                            Department Name
                          </label>
                          <select
                            className="my-auto ti-form-select"
                            name="departmentId"
                            onChange={inputHandler}
                          >
                            <option value="">Select Department</option>
                            {category.map((item) => (
                              <option key={item.id} value={item.id}>
                                {item.name}
                              </option>
                            ))}
                          </select>
                          <span className="text-red-500 text-sm">
                            {validationErrors.departmentId}
                          </span>
                        </div>

                        {form.departmentId !== "" ? (
                          <div className="space-y-2">
                            <label className="ti-form-label mb-0">
                              Category Name
                            </label>
                            <select
                              className="my-auto ti-form-select"
                              name="categoryId"
                              onChange={inputHandler}
                            >
                              <option value="">Select Category</option>
                              {subCategory
                                .filter(
                                  (item) => item.departmentId == form.departmentId,
                                )
                                .map((item) => (
                                  <option key={item.id} value={item.id}>
                                    {item.name}
                                  </option>
                                ))}
                            </select>
                            <span className="text-red-500 text-sm">
                              {validationErrors.categoryId}
                            </span>
                          </div>
                        ) : (
                          ""
                        )}

                        {form.categoryId !== "" ? (
                          <div className="space-y-2">
                            <label className="ti-form-label mb-0">
                              Sub Category Name
                            </label>
                            <select
                              className="my-auto ti-form-select"
                              name="subcategoryId"
                              onChange={inputHandler}
                            >
                              <option value="">Select Sub Category</option>
                              {innerCategory
                                .filter(
                                  (item) =>
                                    item.categoryId == form.categoryId,
                                )
                                .map((item) => (
                                  <option key={item.id} value={item.id}>
                                    {item.name}
                                  </option>
                                ))}
                            </select>
                            <span className="text-red-500 text-sm">
                              {validationErrors.subcategoryId}
                            </span>
                          </div>
                        ) : (
                          ""
                        )}

                        {form.subcategoryId !== "" ? (
                          <div className="space-y-2">
                            <label className="ti-form-label mb-0">
                              Product Type Name
                            </label>
                            <select
                              className="my-auto ti-form-select"
                              name="productTypeId"
                              onChange={inputHandler}
                            >
                              <option value="">Select Product Type Name</option>

                              {productType?.map((item) => (
                                  <option key={item.id} value={item.id}>
                                    {item.name}
                                  </option>
                                ))}
                            </select>
                            <span className="text-red-500 text-sm">
                              {validationErrors.productTypeId}
                            </span>
                          </div>
                        ) : (
                          ""
                        )}

                        <div className="space-y-2">
                          <label className="ti-form-label mb-2">
                            Brand Name
                          </label>
                          <select
                            className="my-auto ti-form-select"
                            name="brandId"
                            onChange={inputHandler}
                          >
                            <option value="">Select Brand Name</option>
                            {brand
                              .map((item) => (
                                <option key={item.id} value={item.id}>
                                  {item.name}
                                </option>
                              ))}
                          </select>
                          <span className="text-red-500 text-sm">
                            {validationErrors.brandId}
                          </span>
                        </div>

                        {/* <div className="space-y-2">
                          <label className="ti-form-label mb-2">
                            Color
                          </label>
                          <input
                            type="text"
                            className="my-auto ti-form-input"
                            placeholder="Color"
                            name="color"
                            value={form.color}
                            onChange={inputHandler}
                          />
                        </div> */}

                        <div className="space-y-2">
                          <label className="ti-form-label mb-2">
                            Color Name
                          </label>
                          <select
                            className="my-auto ti-form-select"
                            name="colorId"
                            onChange={inputHandler}
                          >
                            <option value="">Select Color Name</option>
                            {color.map((item) => (
                              <option key={item.id} value={item.id}>
                                {item.name}
                              </option>
                            ))}
                          </select>
                          <span className="text-red-500 text-sm">
                            {validationErrors.colorId}
                          </span>
                        </div>

                        {/* <div className="space-y-2">
                          <label className="ti-form-label mb-0">
                            Color of Product
                          </label>
                          <select
                            className="my-auto ti-form-select"
                            name="colorProductId"
                            onChange={inputHandler}
                          >
                            <option value="">Select color of product</option>
                            {product
                              .filter(
                                (item) =>
                                  item.subCategoryId == form.subCategoryId,
                              )
                              .map((item) => (
                                <option key={item.id} value={item.id}>
                                  {item.productName}
                                </option>
                              ))}
                          </select>
                        </div> */}

                        <div className="space-y-2">
                          <label className="ti-form-label mb-2">
                            Image1* (480*640 pixels)
                          </label>
                          <input
                            accept="image/x-png,image/gif,image/jpeg,image/webp"
                            type="file"
                            name="image"
                            className="my-auto ti-form-input"
                            onChange={(e) => handleImageChange(e, 1)}
                          />

                          {imagePreviews.image1 && (
                            <>
                              <img
                                src={imagePreviews.image1}
                                alt="Image Preview"
                                style={{
                                  maxWidth: "100px",
                                  maxHeight: "100px",
                                  marginTop: "10px",
                                }}
                              />
                            </>
                          )}
                          <span className="text-red-500 text-sm">
                            {validationErrors.image1}
                          </span>
                        </div>

                        <div className="space-y-2">
                          <label className="ti-form-label mb-2">
                            Image 2 (480*640 pixels)
                          </label>
                          <input
                            accept="image/x-png,image/gif,image/jpeg,image/webp"
                            type="file"
                            name="image"
                            className="my-auto ti-form-input"
                            onChange={(e) => handleImageChange(e, 2)}
                          />
                          {imagePreviews.image2 && (
                            <>
                              <img
                                src={imagePreviews.image2}
                                alt="Image Preview"
                                style={{
                                  maxWidth: "100px",
                                  maxHeight: "100px",
                                  marginTop: "10px",
                                }}
                              />
                            </>
                          )}
                          <span className="text-red-500 text-sm">
                            {validationErrors.image2}
                          </span>
                        </div>

                        <div className="space-y-2">
                          <label className="ti-form-label mb-2">
                            Image 3 (480*640 pixels)
                          </label>
                          <input
                            accept="image/x-png,image/gif,image/jpeg,image/webp"
                            type="file"
                            name="image"
                            className="my-auto ti-form-input"
                            onChange={(e) => handleImageChange(e, 3)}
                          />
                          {imagePreviews.image3 && (
                            <>
                              <img
                                src={imagePreviews.image3}
                                alt="Image Preview"
                                style={{
                                  maxWidth: "100px",
                                  maxHeight: "100px",
                                  marginTop: "10px",
                                }}
                              />
                            </>
                          )}
                          <span className="text-red-500 text-sm">
                            {validationErrors.image3}
                          </span>
                        </div>

                        <div className="space-y-2">
                          <label className="ti-form-label mb-2">
                            Image 4 (480*640 pixels)
                          </label>
                          <input
                            accept="image/x-png,image/gif,image/jpeg,image/webp"
                            type="file"
                            name="image"
                            className="my-auto ti-form-input"
                            onChange={(e) => handleImageChange(e, 4)}
                          />
                          {imagePreviews.image4 && (
                            <img
                              src={imagePreviews.image4}
                              alt="Image Preview"
                              style={{
                                maxWidth: "100px",
                                maxHeight: "100px",
                                marginTop: "10px",
                              }}
                            />
                          )}
                          <span className="text-red-500 text-sm">
                            {validationErrors.image4}
                          </span>
                        </div>

                        <div className="space-y-2">
                          <label className="ti-form-label mb-2">
                            Image 5 (480*640 pixels)
                          </label>
                          <input
                            accept="image/x-png,image/gif,image/jpeg,image/webp"
                            type="file"
                            name="image"
                            className="my-auto ti-form-input"
                            onChange={(e) => handleImageChange(e, 5)}
                          />
                          {imagePreviews.image5 && (
                            <img
                              src={imagePreviews.image5}
                              alt="Image Preview"
                              style={{
                                maxWidth: "100px",
                                maxHeight: "100px",
                                marginTop: "10px",
                              }}
                            />
                          )}
                          <span className="text-red-500 text-sm">
                            {validationErrors.image5}
                          </span>
                        </div>

                        {/* Sizes section start */}
                        <div className="space-y-1">
                          <label className="ti-form-label mb-2">
                            Quantity Type
                          </label>
                          <select
                            className="my-auto ti-form-select"
                            name="isSizeAvailable"
                            onChange={(e) => handleQuantityType(e.target.value)}
                            value={isSizeAvailable}
                          >
                            <option value="" disabled>
                              Select Quantity Type
                            </option>
                            <option value="0">
                              Only available in single size
                            </option>
                            <option value="1">Quantity based on sizes</option>
                          </select>
                          <span className="text-red-500 text-sm">
                            {validationErrors.isSizeAvailableErr}
                          </span>
                        </div>
                        {defaultQty ? (
                          <div className="space-y-2">
                            <label className="ti-form-label mb-0">
                              Quantity
                            </label>
                            <input
                              type="number"
                              className="my-auto ti-form-input"
                              name="productQuantity"
                              placeholder="Product Quantity"
                              value={form.productQuantity}
                              onChange={inputHandler}
                            />
                          </div>
                        ) : (
                          ""
                        )}

                        {qtyBasedOnSize ? (
                          <div className="space-y-2">
                            <label className="ti-form-label mb-0">
                              Add quantity based on size
                            </label>
                            <div className="table-responsive">
                              <table className="table table-bordered">
                                <thead>
                                  <tr>
                                    <th>Size Name</th>
                                    <th>Quantity</th>
                                    <th>On Size Price</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {form.categoryId != "" ? (
                                    categorySizes.map((item) => (
                                      <tr>
                                        <td>
                                          <input
                                            type="text"
                                            className="my-auto ti-form-input"
                                            placeholder="Size Name"
                                            readOnly
                                            value={item.name}
                                          />
                                        </td>
                                        <td>
                                          <input
                                            type="text"
                                            className="my-auto ti-form-input"
                                            placeholder="0"
                                            id={`qtyInput_${item.id}`}
                                          />
                                        </td>
                                        <td>
                                          <input
                                            type="number"
                                            className="my-auto ti-form-input"
                                            placeholder="0"
                                            id={`amtInput_${item.id}`}
                                          />
                                        </td>
                                      </tr>
                                    ))
                                  ) : (
                                    <tr>
                                      <td colSpan={3}>
                                        <center>
                                          Please select <b>category</b> or{" "}
                                          <b>sub-category</b> first.
                                        </center>
                                      </td>
                                    </tr>
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}

                        {/* Sizes section end */}

                        {/* <div className="space-y-2">
                          <label className="ti-form-label mb-2">
                            Composition
                          </label>
                          <textarea
                            className="my-auto ti-form-input"
                            placeholder="composition (EN)"
                            name="composition"
                            value={form.composition}
                            onChange={inputHandler}
                          ></textarea>
                        </div> */}

                        {/* <div className="space-y-2">
                          <textarea
                            className="my-auto ti-form-input"
                            placeholder="composition (RU)"
                            name="composition_ru"
                            value={form.composition_ru}
                            onChange={inputHandler}
                          ></textarea>
                        </div>

                        <div className="space-y-2">
                          <label className="ti-form-label mb-2">
                            Highlights
                          </label>
                          <textarea
                            className="my-auto ti-form-input"
                            placeholder="Highlights (EN)"
                            name="highlights"
                            value={form.highlights}
                            onChange={inputHandler}
                          ></textarea>
                        </div> */}

                        {/* <div className="space-y-2">
                          <textarea
                            className="my-auto ti-form-input"
                            placeholder="Highlights (RU)"
                            name="highlights_ru"
                            value={form.highlights_ru}
                            onChange={inputHandler}
                          ></textarea>
                        </div>

                        <div className="space-y-2">
                          <label className="ti-form-label mb-2">Wearing </label>
                          <textarea
                            className="my-auto ti-form-input"
                            placeholder="Wearing (EN)"
                            name="wearing"
                            value={form.wearing}
                            onChange={inputHandler}
                          ></textarea>
                        </div>

                        <div className="space-y-2">
                          <textarea
                            className="my-auto ti-form-input"
                            placeholder="Wearing (RU)"
                            name="wearing_ru"
                            value={form.wearing_ru}
                            onChange={inputHandler}
                          ></textarea>
                        </div> */}

                        {/* <div className="space-y-2">
                          <label className="ti-form-label mb-2">
                            {" "}
                            Washing Instruction
                          </label>
                          <textarea
                            className="my-auto ti-form-input"
                            placeholder="Washing Instruction (EN)"
                            name="washingInstruction"
                            value={form.washingInstruction}
                            onChange={inputHandler}
                          ></textarea>
                        </div>

                        <div className="space-y-2">
                          <textarea
                            className="my-auto ti-form-input"
                            placeholder="Washing Instruction (RU)"
                            name="washingInstruction_ru"
                            value={form.washingInstruction_ru}
                            onChange={inputHandler}
                          ></textarea>
                        </div> */}
                      </div>
                      {/* {isSubmitting ?
                        <button type="button" disabled className="ti-btn ti-btn-primary">
                          Please Wait ...
                        </button>
                        : */}
                      <button
                        type="submit"
                        className="ti-btn ti-btn-primary mt-3"
                      >
                        Submit
                      </button>
                      {/* } */}
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default AddProduct;
