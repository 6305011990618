import React, { useEffect, useState } from "react";
import { Modal } from "react-responsive-modal";
import { ToastContainer, toast } from "react-toastify";
import DataTable from "react-data-table-component";
import {
  updateProductSizeAction,
  addProductSizeAction,
  updateSizeStatusAction,
  getProductSizeListAction,
  deleteSizesAction,
} from "../../Action/user.action";
import Swal from "sweetalert2";
import "react-responsive-modal/styles.css";
import "react-toastify/dist/ReactToastify.css";
import { tableStyles } from "../../config/tableStyles";
import { Loader } from "../UI/Loader";

const Size = () => {
  const [loader, setLoader] = useState(true);
  const [sublisting, setSubListing] = useState([]);
  const [open, setOpen] = useState(false);
  const [modalSecond, setModalSecond] = useState(false);
  const [formData, setFormData] = useState({
    categoryId: "",
    subCategoryId: "",
    regionId: [],
    id: "",
  });

  const [validationErrors, setValidationErrors] = useState({});

  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    fetchProductSizes();
  }, []);
  const clearValidationError = (fieldName) => {
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      [fieldName]: undefined,
    }));
  };

  const handleProductSizeChange = (e) =>
    setFormData({ ...formData, name: e.target.value });

  const StatusUpdate = async (data) => {
    let res = await updateSizeStatusAction({
      id: data.id,
      status: data.status === 0 ? "1 " : "0",
    });
    if (res.success) {
      fetchProductSizes();
    } else {
      Swal.fire("Failed!", res.msg, "error");
    }
  };

  const updateDeleteStatus = async (data) => {
    Swal.fire({
      title: "Are you sure?",
      text: `You want to Delete it!`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: `Yes, Delete it!`,
    }).then(async (result) => {
      if (result.isConfirmed) {
        let res = await deleteSizesAction({
          id: data.id,
        });
        if (res.success) {
          fetchProductSizes();
        } else {
          Swal.fire("Failed!", res.msg, "error");
        }
      }
    });
  };

  // Add Inner Category-------------
  const addInnerSubCategory = async (e) => {
    e.preventDefault();
    const errors = {};

    if (formData.name === "") {
      errors.name = "Size name is required";
    }

    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
    } else {
      // Clear validation errors
      setValidationErrors({});
      try {
        const response = await addProductSizeAction(formData);
        if (response.success) {
          toast.success("Success", {
            position: toast.POSITION.TOP_CENTER,
          });
          onCloseModal();
          fetchProductSizes();
        } else {
          console.error("Failed to add Inner Subcategory");
        }
      } catch (error) {
        console.error("An error occurred while adding the category:", error);
      }
    }
  };
  // Update InnerCategory -------------

  const updateInnerCategory = async (e) => {
    e.preventDefault();
    try {
      const errors = {};
      const data = {
        id: formData.id,
        name: formData.name,
      };
      if (data.name === "") {
        errors.name = "Size name is required";
      }
      if (Object.keys(errors).length > 0) {
        setValidationErrors(errors);
      } else {
        // Clear validation errors
        setValidationErrors({});

        const response = await updateProductSizeAction(data);
        if (response.success) {
          toast.success("Success", {
            position: toast.POSITION.TOP_CENTER,
          });
          onCloseModalSecond();
          fetchProductSizes();
        } else {
          console.error("Failed to update Inner category");
        }
      }
    } catch (error) {
      console.error("An error occurred while updating the category:", error);
    }
  };

  // Fetch Product Size------------------
  const fetchProductSizes = async () => {
    try {
      setLoader(true);
      const res = await getProductSizeListAction();
      if (res.success) {
        setLoader(false);
        setSubListing(res.data);
      } else {
        setLoader(false);
      }
    } catch (error) {
      console.error(
        "An error occurred while getting inner subcategories:",
        error,
      );
    }
  };

  const onOpenModalSecond = (sizes) => {
    setFormData({
      categoryId: sizes.categoryId,
      subCategoryId: sizes.subCategoryId,
      name: sizes.name,
      id: sizes.id,
    });
    setModalSecond(true);
  };

  const onOpenModal = () => {
    setFormData({
      categoryId: "",
      subCategoryId: "",
      name: "",
    });
    setOpen(true);
  };

  const onCloseModalSecond = () => {
    setModalSecond(false);
  };

  const onCloseModal = () => {
    setOpen(false);
  };

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  const filteredData = sublisting.filter((item, index) => {
    item.index = index;
    item = Object.values(item).some((value) =>
      String(value).toLowerCase().includes(searchText.toLowerCase()),
    );
    return item;
  });

  const columns = [
    {
      name: "№",
      selector: (row) => `${row.index + 1}`,
      sortable: true,
    },
    {
      name: "Size",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Action",
      cell: (row) => {
        return (
          <div className="button_wrp">
            <button
              onClick={() => onOpenModalSecond(row)}
              className="btn btn-outline-primary"
            >
              <i class="fa fa-pencil-square-o fa-xs" aria-hidden="true"></i>
            </button>
            {row.status == 0 ? (
              <button
                onClick={() => StatusUpdate(row)}
                className="btn btn-outline-danger redclosebtn"
              >
                <i class="fa fa-times fa-s" aria-hidden="true"></i>
              </button>
            ) : row.status == 1 ? (
              <button
                onClick={() => StatusUpdate(row)}
                className="btn btn-success"
              >
                <i class="fa fa-check  fa-xs" aria-hidden="true"></i>
              </button>
            ) : (
              ""
            )}
            <button
              onClick={() => updateDeleteStatus(row)}
              className="btn btn-outline-danger"
            >
              <i class="fa fa-trash fa-xs" aria-hidden="true"></i>
            </button>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <div className="content">
        <div className="main-content">
          <div className="block justify-between page-header md:flex"></div>
          <div className="grid grid-cols-12 gap-6">
            <div className="col-span-12">
              <div className="box">
                <div className="box-header">
                  <h5 className="box-title font-normal">Product Size List</h5>
                  <button
                    type="button"
                    onClick={onOpenModal}
                    className="ti-btn ti-btn-outline-primary"
                    style={{ float: "right", marginTop: "-35px" }}
                  >
                    Add
                  </button>
                </div>
                <div className="box-body">
                  {/* Search input */}
                  <input
                    type="text"
                    value={searchText}
                    onChange={handleSearch}
                    placeholder="Search..."
                    className="px-2 py-1 border rounded mb-4"
                  />
                  {!loader ? (
                    <div className="overflow-hidden table-bordered">
                      <DataTable
                        columns={columns}
                        data={filteredData}
                        pagination
                        paginationTotalRows={filteredData.length}
                        customStyles={tableStyles}
                      />
                    </div>
                  ) : (
                    <Loader />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        open={open}
        onClose={onCloseModal}
        center
        closeOnOverlayClick={false}
        closeOnEsc={false}
      >
        <label className="mb-2 font-normal" htmlFor="categoryId">
          Add Size Name
        </label>
        <hr />

        <div className="form-group mb-3">
          <label className="mb-2 font-normal" htmlFor="name">
            Size Name
          </label>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={(e) => {
              handleProductSizeChange(e);
              clearValidationError("name");
            }}
            className="form-control select"
            id="InnerSubcategoryName"
            placeholder="Size Name"
          />
          {validationErrors.name && (
            <span className="text-danger">{validationErrors.name}</span>
          )}
        </div>
        <button
          type="submit"
          onClick={addInnerSubCategory}
          className="btn btn-primary"
        >
          Add
        </button>
      </Modal>
      <Modal
        open={modalSecond}
        onClose={onCloseModalSecond}
        center
        closeOnOverlayClick={false}
        closeOnEsc={false}
      >
        <label className="mb-2 font-normal" htmlFor="categoryId">
          Edit Size Name
        </label>
        <hr />

        <div className="form-group mb-3">
          <label className="mb-2 font-normal" htmlFor="name">
            Size Name
          </label>

          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={(e) => {
              handleProductSizeChange(e);
              clearValidationError("name");
            }}
            className="form-control select"
            id="name"
            placeholder="Size"
          />
          {validationErrors.name && (
            <span className="text-danger">{validationErrors.name}</span>
          )}
        </div>
        <br />
        <button
          type="submit"
          onClick={updateInnerCategory}
          className="btn btn-primary "
        >
          Update
        </button>
      </Modal>
      <ToastContainer />
    </>
  );
};

export default Size;
