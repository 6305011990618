import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import Cookies from "js-cookie";
import config from "../../config/config";

const loginData = !Cookies.get("loginSuccessFarfetchAdmin")
  ? null
  : JSON.parse(Cookies.get("loginSuccessFarfetchAdmin"));
const serverPath = process.env.REACT_APP_API_URL;

console.log(loginData);

const baseQuery = fetchBaseQuery({
  baseUrl: serverPath,

  prepareHeaders: (headers) => {
    if (loginData?.authToken) {
      headers.set("authorization", loginData.authToken);
    }

    return headers;
  },
});

export const apiSlice = createApi({
  reducerPath: "apiSlice",
  baseQuery: baseQuery,
  endpoints: () => ({}),
  tagTypes: ["Dashboard", "Users"],
});
