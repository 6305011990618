import { FaUsers, FaAlignLeft } from 'react-icons/fa';
import { GiCombinationLock } from 'react-icons/gi';
import { IoQrCodeOutline } from 'react-icons/io5';
import { MdDelete, MdOutlineProductionQuantityLimits } from 'react-icons/md';
import { SlGlobeAlt, SlSizeFullscreen } from 'react-icons/sl';
import { TbBrandTether, TbCategory2, TbCategoryFilled, TbCategoryPlus } from 'react-icons/tb';

export const SIDEBAR_CONFIG = [
  {
    route: 'dashboard',
    title: 'Dashboard',
    icon: <i className="ri-home-8-line" />,
  },
  {
    route: 'users',
    title: 'Users',
    icon: <FaUsers />,
  },
  {
    route: 'department',
    title: 'Departments',
    icon: <TbCategoryFilled />,
  },
  {
    route: 'subdepartment',
    title: 'Subdepartments',
    icon: <TbCategoryFilled />,
  },
  {
    route: 'categories',
    title: 'Categories',
    icon: <TbCategoryPlus />,
  },
  {
    route: 'inner-categories',
    title: 'Inner Categories',
    icon: <TbCategoryPlus />,
  },
  {
    route: 'subcategories',
    title: 'Subcategories',
    icon: <i class="ri-node-tree" />,
  },
  {
    route: 'productType',
    title: 'Product Types',
    icon: <TbCategory2 />,
  },
  {
    route: 'additionalFilters',
    title: 'Additional Filters',
    icon: <FaAlignLeft />,
  },
  {
    route: '#',
    title: 'Product Combinations',
  },
  {
    route: 'main-product-type',
    title: 'Combinations',
    icon: <GiCombinationLock />,
  },
  {
    route: 'department-banner',
    title: 'Home Banners',
    icon: <SlSizeFullscreen />,
  },
  {
    route: 'order',
    title: 'Orders',
    icon: <i class="ri-archive-fill" />,
  },
  {
    route: 'size',
    title: 'Sizes',
    icon: <SlSizeFullscreen />,
  },
  {
    route: 'promocode',
    title: 'Promocodes',
  },
  {
    route: 'brand',
    title: 'Brands',
    icon: <TbBrandTether />,
  },
  {
    route: 'supplier',
    title: 'Suppliers',
    icon: <i class="ri-truck-line" />,
  },
  {
    route: 'deliveryandtax',
    title: 'Delivery Charges',
    icon: <i class="ri-truck-line" />,
  },
  {
    route: 'importduty',
    title: 'Import Duty',
    icon: <i class="ri-truck-line" />,
  },
  {
    route: 'regions',
    title: 'Regions',
    icon: <SlGlobeAlt />,
  },
  {
    route: 'marginpercent',
    title: 'Margin Percent',
    icon: <SlGlobeAlt />,
  },
  {
    route: 'CMS',
    title: 'CMS',
    icon: <i class="ri-mail-settings-fill" />,
  },
  {
    route: '#',
    title: 'Help And Support',
  },
  {
    route: 'feedback',
    title: "User's Feedback",
  },
  {
    route: '#',
    title: 'Translation',
  },
  {
    route: 'delete',
    title: 'Delete',
    icon: <MdDelete />,
  },
];
