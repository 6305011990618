import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import DataTable from 'react-data-table-component';
import { fetchAndSetTranslations } from '../../i18n';
import { useTranslation } from 'react-i18next';
import {
  UpdateInnerCategoryAction,
  addInnerCategoryAction,
  getCategoryAction,
  getInnerCategoryAction,
  getSubCategoryAction,
  innerCategoryStatusUpdateAction,
  deleteInnerCategoryAction,
  mainPageStatus,
} from '../../Action/user.action';
import Swal from 'sweetalert2';
import 'react-responsive-modal/styles.css';
import 'react-toastify/dist/ReactToastify.css';
import { tableStyles } from '../../config/tableStyles';
import { Loader } from '../UI/Loader';
import AddSubcategoryModal from './Subcategories/AddSubcategoryModal';
import EditSubcategoryModal from './Subcategories/EditSubcategoryModal';

const Subcategories = () => {
  const { t, i18n } = useTranslation();
  const [loader, setLoader] = useState(true);
  const [sublisting, setSubListing] = useState([]);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    departmentId: '',
    categoryId: '',
    subCategoryId: '',
    name: '',
    name_ru: '',
    size_guide: '',
    id: '',
  });
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [validationErrors, setValidationErrors] = useState({});
  const [searchText, setSearchText] = useState('');
  const [showActionButton, setShowActionButton] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => setIsOpen(!isOpen);
  // Fetch all data on component mount
  useEffect(() => {
    fetchTranslations();
    fetchCategories();
    fetchSubcategories();
    fetchInnerSubcategories();
  }, []);

  // Fetch translations
  const fetchTranslationsData = useCallback(async () => {
    try {
      await fetchAndSetTranslations('ru');
    } catch (error) {
      console.error('An error occurred while fetching translations:', error);
    }
  }, []);

  const fetchTranslations = useCallback(async () => {
    await fetchTranslationsData();
  }, [fetchTranslationsData]);

  // Fetch departments
  const fetchCategories = useCallback(async () => {
    try {
      const res = await getCategoryAction();
      if (res.success) {
        setCategories(res.data.filter((item) => item.isActive));
      }
    } catch (error) {
      console.error('An error occurred while getting categories:', error);
    }
  }, []);

  // Fetch subcategories
  const fetchSubcategories = useCallback(async () => {
    try {
      const res = await getSubCategoryAction();
      if (res.success) {
        setSubcategories(res.data);
      }
    } catch (error) {
      console.error('An error occurred while getting subcategories:', error);
    }
  }, []);

  // Fetch inner subcategories
  const fetchInnerSubcategories = useCallback(async () => {
    try {
      setLoader(true);
      const res = await getInnerCategoryAction();
      if (res.success) {
        setSubListing(res.data);
      }
    } catch (error) {
      console.error('An error occurred while getting inner subcategories:', error);
    } finally {
      setLoader(false);
    }
  }, []);

  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(name, value);
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
    console.log(formData);
  };

  // Clear validation errors for a specific field
  const clearValidationError = useCallback((fieldName) => {
    setValidationErrors((prev) => ({
      ...prev,
      [fieldName]: undefined,
    }));
  }, []);

  // Handle category (department) change
  const handleCategoryChange = useCallback((e) => {
    const selectedDepartmentId = e.target.value;
    setFormData((prev) => ({
      ...prev,
      departmentId: selectedDepartmentId,
      categoryId: '',
    }));
  }, []);

  // Handle subcategory change
  const handleSubcategoryChange = useCallback((e) => {
    const selectedCategoryId = e.target.value;
    setFormData((prev) => ({
      ...prev,
      categoryId: selectedCategoryId,
    }));
  }, []);

  // Status update handler
  const StatusUpdate = useCallback(
    async (data) => {
      try {
        const res = await innerCategoryStatusUpdateAction({
          id: data.id,
        });
        if (res.success) {
          fetchInnerSubcategories();
        } else {
          Swal.fire('Failed!', res.msg, 'error');
        }
      } catch (error) {
        console.error('Status update failed:', error);
      }
    },
    [fetchInnerSubcategories]
  );

  // Page status handler
  const onPageStatus = useCallback(
    async (data) => {
      try {
        const res = await mainPageStatus(data.id);
        if (res.success) {
          fetchInnerSubcategories();
        }
      } catch (error) {
        console.error('Page status update failed:', error);
      }
    },
    [fetchInnerSubcategories]
  );

  // Delete or deactivate handler
  const updateDeleteStatus = useCallback(
    async (data) => {
      Swal.fire({
        title: 'Are you sure?',
        text: `You want to Delete it!`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: `Yes, Delete it!`,
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            const res = await deleteInnerCategoryAction({
              id: data.id,
              status: data.status === 1,
            });
            if (res.success) {
              fetchInnerSubcategories();
            } else {
              Swal.fire('Failed!', res.msg, 'error');
            }
          } catch (error) {
            console.error('Deletion failed:', error);
          }
        }
      });
    },
    [fetchInnerSubcategories]
  );

  // Add subcategory handler
  const addInnerSubCategory = async (e) => {
    e.preventDefault();
    console.log(formData);
    try {
      const response = await addInnerCategoryAction(formData);
      if (response.success) {
        toast.success('Subcategory added successfully!', {
          position: toast.POSITION.TOP_CENTER,
        });
        setIsAddModalOpen(false);
        setFormData({
          departmentId: '',
          categoryId: '',
          subCategoryId: '',
          name: '',
          name_ru: '',
          size_guide: '',
          id: '',
        });
        fetchInnerSubcategories();
        fetchTranslations();
      } else {
        toast.error(response.msg, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    } catch (error) {
      console.error('An error occurred while adding the category:', error);
    }
  };

  // Update subcategory handler
  const updateInnerCategory = useCallback(
    async (e) => {
      e.preventDefault();
      const errors = {};
      const { departmentId, categoryId, name, name_ru, size_guide, id } = formData;

      if (!departmentId) {
        errors.departmentId = 'Department name is required';
      }
      if (!categoryId) {
        errors.categoryId = 'Category name is required';
      }
      if (!name.trim()) {
        errors.name = 'Inner category name is required';
      }

      if (Object.keys(errors).length > 0) {
        setValidationErrors(errors);
        return;
      }

      try {
        const data = {
          name,
          nameRu: name_ru,
          size_guide,
          id,
          categoryId,
        };
        const response = await UpdateInnerCategoryAction(data);
        if (response.success) {
          toast.success('Subcategory updated successfully!', {
            position: toast.POSITION.TOP_CENTER,
          });
          setIsEditModalOpen(false);
          setFormData({
            departmentId: '',
            categoryId: '',
            subCategoryId: '',
            name: '',
            name_ru: '',
            size_guide: '',
            id: '',
          });
          fetchInnerSubcategories();
          fetchTranslations();
        } else {
          toast.error('Failed to update category.', {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      } catch (error) {
        console.error('An error occurred while updating the category:', error);
      }
    },
    [formData, fetchInnerSubcategories, fetchTranslations]
  );

  // Open edit modal with selected subcategory data
  const onOpenModalSecond = useCallback((innerCategory) => {
    setFormData({
      departmentId: innerCategory.departmentId,
      categoryId: innerCategory.categoryId,
      name: innerCategory.name,
      name_ru: innerCategory.nameRu,
      size_guide: innerCategory.size_guide || '',
      id: innerCategory.id,
    });
    setIsEditModalOpen(true);
  }, []);

  // Open add modal
  const onOpenModal = useCallback(() => {
    setFormData({
      departmentId: '',
      categoryId: '',
      subCategoryId: '',
      name: '',
      name_ru: '',
      size_guide: '',
      id: '',
    });
    setIsAddModalOpen(true);
  }, []);

  // Close modals
  const onCloseEditModal = useCallback(() => {
    setIsEditModalOpen(false);
    setFormData({
      departmentId: '',
      categoryId: '',
      subCategoryId: '',
      name: '',
      name_ru: '',
      size_guide: '',
      id: '',
    });
  }, []);

  const onCloseAddModal = useCallback(() => {
    setIsAddModalOpen(false);
    setFormData({
      departmentId: '',
      categoryId: '',
      subCategoryId: '',
      name: '',
      name_ru: '',
      size_guide: '',
      id: '',
    });
  }, []);

  // Handle search input
  const handleSearch = useCallback((e) => {
    setSearchText(e.target.value);
  }, []);

  // Filtered data based on search
  const filteredData = useMemo(() => {
    return sublisting
      .map((item, index) => ({ ...item, index }))
      .filter((item) =>
        Object.values(item).some((value) => String(value).toLowerCase().includes(searchText.toLowerCase()))
      );
  }, [sublisting, searchText]);

  const handleSelectedRowsChange = (selectedRows) => {
    if (selectedRows.selectedCount > 0) {
      setSelectedRows(selectedRows.selectedRows);
      setShowActionButton(true);
    } else {
      setShowActionButton(false);
    }
  };

  const handleShowOnPage = async (e) => {
    e.preventDefault();
    try {
      const requests = selectedRows.map((item) => mainPageStatus(item.id));

      const responses = await Promise.all(requests);

      fetchInnerSubcategories();
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setIsOpen(false);
    }
  };

  const handleDontShow = async (e) => {
    e.preventDefault();
    try {
      const requests = sublisting.map((item) =>
        UpdateInnerCategoryAction({
          size_guide: item.size_guide,
          name: item.name,
          nameRu: item.nameRu,
          id: item.id,
          categoryId: item.categoryId,
          is_size_guide: false,
        })
      );

      const responses = await Promise.all(requests);

      fetchInnerSubcategories();
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setIsOpen(false);
    }
  };

  const handleShow = async (e) => {
    e.preventDefault();
    try {
      const requests = sublisting.map((item) =>
        UpdateInnerCategoryAction({
          size_guide: item.size_guide,
          name: item.name,
          nameRu: item.nameRu,
          id: item.id,
          categoryId: item.categoryId,
          is_size_guide: true,
        })
      );
      const responses = await Promise.all(requests);
      fetchInnerSubcategories();
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setIsOpen(false);
    }
  };

  // Table columns
  const columns = useMemo(
    () => [
      {
        name: '№',
        selector: (row) => `${row.index + 1}`,
        sortable: true,
      },
      {
        name: 'Department',
        selector: (row) => row.department_name,
        sortable: true,
      },
      {
        name: 'Category',
        selector: (row) => row.category_name,
        sortable: true,
      },
      {
        name: 'Sub Category',
        selector: (row) => row.name,
        sortable: true,
      },
      {
        name: 'Size Guide',
        selector: (row) => row.size_guide ?? 'None',
        sortable: true,
      },
      {
        name: 'Action',
        cell: (row) => (
          <div className="button_wrp">
            <button onClick={() => onOpenModalSecond(row)} className="btn btn-outline-primary">
              <i className="fa fa-pencil-square-o fa-xs" aria-hidden="true"></i>
            </button>
            {row.isActive === 0 ? (
              <button onClick={() => StatusUpdate(row)} className="btn btn-outline-danger redclosebtn">
                <i className="fa fa-times fa-s" aria-hidden="true"></i>
              </button>
            ) : row.isActive === 1 ? (
              <button onClick={() => StatusUpdate(row)} className="btn btn-outline-success">
                <i className="fa fa-check fa-xs" aria-hidden="true"></i>
              </button>
            ) : (
              ''
            )}
            <button onClick={() => updateDeleteStatus(row)} className="btn btn-outline-danger">
              <i className="fa fa-trash fa-xs" aria-hidden="true"></i>
            </button>
            {row.on_department_page ? (
              <button onClick={() => onPageStatus(row)} className="btn btn-outline-success">
                <i className="fa fa-eye fa-xs" aria-hidden="true"></i>
              </button>
            ) : (
              <button onClick={() => onPageStatus(row)} className="btn btn-outline-danger redclosebtn">
                <i className="fa fa-eye fa-s" aria-hidden="true"></i>
              </button>
            )}
          </div>
        ),
      },
    ],
    [StatusUpdate, updateDeleteStatus, onPageStatus, onOpenModalSecond]
  );

  return (
    <>
      <div className="content">
        <div className="main-content">
          <div className="block justify-between page-header md:flex">
            <div></div>
          </div>
          <div className="grid grid-cols-12 gap-6">
            <div className="col-span-12">
              <div className="box">
                <div className="box-header">
                  <h5 className="box-title font-normal">Sub Categories List</h5>
                  <button
                    type="button"
                    onClick={onOpenModal}
                    className="ti-btn ti-btn-outline-primary"
                    style={{ float: 'right', marginTop: '-35px' }}
                  >
                    Add
                  </button>
                </div>
                <div className="box-body">
                  {/* Search input */}
                  <div className="flex flex-row items-center justify-between mb-4">
                    <input
                      type="text"
                      value={searchText}
                      onChange={handleSearch}
                      placeholder="Search..."
                      className="px-2 py-1 border rounded"
                    />
                    {showActionButton && (
                      <div className="relative inline-block text-left">
                        <button onClick={toggleMenu} className="ti-btn ti-btn-outline-primary gap-0">
                          Actions
                          <svg
                            className="-mr-1 ml-2 h-5 w-5"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            aria-hidden="true"
                          >
                            <path
                              fillRule="evenodd"
                              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </button>

                        {isOpen && (
                          <div className="origin-top-right z-50 absolute right-0 mt-2 w-56 rounded-m shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none">
                            <div className="py-1">
                              <a
                                href="#"
                                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                                onClick={handleShowOnPage}
                              >
                                Change selected on-page status
                              </a>
                              <a
                                href="#"
                                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                                onClick={handleDontShow}
                              >
                                Turn off size guide
                              </a>
                              <a
                                href="#"
                                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                                onClick={handleShow}
                              >
                                Turn on size guide
                              </a>
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                  {!loader ? (
                    <div className="overflow-hidden table-bordered">
                      <DataTable
                        columns={columns}
                        data={filteredData}
                        pagination
                        paginationTotalRows={filteredData.length}
                        customStyles={tableStyles}
                        selectableRows
                        onSelectedRowsChange={handleSelectedRowsChange}
                      />
                    </div>
                  ) : (
                    <Loader />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Add Sub Category Modal */}
      <AddSubcategoryModal
        open={isAddModalOpen}
        onClose={onCloseAddModal}
        formData={formData}
        handleChange={handleChange}
        handleSubmit={addInnerSubCategory}
        categories={categories}
        subcategories={subcategories}
        validationErrors={validationErrors}
        clearValidationError={clearValidationError}
      />

      {/* Edit Sub Category Modal */}
      <EditSubcategoryModal
        open={isEditModalOpen}
        onClose={onCloseEditModal}
        formData={formData}
        handleChange={handleChange}
        handleUpdate={updateInnerCategory}
        categories={categories}
        subcategories={subcategories}
        validationErrors={validationErrors}
        clearValidationError={clearValidationError}
      />

      <ToastContainer />
    </>
  );
};

export default Subcategories;
