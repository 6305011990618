import React from 'react';
import { LoaderIcon } from 'react-hot-toast';
import axios from 'axios';
import Cookies from 'js-cookie';
import { useQuery } from '@tanstack/react-query';
import { getBrandListAction, getCategoriesByDepartment, getSubcategoriesByCategory } from '../../Action/user.action';
import { useForm, Controller } from 'react-hook-form';

export const DepartmentCard = ({ banner, refetchBanners, departments, setBannerData }) => {
  const { control, handleSubmit, watch, reset, setValue } = useForm({
    defaultValues: {
      file: null,
      selectedDep: banner.department_id,
      selectedCat: banner.category_id,
      title: banner.title,
      title_ru: banner.title_ru,
      loading: false,
      opacity: banner.opacity,
    },
  });

  const selectedDep = watch('selectedDep');
  const selectedCat = watch('selectedCat');
  const { data: categories } = useQuery({
    queryKey: [`categories-${banner.id}`, selectedDep],
    queryFn: () =>
      getCategoriesByDepartment({ department_id: selectedDep })
        .then((res) => res.data)
        .catch((err) => console.log(err)),
    enabled: !!selectedDep,
  });

  const { data: subCategories } = useQuery({
    queryKey: [`subCategories-${banner.id}`, selectedCat],
    queryFn: () =>
      getSubcategoriesByCategory({ category_id: selectedCat })
        .then((res) => res.data)
        .catch((err) => console.log(err)),
    enabled: !!selectedCat,
  });

  const { data: brands } = useQuery({
    queryKey: [`brands-${banner.id}`, selectedCat],
    queryFn: () =>
      getBrandListAction()
        .then((res) => res.data)
        .catch((err) => console.log(err)),
  });

  React.useEffect(() => {
    reset(banner);
  }, [banner, reset]);

  const loginData = !Cookies.get('loginSuccessFarfetchAdmin')
    ? []
    : JSON.parse(Cookies.get('loginSuccessFarfetchAdmin'));

  const addBanner = async (data) => {
    setValue('loading', true);
    const formData = new FormData();

    if (data.brand_id) formData.append('brand_id', data.brand_id);
    if (data.subcategory_id) formData.append('subcategory_id', data.subcategory_id);
    if (banner.is_active ? banner.category_id : data.selectedCat) {
      formData.append('category_id', banner.is_active ? banner.category_id : data.selectedCat);
    }
    if (banner.is_active ? banner.department_id : data.selectedDep) {
      formData.append('department_id', banner.is_active ? banner.department_id : data.selectedDep);
    }
    if (data.title) formData.append('title', data.title);
    if (data.title_ru) formData.append('title_ru', data.title_ru);
    if (banner.id) formData.append('id', banner.id);
    if (data.opacity) formData.append('opacity', data.opacity);

    if (data.file) {
      formData.append('banner', data.file);
    }

    try {
      await axios.patch(`${process.env.REACT_APP_API_URL}/updateBanner`, formData, {
        headers: {
          Authorization: loginData?.authToken,
        },
      });
      refetchBanners();
    } catch (err) {
      console.log(err);
    } finally {
      setValue('loading', false);
    }
  };

  const handleChange = (e) => {
    setBannerData((prevItems) =>
      prevItems.map((item) =>
        Number(item.id) === Number(banner.id) ? { ...item, have_sub_department: e.target.checked } : item
      )
    );
  };

  const deleteBanner = async () => {
    try {
      await axios.delete(`${process.env.REACT_APP_API_URL}/deleteBanner`, {
        data: { id: banner.id },
        headers: {
          Authorization: loginData?.authToken,
        },
      });
      refetchBanners();
    } catch (err) {
      console.log(err);
    }
  };

  if (!banner) {
    return null;
  }

  return (
    <div
      className="border p-5 dark:border-white/10"
      style={{ width: 'calc(25% - 40px)', minWidth: '313px', display: 'flex', flexDirection: 'column' }}
    >
      {banner.department_id === 1 && <h3>For Men</h3>}
      {banner.department_id === 2 && <h3>For Woman</h3>}
      {banner.department_id === 3 && <h3>For Kids</h3>}
      <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
        <label>Selected Banner for Department</label>
        <div
          style={{
            width: 'auto',
            height: '300px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '20px',
          }}
        >
          <img
            style={{ objectFit: 'contain', height: '100%' }}
            src={`${process.env.REACT_APP_MEDIA_URL}/banners/${banner?.url}`}
            alt="Banner"
          />
        </div>
      </div>
      <form
        onSubmit={handleSubmit(addBanner)}
        style={{ display: 'flex', flexDirection: 'column', gap: '10px', marginTop: '20px' }}
      >
        <label>Add New Banner Image</label>
        <Controller
          name="file"
          control={control}
          render={({ field: { onChange, value, ...field } }) => (
            <input onChange={(e) => onChange(e.target.files[0])} type="file" accept=".webp" {...field} />
          )}
        />
        {!banner.is_active && (
          <>
            {departments?.length > 0 && (
              <Controller
                name="selectedDep"
                control={control}
                render={({ field }) => (
                  <select {...field}>
                    <option>Select department</option>
                    {departments?.map((dep) => (
                      <option key={dep.id} value={dep.id}>
                        {dep.name}
                      </option>
                    ))}
                  </select>
                )}
              />
            )}
            <Controller
              name="selectedCat"
              control={control}
              render={({ field }) => (
                <select {...field}>
                  <option>Select category</option>
                  {categories?.map((cat) => (
                    <option key={cat.id} value={cat.id}>
                      {cat.name}
                    </option>
                  ))}
                </select>
              )}
            />
            <Controller
              name="subcategory_id"
              control={control}
              render={({ field }) => (
                <select {...field}>
                  <option>Select Subcategory</option>
                  {subCategories?.map((subCat) => (
                    <option key={subCat.id} value={subCat.id}>
                      {subCat.name}
                    </option>
                  ))}
                </select>
              )}
            />
            <Controller
              name="brand_id"
              control={control}
              render={({ field }) => (
                <select {...field}>
                  <option value={0}>Select Brand</option>
                  {brands?.map((brand) => (
                    <option key={brand.id} value={brand.id}>
                      {brand.name}
                    </option>
                  ))}
                </select>
              )}
            />
          </>
        )}
        <label>Title</label>
        <Controller
          name="title"
          control={control}
          render={({ field }) => <input className="border p-2" {...field} />}
        />
        <label>Title_ru</label>
        <Controller
          name="title_ru"
          control={control}
          render={({ field }) => <input className="border p-2" {...field} />}
        />
        <label>Opacity</label>
        <Controller
          name="opacity"
          control={control}
          render={({ field }) => <input className="border p-2" {...field} />}
        />
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '20px',
            marginTop: '10px',
          }}
        >
          <button
            style={{ padding: '10px', border: '1px solid #ccc', width: '100%' }}
            className="special_special_button align-center center flex items-center justify-center"
            type="submit"
          >
            {watch('loading') ? <LoaderIcon /> : 'Submit'}
          </button>
          {!banner.is_active && (
            <button
              style={{ padding: '10px', border: '1px solid #ccc', width: '100%' }}
              onClick={deleteBanner}
              className="special_delete_button align-center center flex items-center justify-center"
              type="button"
            >
              Delete
            </button>
          )}
        </div>
      </form>
    </div>
  );
};
