export const tableStyles = {
  headCells: {
    style: {
      fontSize: '14px',
      fontWeight: '400',
    },
  },
  cells: {
    style: {
      fontWeight: '300',
    },
  },
  pagination: {
    fontSize: '25px',
    fontWeight: '400',
  },
};
