import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import {
  deleteMargin,
  getMarginPercentListAction,
  insertMarginPercentAction,
  updateMarginPercentAction,
} from '../../Action/user.action';
import 'react-responsive-modal/styles.css';
import 'react-toastify/dist/ReactToastify.css';
import { Modal } from 'react-responsive-modal';
import { ToastContainer, toast } from 'react-toastify';
import { tableStyles } from '../../config/tableStyles';
import { Loader } from '../UI/Loader';
import Swal from 'sweetalert2';
import { DELETE_MODAL_CONFIG } from '../../const';

const MarginPercent = () => {
  const [loader, setLoader] = useState(true);
  const [listing, setListing] = useState([]);
  const [open, setOpen] = useState(false);
  const [modalSecond, setModalSecond] = useState(false);
  const [margin, setMargin] = useState({
    priceFrom: '',
    priceTo: '',
    percent: '',
  });
  const [validationErrors, setValidationErrors] = useState({});

  useEffect(() => {
    getListingDetails();
  }, []);

  const handleMarginChange = (e) => {
    const { name, value } = e.target;
    setMargin((old) => {
      return { ...old, [name]: value };
    });
  };

  const clearValidationError = (fieldName) => {
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      [fieldName]: undefined,
    }));
  };

  // Add Margin  ---------------------------------
  const addMargin = async (e) => {
    e.preventDefault();
    const errors = {};

    if (margin.priceFrom === '') {
      errors.priceFrom = 'From Price is required';
    }
    if (margin.priceTo === '') {
      errors.priceTo = 'To Price is required';
    }
    if (margin.percent === '') {
      errors.percent = 'Margin percent is required';
    }

    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
    } else {
      setValidationErrors({});
      try {
        console.log(margin);

        const res = await insertMarginPercentAction(margin);
        if (res.success) {
          toast.success(res.msg, {
            position: toast.POSITION.TOP_CENTER,
          });
          onCloseModal();
          getListingDetails();
        } else {
          toast.error(res.msg, {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      } catch (error) {
        console.error('An error occurred while updating the data:', error);
      }
    }
  };

  // Update Margin  ---------------------------------
  const updateMargin = async (e) => {
    e.preventDefault();
    const errors = {};
    console.log(margin);

    if (margin.priceFrom === '') {
      errors.priceFrom = 'From Price is required';
    }
    if (margin.priceTo === '') {
      errors.priceTo = 'To Price is required';
    }
    if (margin.percent === '') {
      errors.percent = 'Margin percent is required';
    }

    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
    } else {
      setValidationErrors({});
      try {
        console.log(margin);

        const res = await updateMarginPercentAction(margin);
        if (res.success) {
          toast.success(res.msg, {
            position: toast.POSITION.TOP_CENTER,
          });
          onCloseModalSecond();
          getListingDetails();
        } else {
          toast.error(res.msg, {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      } catch (error) {
        console.error('An error occurred while updating the data:', error);
      }
    }
  };

  const updateDeleteStatus = async (data) => {
    Swal.fire(DELETE_MODAL_CONFIG).then(async (result) => {
      if (result.isConfirmed) {
        let res = await deleteMargin({
          id: data.id,
        });
        if (res.success) {
          getListingDetails();
        } else {
          Swal.fire('Failed!', res.msg, 'error');
        }
      }
    });
  };

  // Get listing -------------------------------
  const getListingDetails = async () => {
    try {
      setLoader(true);
      const res = await getMarginPercentListAction();
      if (res.success) {
        setLoader(false);
        console.log(res);
        setListing(res.marginPercent);
      }
    } catch (error) {
      console.error('An error occurred while getting the listing:', error);
    }
  };

  const columns = [
    {
      name: '№',
      selector: (row, index) => `${index + 1}`,
      sortable: true,
    },
    {
      name: 'From Price (USD)',
      selector: (row) => row.priceFrom + ' ' + `USD`,
      sortable: true,
    },
    {
      name: 'To Price (USD)',
      selector: (row) => row.priceTo + ' ' + `USD`,
      sortable: true,
    },
    {
      name: 'Margin Percent (%)',
      selector: (row) => row.percent + `%`,
      sortable: true,
    },
    {
      name: 'Action',
      cell: (row) => {
        return (
          <>
            <button onClick={() => onOpenModalSecond(row)} className="btn btn-outline-primary">
              <i class="fa fa-pencil-square-o fa-xs" aria-hidden="true"></i>
            </button>
            &nbsp;
            <button onClick={() => updateDeleteStatus(row)} className="btn btn-outline-danger">
              <i class="fa fa-trash fa-xs" aria-hidden="true"></i>
            </button>
          </>
        );
      },
    },
  ];

  // Modal code  ---------------------------------------
  const onOpenModalSecond = (category) => {
    setMargin(category);
    setModalSecond(true);
  };

  const onCloseModalSecond = () => {
    setModalSecond(false);
    setMargin({ priceFrom: '', priceTo: '', percent: '' });
  };

  const onOpenModal = () => setOpen(true);

  const onCloseModal = () => {
    setMargin({ priceFrom: '', priceTo: '', percent: '' });
    setOpen(false);
  };

  return (
    <>
      <div className="content">
        {/* Start::main-content */}
        <div className="main-content">
          <div className="block justify-between page-header md:flex"></div>

          <div className="grid grid-cols-12 gap-6">
            <div className="col-span-12">
              <div className="box">
                <div className="box-header font-normal">
                  Margin Percent List
                  <button
                    type="button"
                    onClick={onOpenModal}
                    className="ti-btn ti-btn-outline-primary"
                    style={{ float: 'right' }}
                  >
                    Add
                  </button>
                </div>

                <div className="box-body">
                  {!loader ? (
                    <div className="overflow-hidden table-bordered">
                      <DataTable columns={columns} data={listing} pagination customStyles={tableStyles} />
                    </div>
                  ) : (
                    <Loader />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Start::main-content */}
      </div>

      <Modal open={open} onClose={onCloseModal} center closeOnOverlayClick={false} closeOnEsc={false}>
        <label className="mb-2 font-normal" htmlFor="">
          Add Margin
        </label>
        <hr />
        <div className="form-group mb-3">
          <label className="mb-2 font-normal" htmlFor="priceFrom">
            From Price
          </label>
          <br />
          <input
            type="text"
            name="priceFrom"
            value={margin.priceFrom}
            onChange={(e) => {
              handleMarginChange(e);
              clearValidationError('priceFrom');
            }}
            className="form-control select"
            id="priceFrom"
            placeholder="From Price"
          />
          {validationErrors.priceFrom && <span className="text-danger">{validationErrors.priceFrom}</span>}
        </div>

        <div className="form-group mb-3">
          <label className="mb-2 font-normal" htmlFor="priceTo">
            To Price
          </label>
          <br />
          <input
            type="text"
            name="priceTo"
            value={margin.priceTo}
            onChange={(e) => {
              handleMarginChange(e);
              clearValidationError('priceTo');
            }}
            className="form-control select"
            id="priceTo"
            placeholder="To Price"
          />
          {validationErrors.priceTo && <span className="text-danger">{validationErrors.priceTo}</span>}
        </div>

        <div className="form-group mb-3">
          <label className="mb-2 font-normal" htmlFor="percent">
            Margin Percent
          </label>
          <br />
          <input
            type="text"
            name="percent"
            value={margin.percent}
            onChange={(e) => {
              handleMarginChange(e);
              clearValidationError('percent');
            }}
            className="form-control select"
            id="percent"
            placeholder="Margin Percent"
          />
          {validationErrors.percent && <span className="text-danger">{validationErrors.percent}</span>}
        </div>

        <button type="submit" onClick={addMargin} className="btn btn-primary ">
          Add
        </button>
      </Modal>
      <Modal open={modalSecond} onClose={onCloseModalSecond} center closeOnOverlayClick={false} closeOnEsc={false}>
        <label className="mb-2 font-normal" htmlFor="">
          Edit Margin
        </label>
        <hr />
        <div className="form-group mb-3">
          <label className="mb-2" htmlFor="priceFrom">
            From Price
          </label>
          <br />
          <input
            type="text"
            name="priceFrom"
            value={margin.priceFrom}
            onChange={(e) => {
              handleMarginChange(e);
              clearValidationError('priceFrom');
            }}
            className="form-control select"
            id="priceFrom"
            placeholder="From Price"
          />
          {validationErrors.priceFrom && <span className="text-danger">{validationErrors.priceFrom}</span>}
        </div>

        <div className="form-group mb-3">
          <label className="mb-2" htmlFor="priceTo">
            To Price
          </label>
          <br />
          <input
            type="text"
            name="priceTo"
            value={margin.priceTo}
            onChange={(e) => {
              handleMarginChange(e);
              clearValidationError('priceTo');
            }}
            className="form-control select"
            id="priceTo"
            placeholder="To Price"
          />
          {validationErrors.priceTo && <span className="text-danger">{validationErrors.priceTo}</span>}
        </div>

        <div className="form-group mb-3">
          <label className="mb-2 font-normal" htmlFor="percent">
            Margin Percent
          </label>
          <br />
          <input
            type="text"
            name="percent"
            value={margin.percent}
            onChange={(e) => {
              handleMarginChange(e);
              clearValidationError('percent');
            }}
            className="form-control select"
            id="percent"
            placeholder="Margin Percent"
          />
          {validationErrors.percent && <span className="text-danger">{validationErrors.percent}</span>}
        </div>

        <button type="submit" onClick={updateMargin} className="btn btn-primary ">
          Update
        </button>
      </Modal>
      <ToastContainer />
    </>
  );
};

export default MarginPercent;
