import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import {
  addDeliveries,
  deleteDeliveries,
  getSupplierListAction,
  updateDeliveries,
  updateSupplierDetailAction,
} from '../../Action/user.action';
import 'react-responsive-modal/styles.css';
import 'react-toastify/dist/ReactToastify.css';
import { Modal } from 'react-responsive-modal';
import { ToastContainer, toast } from 'react-toastify';
import { tableStyles } from '../../config/tableStyles';
import { Loader } from '../UI/Loader';
import Swal from 'sweetalert2';
import { DELETE_MODAL_CONFIG } from '../../const';

const Supplier = () => {
  const [loader, setLoader] = useState(true);
  const [listing, setListing] = useState([]);
  const [open, setOpen] = useState(false);
  const [modalSecond, setModalSecond] = useState(false);
  const [openAdd, setOpenAdd] = useState(false);
  const [supplier, setSupplier] = useState({
    name: '',
    address: '',
  });
  const [validationErrors, setValidationErrors] = useState({});

  useEffect(() => {
    getListingDetails();
  }, []);

  const handleSupplierChange = (e) => {
    const { name, value } = e.target;
    setSupplier((old) => {
      return { ...old, [name]: value };
    });
  };

  const clearValidationError = (fieldName) => {
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      [fieldName]: undefined,
    }));
  };

  // Update Supplier  ---------------------------------
  const updateSupplier = async (e) => {
    e.preventDefault();
    const errors = {};
    console.log(supplier);

    if (supplier.name === '') {
      errors.name = 'Name is required';
    }
    if (supplier.address === '') {
      errors.address = 'Address is required';
    }
    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
    } else {
      setValidationErrors({});
      try {
        const res = await updateDeliveries(supplier);
        if (res.success) {
          toast.success(res.msg, {
            position: toast.POSITION.TOP_CENTER,
          });
          onCloseModalSecond();
          getListingDetails();
        } else {
          toast.error(res.msg, {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      } catch (error) {
        console.error('An error occurred while updating the category:', error);
      }
    }
  };

  const addSupplier = async (e) => {
    e.preventDefault();
    const errors = {};
    console.log(supplier);

    if (supplier.name === '') {
      errors.name = 'Name is required';
    }
    if (supplier.address === '') {
      errors.address = 'Address is required';
    }

    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
    } else {
      setValidationErrors({});
      try {
        const res = await addDeliveries(supplier);
        if (res.success) {
          toast.success(res.msg, {
            position: toast.POSITION.TOP_CENTER,
          });
          onCloseModalSecond();
          getListingDetails();
        } else {
          toast.error(res.msg, {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      } catch (error) {
        console.error('An error occurred while updating the category:', error);
      } finally {
        closeHandleAdd();
      }
    }
  };

  const updateDeleteStatus = async (data) => {
    Swal.fire(DELETE_MODAL_CONFIG).then(async (result) => {
      if (result.isConfirmed) {
        let res = await deleteDeliveries({
          id: data.id,
          status: data.status,
        });
        if (res.success) {
          getListingDetails();
        } else {
          Swal.fire('Failed!', res.msg, 'error');
        }
      }
    });
  };

  // Get listing -------------------------------
  const getListingDetails = async () => {
    try {
      setLoader(true);
      const res = await getSupplierListAction().then((res) => {
        console.log(res);

        setLoader(false);
        setListing(res.data);
      });
    } catch (error) {
      console.error('An error occurred while getting the listing:', error);
    }
  };

  const columns = [
    {
      name: '№',
      selector: (row, index) => `${index + 1}`,
      sortable: true,
    },
    {
      name: 'Name',
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: 'Address',
      selector: (row) => row.address,
      sortable: true,
    },
    {
      name: 'Action',
      cell: (row) => {
        return (
          <>
            <button onClick={() => onOpenModalSecond(row)} className="btn btn-primary">
              <i class="fa fa-pencil-square-o fa-xs" aria-hidden="true"></i>
            </button>
            &nbsp;
            <button onClick={() => updateDeleteStatus(row)} className="btn btn-outline-danger">
              <i class="fa fa-trash fa-xs" aria-hidden="true"></i>
            </button>
          </>
        );
      },
    },
  ];

  // Modal code  ---------------------------------------
  const onOpenModalSecond = (category) => {
    setSupplier(category);
    setModalSecond(true);
  };

  const onCloseModalSecond = () => setModalSecond(false);

  const handleAdd = () => {
    setOpenAdd(true);
  };

  const closeHandleAdd = () => {
    setOpenAdd(false);
  };

  return (
    <>
      <div className="content">
        {/* Start::main-content */}
        <div className="main-content">
          <div className="block justify-between page-header md:flex"></div>

          <div className="grid grid-cols-12 gap-6">
            <div className="col-span-12">
              <div className="box">
                <div className="box-header">
                  <h5 className="box-title">Supplier List</h5>
                  <button
                    onClick={() => setOpenAdd(true)}
                    type="button"
                    className="ti-btn ti-btn-outline-primary"
                    style={{ float: 'right', marginTop: '-35px' }}
                  >
                    Add
                  </button>
                </div>

                <div className="box-body">
                  {!loader ? (
                    <div className="overflow-hidden table-bordered">
                      <DataTable columns={columns} data={listing} pagination customStyles={tableStyles} />
                    </div>
                  ) : (
                    <Loader />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Start::main-content */}
      </div>

      <Modal open={openAdd} onClose={closeHandleAdd} center closeOnOverlayClick={false} closeOnEsc={false}>
        <label className="mb-2" htmlFor="">
          <h4>Add Supplier</h4>
        </label>
        <hr />
        <div className="form-group mb-3">
          <label className="mb-2" htmlFor="name">
            Supplier name
          </label>
          <br />
          <input
            type="text"
            name="name"
            value={supplier.name}
            onChange={(e) => {
              handleSupplierChange(e);
              clearValidationError('name');
            }}
            className="form-control"
            id="name"
            placeholder="Name"
          />
          {validationErrors.IIN && <span className="text-danger">{validationErrors.name}</span>}
        </div>

        <div className="form-group mb-3">
          <label className="mb-2" htmlFor="address">
            Address
          </label>
          <br />
          <textarea
            type="text"
            name="address"
            value={supplier.address}
            onChange={(e) => {
              handleSupplierChange(e);
              clearValidationError('address');
            }}
            className="form-control"
            id="address"
            placeholder="address"
          />
          {validationErrors.address && <span className="text-danger">{validationErrors.address}</span>}
        </div>
        {/* <br /> */}
        <button type="submit" onClick={addSupplier} className="btn btn-primary modal-footer">
          Add
        </button>
      </Modal>

      <Modal open={modalSecond} onClose={onCloseModalSecond} center closeOnOverlayClick={false} closeOnEsc={false}>
        <label className="mb-2" htmlFor="">
          <h4>Edit Supplier</h4>
        </label>
        <hr />

        <div className="form-group mb-3">
          <label className="mb-2" htmlFor="name">
            Supplier name
          </label>
          <br />
          <input
            type="text"
            name="name"
            value={supplier.name}
            onChange={(e) => {
              handleSupplierChange(e);
              clearValidationError('name');
            }}
            className="form-control"
            id="name"
            placeholder="Name"
          />
          {validationErrors.IIN && <span className="text-danger">{validationErrors.name}</span>}
        </div>

        <div className="form-group mb-3">
          <label className="mb-2" htmlFor="address">
            Address
          </label>
          <br />
          <textarea
            type="text"
            name="address"
            value={supplier.address}
            onChange={(e) => {
              handleSupplierChange(e);
              clearValidationError('address');
            }}
            className="form-control"
            id="address"
            placeholder="address"
          />
          {validationErrors.address && <span className="text-danger">{validationErrors.address}</span>}
        </div>
        {/* <br /> */}
        <button type="submit" onClick={updateSupplier} className="btn btn-primary modal-footer">
          Update
        </button>
      </Modal>
      <ToastContainer />
    </>
  );
};

export default Supplier;
