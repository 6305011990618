import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
// import './sidebar.css'
import config from "../config/config";
import { Accordion } from "react-bootstrap";
import Cookies from "js-cookie";
import { FaUsers } from "react-icons/fa";
import { TbCategoryFilled } from "react-icons/tb";
import { TbCategoryPlus } from "react-icons/tb";
import { TbCategory2 } from "react-icons/tb";
import { MdOutlineProductionQuantityLimits } from "react-icons/md";
import { GiCombinationLock } from "react-icons/gi";
import { LuRefreshCwOff } from "react-icons/lu";
import { SlSizeFullscreen } from "react-icons/sl";
import { IoQrCodeOutline } from "react-icons/io5";
import { TbBrandTether } from "react-icons/tb";
import { SlGlobeAlt } from "react-icons/sl";
import { TiContacts } from "react-icons/ti";
import { SIDEBAR_CONFIG } from "./const";

const loginData = !Cookies.get("loginSuccessFarfetchAdmin")
  ? []
  : JSON.parse(Cookies.get("loginSuccessFarfetchAdmin"));

const Sidebar = () => {
  const navigate = useNavigate();

  const [lastSegmentData, setlastSegmentData] = useState("");
  const [cmsData, setcmsData] = useState(0);

  const redirectPage = async (pageName) => {
    navigate(`${process.env.REACT_APP_BASE_URL}${pageName}`);
  };

  useEffect(() => {
    var parts = window.location.href.split("/");
    // console.log(parts)
    // var lastSegment = parts.pop() || parts.pop(); // handle potential trailing slash
    setlastSegmentData(parts[4]);
    // alert(parts[4])
  }, []);

  const cmsAction = (id) => {
    if (id === 0) {
      setcmsData(1);
    } else {
      setcmsData(0);
    }
  };

  return (
    <aside
      className="app-sidebar"
      id="sidebar"
      style={{ background: "#1e293b" }}
    >
      {/* Start::main-sidebar-header */}
      <div className="main-sidebar-header">
        <a
          href
          className="header-logo"
          style={{ color: "#fff", textDecoration: "none" }}
        >
          <img
            src={`./assets/img/logo.svg`}
            alt="logo"
            style={{ marginLeft: "9px", marginTop: "-9px" }}
            className="main-logo desktop-dark"
          />
          {/* <h3>For You Admin</h3> */}
        </a>
      </div>
      {/* End::main-sidebar-header */}
      {/* Start::main-sidebar */}
      <div className="main-sidebar " id="sidebar-scroll">
        {/* Start::nav */}
        <nav className="main-menu-container nav nav-pills flex-column sub-open">
          <div className="slide-left" id="slide-left">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="#7b8191"
              width={24}
              height={24}
              viewBox="0 0 24 24"
            >
              <path d="M13.293 6.293 7.586 12l5.707 5.707 1.414-1.414L10.414 12l4.293-4.293z" />
            </svg>
          </div>
          <ul className="main-menu">
            {/* Start::slide__category */}
            <li className="slide__category">
              <span className="category-name">Main</span>
            </li>
            {/* End::slide__category */}
            {/* Start::slide */}
            {SIDEBAR_CONFIG.map((el) => (
              <li className="slide">
                <NavLink
                  to={`${process.env.REACT_APP_BASE_URL}${el.route}`}
                  className={({ isActive }) =>
                    isActive ? "side-menu__item active" : "side-menu__item"
                  }
                  style={{display: 'flex', alignItems: 'center'}}
                >
                  {el.icon}
                  <span className="side-menu__label">{el.title}</span>
                </NavLink>
              </li>
            ))}
          </ul>
        </nav>
      </div>
    </aside>
  );
};
export default Sidebar;
