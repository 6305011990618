import React, { useEffect, useState, useMemo } from "react";
import Sidebar from "../../directives/sidebar";
import Header from "../../directives/header";
import Footer from "../../directives/footer";
import JoditEditor from "jodit-react";
import { useParams } from "react-router-dom";
import {
  getCMSContentByIdAction,
  updateCMSContentAction,
} from "../../Action/user.action";
import { ToastContainer, toast } from "react-toastify";
import { fetchAndSetTranslations } from "../../i18n";
import { useTranslation } from "react-i18next";
import { baseurl } from "../../config/config";
import { useNavigate } from "react-router-dom";

const EditCMSContent = () => {
  const { t, i18n } = useTranslation();
  const { id } = useParams();
  const [listing, setListing] = useState([]);
  const [editorState, setEditorState] = useState("");
  const [editorState_ru, setEditorState_ru] = useState("");
  const [title, setTitle] = useState("");
  const [title_ru, setTitle_ru] = useState("");
  const [validationErrors, setValidationErrors] = useState({});

  const navigate = useNavigate();

  useEffect(() => {
    getListingDetails();
    fetchTranslations();
  }, []);


  const config = useMemo(
    () => ({
      controls: {
        font: {
          list: {
            'Didact Gothic, sans-serif': 'Didact Gothic',
            'Rubik, sans-serif': 'Rubik',
            'Arial, sans-serif': 'Arial',
            'Georgia, serif': 'Georgia'
          }
        }
      }
    }),
    []
  );

  const fetchTranslations = async () => {
    try {
      const translations = await fetchAndSetTranslations("ru");
    } catch (error) {
      console.error("An error occurred while fetching translations:", error);
    }
  };

  const getListingDetails = async () => {
    try {
      console.log(id);
      const res = await getCMSContentByIdAction({ id: id });
      console.log(res);
      if (res.success) {
        let data = res.data;
        setTitle(data.name || "");
        setTitle_ru(t(data.nameRu));
        setEditorState(data.text || "");
        setEditorState_ru(t(data.textRu));
        setListing(data);
      }
    } catch (error) {
      console.error("An error occurred while getting the listing:", error);
    }
  };

  const handleUpdate = async () => {
    try {
      const data = {
        text: editorState,
        textRu: editorState_ru,
        id: id,
        name: title,
        nameRu: title_ru,
      };

      const errors = {};

      if (data.text === "" && data.text.trim() === "") {
        errors.text = "Description is required";
      }
      if (data.textRu === "" && data.textRu.trim() === "") {
        errors.textRu = "Description is required";
      }

      if (Object.keys(errors).length > 0) {
        setValidationErrors(errors);
      } else {
        clearValidationErrors();
        const res = await updateCMSContentAction(data);
        if (res.success) {
          toast.success("Success", {
            position: toast.POSITION.TOP_CENTER,
          });

          getListingDetails();
          fetchTranslations();
          setTimeout(() => {
            navigate(-1);
          }, 1200);
        } else {
          toast.error("Failed to update accessibility data");
        }
      }
    } catch (error) {
      console.error(
        "An error occurred while saving the accessibility data:",
        error,
      );
    }
  };

  const handleCMSChange = (newContent) => {
    setEditorState(newContent);
  };

  const handleCMSChange_ru = (newContent) => {
    setEditorState_ru(newContent);
  };

  const clearValidationErrors = () => {
    setValidationErrors({});
  };

  return (
    <>
      <div className="content">
        <div className="grid grid-cols-12 gap-x-6">
          <div className="col-span-12">
            <div className="box fields">
              <div className="box-header">
                <h5 className="box-title font-normal">Edit CMS Content</h5>
              </div>
              <div className="box-body">
                <form>
                  <div className="space-y-4">
                    <div className="space-y-2">
                      <label className="ti-form-label mb-2">Title</label>
                      <input
                        type="text"
                        className="my-auto ti-form-input"
                        name="title"
                        placeholder="Enter title name (EN)"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                      />
                    </div>

                    <div className="space-y-2">
                      <input
                        type="text"
                        className="my-auto ti-form-input"
                        name="title_ru"
                        placeholder="Enter title name (RU)"
                        value={title_ru}
                        onChange={(e) => setTitle_ru(e.target.value)}
                      />
                    </div>

                    <div className="space-y-2">
                      <label className="ti-form-label mb-2">Description</label>
                      <JoditEditor
                        value={editorState}
                        onChange={handleCMSChange}
                        editorClassName="CMSdata"
                        config={config}
                      />
                      {validationErrors.text && (
                        <span className="text-red-500 text-sm">
                          {validationErrors.text}
                        </span>
                      )}
                    </div>

                    <div className="space-y-2">
                      <JoditEditor
                        value={editorState_ru}
                        onChange={handleCMSChange_ru}
                        editorClassName="CMSdata"
                        config={config}
                      />
                      {validationErrors.textRu && (
                        <span className="text-red-500 text-sm">
                          {validationErrors.textRu}
                        </span>
                      )}
                    </div>
                  </div>
                  <button
                    type="button"
                    className="ti-btn ti-btn-primary"
                    onClick={handleUpdate}
                  >
                    Save
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditCMSContent;
