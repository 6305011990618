import React, { useEffect, useState } from "react";
import "react-responsive-modal/styles.css";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import Sidebar from "../../directives/sidebar";
import Header from "../../directives/header";
import Footer from "../../directives/footer";
import DataTable from "react-data-table-component";
import Swal from "sweetalert2";
import config from "../../config/config";
import {
  getPromoCodeAction,
  updatePromoCodeStatusAction,
  deletePromocodeAction,
} from "../../Action/user.action";
import Togglesidebar from "../../directives/togglesidebar";
import moment from "moment";
import { Form } from "react-bootstrap";
import { tableStyles } from "../../config/tableStyles";

const Promocode = () => {
  const [loader, setLoader] = useState(true);
  const [promocode, setPromocode] = useState([]);
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    fetchPromoCodeList();
  }, []);

  const fetchPromoCodeList = async () => {
    try {
      setLoader(true);
      const res = await getPromoCodeAction();
      console.log(res);
      if (res.success) {
        console.log(res.promocodes);
        setPromocode(res.promocodes);
      }
      setLoader(false);
    } catch (error) {
      console.error("An error occurred while getting Promo Codes:", error);
      setLoader(false);
    }
  };

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  const StatusUpdate = (data) => async () => {
    let res = await updatePromoCodeStatusAction({
      id: data,
    });
    if (res.success) {
      fetchPromoCodeList();
    } else {
      Swal.fire("Failed!", res.msg, "error");
    }
  };

  const updateDeleteStatus = async (data) => {
    Swal.fire({
      title: "Are you sure?",
      text: `You want to Delete it!`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: `Yes, Delete it!`,
    }).then(async (result) => {
      if (result.isConfirmed) {
        let res = await deletePromocodeAction({
          id: data.id,
        });
        console.log(res);
        if (res.success) {
          await fetchPromoCodeList();
        } else {
          Swal.fire("Failed!", res.msg, "error");
        }
      }
    });
  };

  const columns = [
    {
      name: "№",
      selector: (row, index) => index + 1,
      sortable: true,
    },
    {
      name: "Promo Code",
      selector: (row) => row.code,
      sortable: true,
    },
    {
      name: "Discount (%)",
      selector: (row) => row.percent,
      sortable: true,
    },
    {
      name: "Is active",
      selector: (row) => row.isActive,
      sortable: true,
      cell: (row) => (
        <Form>
          <Form.Check
            checked={row.isActive}
            onClick={StatusUpdate(row.id)}
          ></Form.Check>
        </Form>
      ),
    },
    {
      name: "Valid From",
      selector: (row) => moment(row?.validFrom).format("DD-MM-YYYY"),
      sortable: true,
    },
    {
      name: "Valid To",
      selector: (row) => moment(row?.validTo).format("DD-MM-YYYY"),
      sortable: true,
    },
    {
      name: "Action",
      cell: (row) => (
        <div className="button_wrp">
          <a href={`${process.env.REACT_APP_BASE_URL}editPromocode/${row.id}`}>
            <button className="btn btn-outline-primary">
              <i className="fa fa-pencil-square-o fa-xs" aria-hidden="true"></i>
            </button>
          </a>
          {row.status === 0 ? (
            <button
              onClick={() => StatusUpdate(row)}
              className="btn btn-outline-danger redclosebtn"
            >
              <i className="fa fa-times fa-s" aria-hidden="true"></i>
            </button>
          ) : row.status === 1 ? (
            <button
              onClick={() => StatusUpdate(row)}
              className="btn btn-success"
            >
              <i className="fa fa-check  fa-xs" aria-hidden="true"></i>
            </button>
          ) : null}

          <button
            onClick={() => updateDeleteStatus(row)}
            className="btn btn-outline-danger"
          >
            <i className="fa fa-trash fa-xs" aria-hidden="true"></i>
          </button>
        </div>
      ),
    },
  ];

  const filteredData = promocode?.filter((item) =>
    Object.values(item).some((value) =>
      String(value).toLowerCase().includes(searchText.toLowerCase()),
    ),
  );

  return (
    <>
      <div className="content">
        <div className="main-content">
          <div className="block justify-between page-header md:flex"></div>
          <div className="grid grid-cols-12 gap-6">
            <div className="col-span-12">
              <div className="box fields">
                <div className="box-header">
                  <h5 className="box-title font-normal">Promo Code List</h5>
                  <a href={`${process.env.REACT_APP_BASE_URL}addPromocode`}>
                    <span>
                      <button
                        type="button"
                        className="ti-btn ti-btn-outline-primary"
                        style={{ float: "right", marginTop: "-35px" }}
                      >
                        Add
                      </button>
                    </span>
                  </a>
                </div>
                <div className="box-body">
                  {/* Search input */}
                  <input
                    type="text"
                    value={searchText}
                    onChange={handleSearch}
                    placeholder="Search..."
                    className="px-2 py-1 border rounded mb-4"
                  />
                  {loader ? (
                    <div className="text-center mt-4">
                      <i className="fa fa-spinner fa-spin fa-2x"></i>
                    </div>
                  ) : filteredData?.length ? (
                    <div className="overflow-hidden table-bordered">
                      <DataTable
                        columns={columns}
                        data={filteredData}
                        pagination
                        paginationTotalRows={filteredData.length}
                        customStyles={tableStyles}
                      />
                    </div>
                  ) : (
                    <div className="text-center mt-4">No data found</div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ToastContainer />
    </>
  );
};

export default Promocode;
