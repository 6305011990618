import React from 'react';
import { Modal } from 'react-responsive-modal';

const EditSubcategoryModal = ({
  open,
  onClose,
  formData,
  handleChange,
  handleUpdate,
  categories,
  subcategories,
  validationErrors,
  clearValidationError,
}) => {
  return (
    <Modal open={open} onClose={onClose} center closeOnOverlayClick={false} closeOnEsc={false}>
      <label className="mb-2" htmlFor="departmentId">
        <h4>Edit Sub Category</h4>
      </label>
      <hr />
      <form onSubmit={handleUpdate}>
        <div className="form-group mb-2">
          <label className="mb-2" htmlFor="departmentId">
            Department
          </label>
          <select
            name="departmentId"
            value={formData.departmentId}
            onChange={(e) => {
              handleChange(e);
              clearValidationError('departmentId');
            }}
            className="form-control"
            id="departmentId"
          >
            <option value="">Select a department</option>
            {categories.map((category) => (
              <option key={category.id} value={category.id}>
                {category.name}
              </option>
            ))}
          </select>
          {validationErrors.departmentId && <span className="text-danger">{validationErrors.departmentId}</span>}
        </div>

        <div className="form-group mb-2">
          <label className="mb-2" htmlFor="categoryId">
            Category
          </label>
          <select
            name="categoryId"
            value={formData.categoryId}
            onChange={(e) => {
              handleChange(e);
              clearValidationError('categoryId');
            }}
            className="form-control"
            id="categoryId"
          >
            <option value="">Select category</option>
            {subcategories
              .filter((item) => item.departmentId === formData.departmentId)
              .map((subcategory) => (
                <option key={subcategory.id} value={subcategory.id}>
                  {subcategory.name}
                </option>
              ))}
          </select>
          {validationErrors.categoryId && <span className="text-danger">{validationErrors.categoryId}</span>}
        </div>

        <div className="form-group mb-2">
          <label className="mb-2" htmlFor="name">
            Sub Category Name (EN)
          </label>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={(e) => {
              handleChange(e);
              clearValidationError('name');
            }}
            className="form-control"
            id="name"
            placeholder="Sub Category Name (EN)"
          />
          {validationErrors.name && <span className="text-danger">{validationErrors.name}</span>}
        </div>

        <div className="form-group mb-2">
          <label className="mb-2" htmlFor="name_ru">
            Sub Category Name (RU)
          </label>
          <input
            type="text"
            name="name_ru"
            value={formData.name_ru}
            onChange={(e) => {
              handleChange(e);
              clearValidationError('name_ru');
            }}
            className="form-control"
            id="name_ru"
            placeholder="Sub Category Name (RU)"
          />
          {validationErrors.name_ru && <span className="text-danger">{validationErrors.name_ru}</span>}
        </div>

        <div className="form-group mb-2">
          <label className="mb-2" htmlFor="size_guide">
            Size Guide
          </label>
          <select
            name="size_guide"
            value={formData.size_guide}
            onChange={(e) => {
              handleChange(e);
            }}
            className="form-control"
            id="size_guide"
          >
            <option value="">Select size guide</option>

            <option value="top">Top</option>
            <option value="bottom">Bottom</option>
            <option value="shoes">Shoes</option>
            <option value="none">none</option>
          </select>
          {validationErrors.size_guide && <span className="text-danger">{validationErrors.size_guide}</span>}
        </div>

        <br />
        <button type="submit" className="btn btn-outline-primary">
          Update
        </button>
      </form>
    </Modal>
  );
};

export default EditSubcategoryModal;
